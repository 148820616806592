import { useEffect, useState, useContext } from 'react'
import CartContext from '../../Context/cart';
import { Link } from "react-router-dom";
import LangContext from '../../Context/lang';



const ProductCart = (item) => {

    const { Lang, ProductLang, Global, Panier } = useContext(LangContext);


    function shorten(str, maxLen, separator = ' ') {
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen));
    }

    console.log(item.item);
    const { Cart, AddToCart, RemoveFromCart, updateState, MaxAmount, MinAmount, CartTotal } = useContext(CartContext);
    const [Quantity, setQuantity] = useState(item.item.quantité);
    const [MaxQuantity, setMaxQuantity] = useState(item.item.qtsUnite)
    const [GetItem, setGetItem] = useState(item)

    // function CartTotal() {
    //     return Cart.reduce((n, {quantité , price}) => n +( quantité * price), 0)
    // }

    useEffect(() => {
        console.log(item.item);
        console.log('data changed');
        setGetItem(item)
        setQuantity(item.item.quantité)
        setMaxQuantity(item.item.qtsUnite)
    }, [item])

    console.log(GetItem);

    function ProductQuantity(type) {

        if (type === "up") {
            if (Quantity !== 1) {
                setQuantity(Quantity - 1)
                console.log(GetItem.item.hash, Quantity);
                updateState(GetItem.item.hash, Quantity - 1, 'min')
            }
        } else {

            if (Quantity < MaxQuantity) {


                console.log(CartTotal() + (GetItem.item.price));
                // if ((CartTotal() + (GetItem.item.price)) < MaxAmount) {

                // } else {
                //     return
                // }


                setQuantity(Quantity + 1)
                console.log(GetItem.item.hash, Quantity);
                updateState(GetItem.item.hash, Quantity + 1)
            }
        }

    }

    return (


        <div className='row align-items-center mb-5 mt-3 cart-product-section'>

            <div className='col-lg-2 col-6'>
                <div className='product-item-img'>
                    <Link to={`/product/${GetItem.item.hash}`}>
                        <img className="cart-img" src={`https://www.diardzair.com.dz/${GetItem.item.image}`} />
                    </Link>
                </div>
            </div>
            <div className='col-lg-2 col-6'>
                <Link to={`/product/${GetItem.item.hash}`}>
                    <p className='cart-product-name'>{GetItem?.item?.nom.length > 27 ? shorten(GetItem?.item?.nom , 27) + "....." : GetItem?.item?.nom } </p>
                    <p className='cart-product-marque'>{GetItem.item.marque}</p>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6' dir={Lang === 'ar' && 'rtl'}>
                <div className='product-quantity'>{(Panier['Quantité'][Lang])} 
                    <div className={Lang === 'ar' ? 'quantity-btn ' : 'quantity-btn '} dir='ltr'>
                        <button className="qtyminus" onClick={() => ProductQuantity("up")} aria-hidden="true">−</button>
                        <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Quantity} />
                        <button className="qtyplus" onClick={() => ProductQuantity("down")} aria-hidden="true">+</button>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-5 text-center mt-3 mt-md-0'>
                <p className='cart-product-price' dir={Lang === 'ar' && 'rtl'}>{GetItem.item.price} {(Global['Dajust'][Lang])}</p>
            </div>
            <div className='col-lg-1 col-12 my-3 mt-3 mt-md-0'>
                <div onClick={() => RemoveFromCart(GetItem.item.hash)} className='remove-btn'><svg xmlns="http://www.w3.org/2000/svg" width="17.386" height="20.667" viewBox="0 0 17.386 20.667">
                    <path id="icons8-trash" d="M17.233,1a.492.492,0,0,0,0,.984h4.921a.492.492,0,0,0,0-.984Zm-4.1,2.3a2.132,2.132,0,0,0,0,4.265h12.63V17.894a2.792,2.792,0,0,1-2.788,2.788H16.413a2.792,2.792,0,0,1-2.788-2.788V9.365a.492.492,0,0,0-.984,0v8.529a3.777,3.777,0,0,0,3.773,3.773h6.561a3.777,3.777,0,0,0,3.773-3.773V7.5A2.132,2.132,0,0,0,26.254,3.3Zm0,.984H26.254a1.148,1.148,0,0,1,0,2.3H13.132a1.148,1.148,0,0,1,0-2.3ZM17.4,9.857a.491.491,0,0,0-.492.492v7.545a.492.492,0,0,0,.984,0V10.349A.491.491,0,0,0,17.4,9.857Zm4.593,0a.491.491,0,0,0-.492.492v7.545a.492.492,0,0,0,.984,0V10.349A.491.491,0,0,0,21.989,9.857Z" transform="translate(-11 -1)" fill="#fff" />
                </svg>
                </div>
            </div>

        </div>

    )
}

export default ProductCart