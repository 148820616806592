import { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BankContext from '../../Context/bank'
import LangContext from '../../Context/lang';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




const DartaksitCategories = ({ CatName }) => {

    const { bankActive, setBankActive, GetTaksitPrice } = useContext(BankContext);
    const { Lang, setLang, NavMenu, Global } = useContext(LangContext);





    const [AllProduct, setAllProduct] = useState([])
    const [Réfrigérateurs, setRéfrigérateurs] = useState([])

    function shorten(str, maxLen, separator = ' ') {
        if (str.length <= maxLen) return str;
        return str.substr(0, str.lastIndexOf(separator, maxLen));
    }


    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`https://diardzair.com.dz/ws/produit/categorie?parent=${CatName.unicnom}&dar=DARTAKSIT`);
                console.log(response[0]);
                let result = [];
                if (response[0]) {

                    setAllProduct(response[0])
                    response[0].forEach((x, y, z) => !(y % 4) ? result.push(z.slice(y, y + 4)) : '');

                    setRéfrigérateurs(result);
                    console.log(result)
                }

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);


    return (
        <>
            {/* <section className="pack-promo mt-5 pb-5 text-center background-section"> */}
            {/*************************** Start Electroménager ****************************/}
            {/* <div className="container pt-5">
                <div className="title-section"> Electroménager
                    <a href='#'>
                        <span className='background-section'>Voir Plus <i class="fa fa-angle-right" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row d-none d-lg-flex ">
                    <div className='col-12 col-lg-3'><img className="img-fluid" src="img/Groupe 82.png" /></div>
                    <div className='col-12 col-lg-9'>
                        <div className="row gy-3 gx-3 d-none d-lg-flex ">
                            <div id="diar-dzair-slide2" className="carousel carousel-dartaksit-category slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#diar-dzair-slide2" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                                    <button type="button" data-bs-target="#diar-dzair-slide2" data-bs-slide-to={1} aria-label="Slide 2" />
                                    <button type="button" data-bs-target="#diar-dzair-slide2" data-bs-slide-to={2} aria-label="Slide 3" />
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row gy-3 gx-3 d-none d-lg-flex ">
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/8822.jpg" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2340.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2207.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2204.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price-dartaksit-category">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row gy-3 gx-3 d-none d-lg-flex ">
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/8822.jpg" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2340.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2207.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2204.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price-dartaksit-category">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row gy-3 gx-3 d-none d-lg-flex ">
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/8822.jpg" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2340.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2207.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="card-dartaksit-category">
                                                    <div className="first-section-dartaksit-category">
                                                        <img className="img-dartaksit-category" src="img/2204.png" />
                                                    </div>
                                                    <div className="second-section-dartaksit-category">
                                                        <div className='marque-dartaksit-category'>SAMSUNG</div>
                                                        <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                                        <div className="tag-dartaksit-category">تقسيط</div>
                                                        <div className="price-dartaksit-category">2348 <span>DA | Mois</span></div>
                                                        
                                                    </div>
                                                    <div className='cat-btn'>
                                                        <div className='btn-cat-dar-taksit'>
                                                            Voir Plus
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev-dartaksit-category" type="button" data-bs-target="#diar-dzair-slide2" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next-dartaksit-category" type="button" data-bs-target="#diar-dzair-slide2" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true" />
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="carouselExampleControlsNoTouching2" className="carousel slide d-lg-none d-flex" data-bs-touch="false" data-bs-interval="false">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="card-dartaksit-category">
                                <div className="first-section-dartaksit-category">
                                    <img className="img-dartaksit-category" src="img/8822.jpg" />
                                </div>
                                <div className="second-section-dartaksit-category">
                                    <div className='marque-dartaksit-category'>SAMSUNG</div>
                                    <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                    <div className="tag-dartaksit-category">تقسيط</div>
                                    <div className="price-dartaksit-category">2348 <span>DA | Mois</span></div>
                                    <div className='voir-plus-dartaksit-category'>
                                        <a href='#'>Voir Plus</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="card-dartaksit-category">
                                <div className="first-section-dartaksit-category">
                                    <img className="img-dartaksit-category" src="img/2340.png" />
                                </div>
                                <div className="second-section-dartaksit-category">
                                    <div className='marque-dartaksit-category'>SAMSUNG</div>
                                    <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                    <div className="tag-dartaksit-category">تقسيط</div>
                                    <div className="price-dartaksit-category">2348 <span>DA | Mois</span></div>
                                    <div className='voir-plus-dartaksit-category'>
                                        <a href='#'>Voir Plus</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="card-dartaksit-category">
                                <div className="first-section-dartaksit-category">
                                    <img className="img-dartaksit-category" src="img/2207.png" />
                                </div>
                                <div className="second-section-dartaksit-category">
                                    <div className='marque-dartaksit-category'>SAMSUNG</div>
                                    <div className='title-dartaksit-category'>Téléviseur SAMSUNG 43°</div>
                                    <div className="tag-dartaksit-category">تقسيط</div>
                                    <div className="price-dartaksit-category">2348 <span>DA | Mois</span></div>
                                    <div className='voir-plus-dartaksit-category'>
                                        <a href='#'>Voir Plus</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching2" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching2" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div> */}
            {/*************************** End Electroménager ****************************/}


            {/*************************** Start Réfrigérateurs ****************************/}
            <div className="container pt-5">
                <div className="title-section"> {(Lang === 'ar' ? CatName.nomAr : CatName.nom)}
                    <a href={`/search/?page=1&parent=${CatName.unicnom}`}>
                        <span className='background-section'>{(Global['Plus'][Lang])} <i class="fa fa-angle-right" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row d-none d-lg-flex ">
                    <div className='col-12 col-lg-3'>
                        <Link to={`/search/?page=1&parent=${CatName.unicnom}`}>
                            <img className="img-fluid" src={"https://www.diardzair.com.dz/" + CatName.imgSection} />
                        </Link>
                    </div>
                    <div className='col-12 col-lg-9'>
                        <div className="row gy-3 gx-3 d-none d-lg-flex ">
                            {/* <div id={"diar-dzair-slide-" + CatName.unicnom} className="carousel carousel-dartaksit-category slide" data-bs-ride="carousel"> */}
                            {/* <div className="carousel-indicators d-none">

                                    {
                                        Réfrigérateurs.length > 0 && Réfrigérateurs.map((item, i) => (
                                            <button type="button" data-bs-target={"#diar-dzair-slide-" + CatName.unicnom} data-bs-slide-to={i} className={i === 0 ? 'active' : ''} aria-current="true" aria-label="Slide 1" />
                                        ))
                                    }

                                </div> */}
                            {/* <div className="carousel-inner"> */}
                            {
                                Réfrigérateurs.length === 0 &&
                                // <div className="carousel-inner">
                                //     <div className="carousel-item active">
                                <div className="row gy-3 gx-3 d-none d-lg-flex ">
                                    <div className="col-12 col-lg-6">
                                        <div className="card-dartaksit-category">
                                            <div className="first-section-dartaksit-category">
                                                <Skeleton height={82} width={94} borderRadius={30} />
                                            </div>
                                            <div className="second-section-dartaksit-category">
                                                <div className='marque-dartaksit-category'><Skeleton width={'30%'} height={11} /></div>
                                                <div className='title-dartaksit-category'><Skeleton width={'60%'} height={11} /></div>
                                                <div className="tag-dartaksit-category"><Skeleton height={20} /></div>
                                                <div className="price"><Skeleton height={19} width={'40%'} /></div>

                                            </div>
                                            <div className='cat-btn'>
                                                <Skeleton height={30} width={94} borderRadius={30} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="card-dartaksit-category">
                                            <div className="first-section-dartaksit-category">
                                                <Skeleton height={82} width={94} borderRadius={30} />
                                            </div>
                                            <div className="second-section-dartaksit-category">
                                                <div className='marque-dartaksit-category'><Skeleton width={'30%'} height={11} /></div>
                                                <div className='title-dartaksit-category'><Skeleton width={'60%'} height={11} /></div>
                                                <div className="tag-dartaksit-category"><Skeleton height={20} /></div>
                                                <div className="price"><Skeleton height={19} width={'40%'} /></div>

                                            </div>
                                            <div className='cat-btn'>
                                                <Skeleton height={30} width={94} borderRadius={30} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="card-dartaksit-category">
                                            <div className="first-section-dartaksit-category">
                                                <Skeleton height={82} width={94} borderRadius={30} />
                                            </div>
                                            <div className="second-section-dartaksit-category">
                                                <div className='marque-dartaksit-category'><Skeleton width={'30%'} height={11} /></div>
                                                <div className='title-dartaksit-category'><Skeleton width={'60%'} height={11} /></div>
                                                <div className="tag-dartaksit-category"><Skeleton height={20} /></div>
                                                <div className="price"><Skeleton height={19} width={'40%'} /></div>

                                            </div>
                                            <div className='cat-btn'>
                                                <Skeleton height={30} width={94} borderRadius={30} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="card-dartaksit-category">
                                            <div className="first-section-dartaksit-category">
                                                <Skeleton height={82} width={94} borderRadius={30} />
                                            </div>
                                            <div className="second-section-dartaksit-category">
                                                <div className='marque-dartaksit-category'><Skeleton width={'30%'} height={11} /></div>
                                                <div className='title-dartaksit-category'><Skeleton width={'60%'} height={11} /></div>
                                                <div className="tag-dartaksit-category"><Skeleton height={20} /></div>
                                                <div className="price"><Skeleton height={19} width={'40%'} /></div>

                                            </div>
                                            <div className='cat-btn'>
                                                <Skeleton height={30} width={94} borderRadius={30} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                //     </div>
                                // </div>
                            }

                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Autoplay]}
                                loop={true}
                                // spaceBetween={20}
                                slidesPerView={1}
                                // breakpoints={{
                                //     // when window width is >= 640px
                                //     640: {
                                //         slidesPerView: 1,
                                //     },
                                //     // when window width is >= 768px
                                //     768: {
                                //         slidesPerView: 2,
                                //     },
                                //     992: {
                                //         slidesPerView: 3,
                                //         spaceBetween: 8
                                //     },
                                //     1200: {
                                //         slidesPerView: 4,
                                //         spaceBetween: 20,

                                //     },

                                // }}
                                navigation
                                autoplay={{
                                    delay: 1500,
                                    disableOnInteraction: false,
                                }}
                                // pagination={{ clickable: true }}
                                // scrollbar={{ draggable: true }}
                                // onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={() => console.log('slide change')}
                                
                            >
                                {Réfrigérateurs.length > 0 && Réfrigérateurs.map((item, i) => (

                                    <SwiperSlide>
                                        {/* <div className={`carousel-item ${i == 0 ? 'active' : ''}`} data-bs-interval="2000"> */}
                                        <div className="row gy-3 gx-3 d-none d-lg-flex ">

                                            {item.map((item, i) => (

                                                <div className="col-12 col-lg-6">
                                                    <Link to={`/product/${item.hash}`}>
                                                        <div className="card-dartaksit-category">
                                                            <div className="first-section-dartaksit-category">
                                                                <img className="img-dartaksit-category" src={`https://www.diardzair.com.dz/${item.image}`} />
                                                            </div>
                                                            <div className="second-section-dartaksit-category">
                                                                <div className='marque-dartaksit-category'>{item.marque}</div>
                                                                <div className='title-dartaksit-category'>{shorten(item.nom, 30)}</div>
                                                                <div className="tag-dartaksit-category">تقسيط</div>
                                                                <div className={Lang === 'ar' ? "price d-flex" : "price"}>{(Lang === 'ar' ? <> <span className='me-1'> {(Global['Da'][Lang])} </span> {GetTaksitPrice(parseInt(item?.prix))['36']}</> : <>{GetTaksitPrice(parseInt(item?.prix))['36']}<span> {(Global['Da'][Lang])}</span></>)}</div>
                                                                {/* <div className='voir-plus-dartaksit-category'>
                                                                            <a href='#'>Voir Plus</a>
                                                                        </div> */}
                                                            </div>
                                                            <div className='cat-btn'>
                                                                <div className='btn-cat-dar-taksit'>
                                                                    {(Global['Plus'][Lang])}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            ))}

                                        </div>
                                        {/* </div> */}
                                     </SwiperSlide>


                                ))
                                }
                                </Swiper>
                                {/* </div> */}
                                {/* {Réfrigérateurs.length > 2 &&
                                    <div className=''>
                                        <button className="carousel-control-prev-dartaksit-category" type="button" data-bs-target={"#diar-dzair-slide-" + CatName.unicnom} data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next-dartaksit-category" type="button" data-bs-target={"#diar-dzair-slide-" + CatName.unicnom} data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true" />
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>} */}
                                {/* </div> */}
                        </div>
                    </div>
                </div>
                {/* <div id={"carouselExampleControlsNoTouching"+CatName.unicnom} className="taksit-cat-mobile carousel slide d-lg-none d-flex " data-touch="true" data-bs-touch="true" data-bs-interval="false"> */}
                {/* <div className="carousel-inner"> */}

                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Autoplay]}
                    loop={true}
                    // spaceBetween={20}
                    slidesPerView={1}
                    // breakpoints={{
                    //     // when window width is >= 640px
                    //     640: {
                    //         slidesPerView: 1,
                    //     },
                    //     // when window width is >= 768px
                    //     768: {
                    //         slidesPerView: 2,
                    //     },
                    //     992: {
                    //         slidesPerView: 3,
                    //         spaceBetween: 8
                    //     },
                    //     1200: {
                    //         slidesPerView: 4,
                    //         spaceBetween: 20,

                    //     },

                    // }}
                    navigation
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    // pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    className=" d-lg-none d-flex "
                >

                    {AllProduct?.length > 0 && AllProduct?.map((item, i) => (
                        <SwiperSlide>

                            {/* <div className={`carousel-item ${i == 0 ? 'active' : ''}`} data-bs-interval="2000"> */}
                            <Link to={`/product/${item.hash}`}>
                                <div className="card-dartaksit-category">
                                    <div className="first-section-dartaksit-category">
                                        <img className="img-dartaksit-category" src={`https://www.diardzair.com.dz/${item.image}`} />
                                    </div>
                                    <div className="second-section-dartaksit-category">
                                        <div className='marque-dartaksit-category'>{item.marque}</div>
                                        <div className='title-dartaksit-category'>{shorten(item.nom, 30)}</div>
                                        <div className="tag-dartaksit-category">تقسيط</div>
                                        <div className={Lang === 'ar' ? "price-dartaksit-category d-flex" : "price-dartaksit-category"}>{(Lang === 'ar' ? <> <span className='me-1'> {(Global['Da'][Lang])} </span> {GetTaksitPrice(parseInt(item?.prix))['36']}</> : <>{GetTaksitPrice(parseInt(item?.prix))['36']}<span> {(Global['Da'][Lang])}</span></>)}</div>
                                        <div className='cat-btn'>
                                            <div className='btn-cat-dar-taksit'>
                                                {(Global['Plus'][Lang])}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            {/* </div> */}
                        </SwiperSlide>

                    ))
                    }
                </Swiper>
                {/* </div> */}
                {/* {
                        AllProduct.length > 1 && <>
                            <button className="carousel-control-prev" type="button" data-bs-target={"#carouselExampleControlsNoTouching"+CatName.unicnom} data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target={"#carouselExampleControlsNoTouching"+CatName.unicnom} data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="visually-hidden">Next</span>
                            </button></>
                    } */}
                {/* </div> */}
            </div>

            {/*************************** End Réfrigérateurs ****************************/}


            {/* </section> */}
        </>
    )
}

export default DartaksitCategories