import {useEffect , useState} from 'react'
import {useParams , useSearchParams , useNavigate} from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



const CatProduct = () => {

  let { keywords , catname} = useParams();
  const navigate = useNavigate();

  console.log(keywords,catname);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageQuery = searchParams.get('page');
  console.log(pageQuery)
  // setSearchParams({page:"1"})

  const SkeletonArray = [65, 44, 12, 4,65, 44, 12, 4,65, 44, 12, 4, 44, 12, 4,65, 44, 12, 4];


  const [SearchData, setSearchData] = useState([])
  const [PageNumber, setPageNumber] = useState(0)
  const [CurrentPage, setCurrentPage] = useState(pageQuery !== '' ? pageQuery : 1)
  const [search, setsearch] = useState(keywords)
  const [Loading, setLoading] = useState(true)


  function Pagination(type,number) {

    if (type === "firstPage") {
          setCurrentPage(1)
          setSearchParams({page : 1})
    }

    if (type === "lastPage") {
      setCurrentPage(PageNumber)
      setSearchParams({page : PageNumber})
    }

    if (type === "allPage") {
      setCurrentPage(number)
      setSearchParams({page : number})
    }

    if (type === "Back") {
      setCurrentPage(number)
      setSearchParams({page : number})
    }

    if (type === "Next") {
      setCurrentPage(number)
      setSearchParams({page : number})
    }
    
  }


  useEffect(() => {
     
    if (!pageQuery) {
      setSearchParams({page:"1"})
    }
  }, [])
  

  useEffect(() => {

    const fetchData = async () =>{
      // setLoading(true);
      try {
        

        const {data: response} = await axios.get(`https://www.diardzair.com.dz/ws/produit/categorie?unicnom=Televisions&dar=DARTAKSIT`);
        // const {data: response} = await axios.get(`https://diardzair.com.dz/ws/searchText?q=p&page=${pageQuery}`);

        console.log(response[0]);
        setSearchData(response[0]);
        setPageNumber(response['pageTotal'])
        setCurrentPage(response['page'])
        console.log(CurrentPage)
        console.log(PageNumber);
        setLoading(false)

      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }
    
    fetchData();
              
  }, [keywords,pageQuery]);


  function SearchQuery(e) {
    // setsearch(e.target.value)
    // setCurrentPage(1)

    if (search.trim() !== "") {
      
      navigate("/search/"+search+"?page=1")
      // setsearch("");
    }
    
  }

  return (
    <div className='search-page'> 
    {/* <div className='search-area'>
      <div className='container'>
        <div className='titel'>Rechercher</div>
      <div className="d-flex justify-content-center px-5"> <div className="search"> 
      <input type="text" className="search-input" onChange={e => setsearch(e.target.value) } placeholder="Que recherchez-vous ? ..." value={search} /> 
      <a onClick={()=> SearchQuery()} href="#" className="search-icon"> <i className="fa fa-search" /> </a> </div> </div>
      </div>
    </div> */}
    <div className='container'>
    {/* <input type="search" onChange={e => SearchQuery(e) } className="form-control input-search-dd mb-5" placeholder="Que recherchez-vous ?" aria-label="Search" aria-describedby="search-addon" /> */}

        <div className='row mt-5 pt-5'>
        <div className='cat-title'><span>Dar Taksit | Catégories</span>{catname !== undefined ?  catname : ''}</div>

            {/* <div className='col-12 col-lg-3 mb-5'>
                <div className='sidebar-wrapper  sidebar--categories'>
            <h5 className="sidebarBlock-heading"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <path id="category-15" d="M102.843,109.387a5.862,5.862,0,1,1,4.127-1.717A5.792,5.792,0,0,1,102.843,109.387Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,102.843,99.146Zm0,23.554a5.862,5.862,0,1,1,4.127-1.717A5.793,5.793,0,0,1,102.843,122.7Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,102.843,112.459ZM118.265,122.7h-4.217a3.738,3.738,0,0,1-3.735-3.735v-4.217a.723.723,0,0,1,1.446,0v4.217a2.292,2.292,0,0,0,2.289,2.289h4.217a2.292,2.292,0,0,0,2.289-2.289v-4.217a2.292,2.292,0,0,0-2.289-2.289h-4.217a.723.723,0,0,1,0-1.446h4.217A3.738,3.738,0,0,1,122,114.748v4.217A3.738,3.738,0,0,1,118.265,122.7Zm-2.108-13.313a5.862,5.862,0,1,1,4.126-1.717A5.793,5.793,0,0,1,116.157,109.387Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,116.157,99.146Z" transform="translate(-97 -97.7)" fill="#ed335b"/>
</svg>
Catégories</h5>
                        <div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckDefault">
                Electromenager
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" defaultChecked />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckChecked">
                Refrigirateur
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckDefault">
                Lave linge
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckDefault">
                Chambres adultes
                </label>
            </div>
            
            
            
            </div>

            </div>
            <div className='sidebar-wrapper  sidebar--categories mt-5'>
            <h5 className="sidebarBlock-heading"><svg xmlns="http://www.w3.org/2000/svg" width="25.446" height="29" viewBox="0 0 25.446 29">
  <g id="mark_icon" data-name="mark icon" transform="translate(-81.65 -174.8)">
    <path id="Tracé_211" data-name="Tracé 211" d="M96.872,174.8c.46.106.919.218,1.379.324,1.921.442,3.842.872,5.756,1.332a1.851,1.851,0,0,1,1.444,1.467q.813,3.447,1.585,6.894a2.04,2.04,0,0,1-.566,1.986Q100,193.289,93.513,199.764a1.465,1.465,0,0,1-2.168-.006q-4.613-4.6-9.209-9.2a1.465,1.465,0,0,1,0-2.18q6.46-6.46,12.915-12.921a2.2,2.2,0,0,1,1.243-.625.646.646,0,0,0,.1-.029Zm-4.431,23.962c.035-.029.077-.071.118-.112L104.013,187.2c.465-.465.937-.931,1.4-1.4a.67.67,0,0,0,.194-.648c-.518-2.268-1.049-4.537-1.567-6.811a.588.588,0,0,0-.489-.483q-3.367-.769-6.734-1.55a.738.738,0,0,0-.754.212L83.22,189.359c-.035.035-.065.071-.088.094Z" fill="#e0234f"/>
    <path id="Tracé_212" data-name="Tracé 212" d="M94.935,437.276a1.706,1.706,0,0,1-.813-.53Q89.384,432,84.636,427.26a.629.629,0,0,0-.136-.094c.194-.194.359-.359.56-.566a.868.868,0,0,0,.094.147q4.808,4.8,9.6,9.592c.283.283.436.283.719,0l13.316-13.316a.838.838,0,0,0,.1-.124l.524.524c-.012.012-.041.047-.071.082l-13.4,13.4a1.526,1.526,0,0,1-.654.377C95.17,437.276,95.052,437.276,94.935,437.276Z" transform="translate(-2.682 -233.482)" fill="#e0234f"/>
    <path id="Tracé_213" data-name="Tracé 213" d="M392.437,251.6a1.346,1.346,0,1,1-1.337,1.343A1.33,1.33,0,0,1,392.437,251.6Z" transform="translate(-291.217 -72.274)" fill="#e0234f"/>
  </g>
</svg>

Marques</h5>
                        <div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckDefault">
                Electromenager
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" defaultChecked />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckChecked">
                Refrigirateur
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckDefault">
                Lave linge
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                <label className="form-check-label search-checkbox" htmlFor="flexCheckDefault">
                Chambres adultes
                </label>
            </div>
            
            
            
            </div>

            </div>
            <div onClick={() =>''} className='search-btn mt-5'>Rechercher 
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
                            <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                                <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff"/>
                                <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff"/>
                                <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff"/>
                            </g>
                            </svg>
                            </div>
            </div> */}
            <div className='col-12 col-lg-12'>
                <div className='row g-5'>
                  {!Loading && SearchData.length === 0 && <div className='text-center mt-5'>Pas de résultats Vérifiez l’orthographe des mots utilisés<br></br>
                  <img className='mt-5' width={300} src='https://thumbs.dreamstime.com/b/upset-magnifying-glass-cute-not-found-symbol-unsuccessful-s-upset-magnifying-glass-cute-not-found-symbol-unsuccessful-122205900.jpg' />
                  </div>}


                  {
     Loading && SkeletonArray.map((item,i) => ( 
        

        <div className="col">
         <Skeleton height={314} width={258} />
      </div>
            
        ))
    }

                {
     SearchData.length > 0 && SearchData.map((item,i) => ( 
        

        <div className="col">
        <Link to={`/product/${item.hash}`} href="#">
          <div className="card-product">
            <div className="first-section-product">
              <div className="img-product"><img className="img-fluid" src={`https://www.diardzair.com.dz/${item['image']}`} /></div>
              <div className="title-product">{item.nom.length > 60 ? item.nom.substring(0,60) + '....' : item.nom.substring(0,60)}</div>
              <div className="mark-product">{item.marque}</div>
            </div>
            <div className="product-page-price price-product">
              <div className="tag-taksit">تقسيط</div>
              <div className="price">{Math.trunc(item.prix)} <span>DA | Mois</span></div>
            </div>
          </div>
        </Link>
      </div>
            
        ))
    }
            

      {PageNumber > 1 &&
      <div class="text-center mt-5 pt-5 mb-5 pt-5">
        <div class="pagination">
        {CurrentPage > 1 ? <a href="#" onClick={()=> Pagination("firstPage")}>{'<<'}</a> :<a>{'<<'}</a>}
        
        {CurrentPage !== '1' ? <a onClick={()=> Pagination("Back",CurrentPage -1)} href="#">&laquo;</a> : <a>&laquo;</a>}


        
        {PageNumber > 0 && [...Array(PageNumber).keys()].map((item,i) => ( 
        
        <>
        {(item+1 == (parseInt(CurrentPage) +1) || item+1 == (parseInt(CurrentPage) +2) || item+1 == (parseInt(CurrentPage) -1) || item+1 == (parseInt(CurrentPage) -2) ||item+1 == CurrentPage )  ? 
        <a href="#" onClick={()=> Pagination("allPage",item+1)} className={item+1 == CurrentPage ? 'active' : ''}  >{item +1}</a>
        : ''}
        </>
            
        ))}
            
        
        
        {CurrentPage < PageNumber ? <a onClick={()=> Pagination("Next",parseInt(CurrentPage)+1)} href="#">&raquo;</a> : <a>&raquo;</a>}

        {CurrentPage < PageNumber ? <a href="#" onClick={()=> Pagination("lastPage",PageNumber)}>{'>>'}</a> :<a>{'>>'}</a>}
        </div>
        </div>
        }
      
      </div>
            </div>
        </div>
    </div>

    </div>
  )
}

export default CatProduct