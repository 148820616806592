import React, { createContext, useState, useEffect } from 'react'
import {useNavigate } from "react-router-dom";

const CartContext = createContext();

export function CartProvider({ children }) {

  const navigate = useNavigate();


  const getCart = localStorage.getItem('cart') !== null && localStorage.getItem('cart') !== undefined  && localStorage.getItem('cart') !== '' ? JSON.parse(localStorage.getItem('cart')) : [];
  const getMaxAmount = localStorage.getItem('MaxAmount') !== '' || localStorage.getItem('MaxAmount') !== null || localStorage.getItem('MaxAmount') !== undefined ?  localStorage.getItem('MaxAmount') : '';
  const getMinAmount = localStorage.getItem('MinAmount') !== '' || localStorage.getItem('MinAmount') !== null || localStorage.getItem('MinAmount') !== undefined ?  localStorage.getItem('MinAmount') : '';
  const getTime = localStorage.getItem('Time') !== '' || localStorage.getItem('Time') !== null ? JSON.parse(localStorage.getItem('Time')) : '';





  const [Cart, setCart] = useState(getCart);
  const [Alert, setAlert] = useState('');
  const [MaxAmount, setMaxAmount] = useState(getMaxAmount);
  const [MinAmount, setMinAmount] = useState(getMinAmount);
  const [TimeCart, setTimeCart] = useState(getTime);


  useEffect(() => {
    window.addEventListener('storage', storageEventHandler ,false);

  }, []);

  function storageEventHandler() {
    // setMaxAmount(localStorage.getItem('MaxAmount'))
    // setMinAmount(localStorage.getItem('MinAmount'))
    // setTimeCart(getTime)
    console.log('changed');
    console.log(localStorage.getItem('MaxAmount'), localStorage.getItem('MinAmount'));
    setMaxAmount(localStorage.getItem('MaxAmount'))
    setMinAmount(localStorage.getItem('MinAmount'))
    setTimeCart(localStorage.getItem('Time'))
    setCart(localStorage.getItem('cart') !== null ? JSON.parse(localStorage.getItem('cart')) : [])
    
  }


  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(Cart));
    setCart(Cart)
  }, [Cart]);

  useEffect(() => {


    localStorage.setItem('MaxAmount', MaxAmount);
    localStorage.setItem('MinAmount', MinAmount);
    localStorage.setItem('Time', TimeCart);

    

    setMaxAmount(MaxAmount)
    setMinAmount(MinAmount)
    setTimeCart(TimeCart)


  }, [MaxAmount, MinAmount, TimeCart]);


  console.log(MaxAmount, MinAmount , TimeCart);



  function CartTotal() {
    return Cart.reduce((n, { quantité, price }) => n + (quantité * price), 0)
  }


  function AddToCart(Product) {

    // if ((CartTotal() + (Product.price * Product.quantité)) > MaxAmount) {
    //   console.log((CartTotal() + Product.price) > MaxAmount);

    //   setAlert({ hash: Product.hash, page: 'Product', flag: 'danger ', msg: 'Le montant total maximum du panier ne peut être dépassé ' + MaxAmount?.toLocaleString() + ' DA' , msgar: 'لا يمكن تجاوز الحد الأقصى لمبلغ السلة الإجمالي ' + MaxAmount?.toLocaleString() + ' دجٍ'});
    //   return
    // }


    const found = Cart.find(element => element.hash == Product.hash);

    if (found == undefined) {

      setCart([...Cart, {
        "hash": Product.hash,
        "nom": Product.nom,
        "marque": Product.marque,
        "price": Product.price,
        "image": Product.image,
        "quantité": Product.quantité,
        "qtsUnite" : Product.qtsUnite
      }])
      // setAlert({ hash: Product.hash, page: 'Product', flag: 'success', msg: 'Produit ajouté avec succès   ',msgar: 'تمت إضافة المنتج بنجاح. سلتي' });
      navigate('/cart', { replace: true })
    } else {
      setAlert({ hash: Product.hash, page: 'Product', flag: 'danger ', msg: 'le produit existe déjà dans le panier', msgar: 'المنتج موجود بالفعل في سلة التسوق' , ref: 1 });
    }

  }

  function CheckCarProduct(Producthash) {

     
    const found = Cart.find(element => element.hash == Producthash);

    if (found !== undefined) {
       return true
    }else{
      return false
    }

  }

  function RemoveFromCart(hash) {
    const tt = Cart.filter(word => word.hash != hash);
    console.log(tt);
    setCart(Cart => Cart.filter(word => word.hash != hash))
    setAlert({ page: 'Cart', flag: 'success', msg: 'Le produit a été retiré du panier', msgar: 'تمت إزالة المنتج من عربة التسوق' });
    // localStorage.setItem('cart', JSON.stringify(Cart));
  }

  const updateState = (hash, quantity,action) => {

    // if (action == 'min' || CartTotal() < MaxAmount) {

    // } else {
    //   return
    // }
    console.log(CartTotal(), MaxAmount);

    const newState = Cart.map(obj => {
      if (obj.hash == hash) {
       
        if (quantity <= obj.qtsUnite ) {
          
          
          return { ...obj, quantité: quantity };
        }
      }
      return obj;
    });

    setCart(newState);
  };

  return <CartContext.Provider value={{ Cart, AddToCart,CheckCarProduct, RemoveFromCart, updateState, Alert, setAlert, MaxAmount, setMaxAmount, MinAmount, setMinAmount, CartTotal, TimeCart, setTimeCart }}>{children}</CartContext.Provider>
}


export default CartContext