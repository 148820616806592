import { useEffect, useState, useContext } from 'react'
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import BankContext from '../../Context/bank'
import LangContext from '../../Context/lang';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1400 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1400, min: 991 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 991, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Topannonces = () => {

  const { bankActive, setBankActive, GetTaksitPrice } = useContext(BankContext);
  const { Lang, setLang, NavMenu, Global } = useContext(LangContext);



  const [Topannonces, setTopannonces] = useState([])

  useEffect(() => {

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`https://diardzair.com.dz/ws/searchText?q=&page=1&parent=electromenager`);
        console.log(response[0]);
        setTopannonces(response[0]);
        console.log(Topannonces)

      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

  }, []);


  return (
    <section className="top-annonces mt-5 pt-4 pb-5">

      <div className="container">
        <div className="title-section">{(Global['TopAnnonce'][Lang])}</div>
        <div className="row gy-5">
          {/* <Carousel infinite={false} draggable={false} autoPlay={true} autoPlaySpeed={1000} responsive={responsive}> */}

          <Swiper
            // install Swiper modules
            modules={[Navigation, Autoplay]}
            loop={true}
            // spaceBetween={20}
            // slidesPerView={6}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 8
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 20,

              },

            }}
            navigation
            // autoplay={{
            //   delay: 1000,
            //   disableOnInteraction: false,
            // }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >

            {Topannonces.length > 0 && Topannonces.map((item, i) => (
              <SwiperSlide>

                <div className="col">
                  <a href={"/product/" + item.hash}>
                    <div className="card-product">
                      <div className="first-section-product">
                        <div className="img-product"><img className="img-fluid" src={`https://www.diardzair.com.dz/${item?.image}`} /></div>
                        <div className="title-product">{item?.nom.length > 20 ? item?.nom.substring(0, 20) + '....' : item?.nom.substring(0, 20)}</div>
                        <div className="mark-product">{item?.marque}</div>
                      </div>
                      <div className="price-product">
                        <div className="tag-taksit">تقسيط</div>
                        <div className={Lang === 'ar' ? "price d-flex" : "price"}> {(Lang === 'ar' ? <> <span className='me-1'> {(Global['Da'][Lang])} </span> {GetTaksitPrice(parseInt(item?.prix))['36']}</> : <>{GetTaksitPrice(parseInt(item?.prix))['36']}<span> {(Global['Da'][Lang])}</span></>)} </div>
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>

            ))
            }

          </Swiper>






          {/* </Carousel> */}
        </div>
      </div>
    </section>

  )
}

export default Topannonces