import React from 'react'
import Slide from '../components/HomePage/Slide'
import Diar from '../components/HomePage/Diar'
import Packpromo from '../components/HomePage/Packpromo'
import Topannonces from '../components/HomePage/Topannonces'
import DarTaksitCat from '../components/HomePage/DarTaksitCat'
import Brands from '../components/HomePage/Brands'
import Darimar from '../components/HomePage/Darimar'
import Daridjarimaarpub from '../components/HomePage/Daridjarimaarpub'
import Daridjar from '../components/HomePage/Daridjar'
import Engagements from '../components/HomePage/Engagements'
import Footer from '../components/Global/Footer'

const Home = () => {
  return (
   <>
   <Slide />
   <Diar />
   <DarTaksitCat />
   <Packpromo />
   <Topannonces />
   <Brands />
   <Darimar />
   <Daridjarimaarpub />
   <Daridjar />
   <Engagements />
   <Footer />
   </>
  )
}

export default Home