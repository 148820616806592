import React from 'react'
// import Packpromo from '../components/DartaksitPage/Packpromo'
import Packpromo from '../components/HomePage/Packpromo'
import SlideCategoriesContent from '../components/DartaksitPage/SlideCategoriesContent'
import Topannonces from '../components/DartaksitPage/Topannonces'
// import Brands from '../components/DartaksitPage/Brands'
import Brands from '../components/HomePage/Brands'
import DartaksitCategories from '../components/DartaksitPage/DartaksitCategories'
import Footer from '../components/Global/Footer'
import CatSection from '../components/DartaksitPage/CatSection'

function Dartaksit() {
  return (
    <div className='dar-taksit-page'>
      <SlideCategoriesContent />
      <Packpromo />
      <Topannonces />
      {/* <Brands /> */}
      <Brands />
      <CatSection />
      <Footer />
    </div>
  )
}

export default Dartaksit