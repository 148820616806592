import React from 'react'

const Pub = () => {
  return (
    <section className="draidjar-pub  pb-5 text-center mt-5 mb-5">
    <div className="container mt-5 mb-5">
        <div className="row g-2 g-lg-4">
        <div className="col-12 col-lg-6"><img className="img-fluid" src="img/idjarpub.png" /></div>
        <div className="col-12 col-lg-6"><img className="img-fluid" src="img/idjarpub2.jpg" /></div>
        </div>
    </div>
    </section>
  )
}

export default Pub