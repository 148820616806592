import { useEffect, useState, useRef,useContext } from 'react'
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import BankContext from '../Context/bank'
import LangContext from '../Context/lang';





const Search = () => {

  // let { keywords } = useParams();
  const navigate = useNavigate();

  const { bankActive, setBankActive ,GetTaksitPrice} = useContext(BankContext);
  const { Lang, setLang, NavMenu ,Global,ProductLang} = useContext(LangContext);



  // console.log(keywords);

  const isInitialMount = useRef(true);
  const isInitialMountCat = useRef(true);



  const [searchParams, setSearchParams] = useSearchParams();
  const keyQuery = searchParams.get('key');
  const [keywords, setkeywords] = useState(keyQuery !== null && keyQuery !== undefined && keyQuery !== '' ? keyQuery : "")
  const pageQuery = searchParams.get('page');
  const MarkQueryParams = searchParams.get('marque');
  const CatQueryParams = searchParams.get('parent');
  const subCatQueryParams = searchParams.get('categorie');

  console.log(pageQuery)
  console.log(MarkQueryParams);
  console.log(CatQueryParams);
  // setSearchParams({page:"2"})

  const SkeletonArray = [65, 44, 12, 4, 65, 44, 12, 4, 65, 44, 12, 4, 44, 12, 4, 65, 44, 12, 4];


  const [SearchData, setSearchData] = useState([])
  const [PageNumber, setPageNumber] = useState(0)
  const [CurrentPage, setCurrentPage] = useState(pageQuery !== "" ? pageQuery : 1)
  const [search, setsearch] = useState(keywords !== null && keywords !== undefined && keywords !== '' ? keywords : "")
  const [Loading, setLoading] = useState(true)

  const [CategoriesList, setCategoriesList] = useState([])
  const [Categorie, setCategorie] = useState(CatQueryParams !== '' ? CatQueryParams : '')
  const [LoadingCat, setLoadingCat] = useState(true)

  console.log(search);


  function Pagination(type, number) {

    if (type === "firstPage") {
      // setCurrentPage(1)
      setSearchParams({ page: 1, ...(search && { key: search }), ...(Markquery && { marque: Markquery }), ...(Categorie && { parent: Categorie }), ...(Categorie && SousCategories && { categorie: SousCategories }) })
    }

    if (type === "lastPage") {
      // setCurrentPage(PageNumber)
      setSearchParams({ page: PageNumber, ...(search && { key: search }), ...(Markquery && { marque: Markquery }), ...(Categorie && { parent: Categorie }), ...(Categorie && SousCategories && { categorie: SousCategories }) })
    }

    if (type === "allPage") {
      // setCurrentPage(number)
      setSearchParams({ page: number, ...(search && { key: search }), ...(Markquery && { marque: Markquery }), ...(Categorie && { parent: Categorie }), ...(Categorie && SousCategories && { categorie: SousCategories }) })
    }

    if (type === "Back") {
      // setCurrentPage(number)
      setSearchParams({ page: number, ...(search && { key: search }), ...(Markquery && { marque: Markquery }), ...(Categorie && { parent: Categorie }), ...(Categorie && SousCategories && { categorie: SousCategories }) })
    }

    if (type === "Next") {
      // setCurrentPage(number)
      setSearchParams({ page: number, ...(search && { key: search }), ...(Markquery && { marque: Markquery }), ...(Categorie && { parent: Categorie }), ...(Categorie && SousCategories && { categorie: SousCategories }) })
    }

  }


  const [Mark, setMark] = useState([]);
  const [GetMarkParams, setGetMarkParams] = useState(MarkQueryParams !== null ? MarkQueryParams.split(',') : [])
  const [MarkChecked, setMarkChecked] = useState(MarkQueryParams !== null ? MarkQueryParams.split(',') : []);
  console.log(MarkQueryParams);
  console.log(MarkChecked);
  const [Markquery, setMarkquery] = useState(MarkChecked.length > 0 && MarkChecked.join())

  const handleChange = (event) => {

    if (!event.target.checked) {

      setMarkChecked(MarkChecked.filter(item => item !== event.target.name))

    } else {
      setMarkChecked((prev) => [...prev, event.target.name]);
    }
    console.log(MarkChecked);

  }

  useEffect(() => {

    setMarkquery(MarkChecked.length > 0 && MarkChecked.join())
    console.log('firrrrrrrrrrrrrrrrreeeeeeeeeeee 0');

    // setMarkquery(Object.keys(MarkChecked).reduce((a, c) => {
    //   if (MarkChecked[c] === true) {
    //     a.push(c);
    //   }
    //   return a;
    // }, []))


  }, [MarkChecked])


  useEffect(() => {

    setMarkChecked(MarkQueryParams !== null ? MarkQueryParams.split(',') : [])



  }, [MarkQueryParams])

  useEffect(() => {

    setCategorie(CatQueryParams !== '' ? CatQueryParams : '')
    console.log('fire cattttt ');
    console.log(Categorie);
    console.log(CatQueryParams);




  }, [CatQueryParams])

  useEffect(() => {

    setSousCategories(subCatQueryParams !== '' ? subCatQueryParams : '')



  }, [subCatQueryParams])
  useEffect(() => {

    setCurrentPage(pageQuery)



  }, [pageQuery])

  useEffect(() => {

    setsearch(keywords !== null && keywords !== undefined && keywords !== '' ? keywords : "")

  }, [keywords,subCatQueryParams,CatQueryParams,MarkQueryParams])


  // useEffect(() => {

  //   setCurrentPage(pageQuery)
  //   // setMarkquery(Object.keys(MarkChecked).reduce((a, c) => {
  //   //   if (MarkChecked[c] === true) {
  //   //     a.push(c);
  //   //   }
  //   //   return a;
  //   // }, []))


  // }, [pageQuery])


  // useEffect(() => {

  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     setSearchParams({ page: 1, ...(Markquery && { marque: Markquery }) })

  //   }



  //   // setMarkquery(MarkChecked.length > 0 && MarkChecked.join())
  //   // setMarkquery(Object.keys(MarkChecked).reduce((a, c) => {
  //   //   if (MarkChecked[c] === true) {
  //   //     a.push(c);
  //   //   }
  //   //   return a;
  //   // }, []))


  // }, [Markquery])




  function filter() {

    setMarkquery(MarkChecked.length > 0 ? MarkChecked.join() : '')
    setSearchParams({ page: 1, ...(Markquery && { marque: Markquery }) })
    const keywordfilter = search.trim() !== '' && search.trim() !== null && search.trim() !== undefined ? search.trim() : '';

    // navigate("/search/" + keywordfilter + "?page=1&marque="+Markquery)
    // navigate(0)

  }


  const [LoadingMark, setLoadingMark] = useState(true)
  useEffect(() => {
    axios.get('https://diardzair.com.dz/ws/homepage/slides?type=marque').then(response => {
      console.log(response.data[0])
      setMark(response.data[0])
      setLoadingMark(false)
      console.log(Mark)
    });
  }, []);



  console.log(Categorie);

  useEffect(() => {

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/dartaksit-categories`);
        console.log(response[0]);
        setCategoriesList(response[0]);
        setLoadingCat(false)
        console.log(CategoriesList)

      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

  }, []);





  const [SousCategoriesList, setSousCategoriesList] = useState([])
  const [SousCategories, setSousCategories] = useState(subCatQueryParams !== '' ? subCatQueryParams : '')

  console.log(SousCategories);

  useEffect(() => {

    const GetSousCategories = async () => {
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/categorie?unicnom=${Categorie}`);
        console.log(response);
        setSousCategoriesList(response[0])
        if (isInitialMountCat.current) {
          isInitialMountCat.current = false;
        } else {
          setSousCategories('')
        }
      } catch (error) {
        console.error(error.message);
      }
    }

    if (Categorie !== '') {
      GetSousCategories();
    }


  }, [Categorie])



  useEffect(() => {

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const Catqueryuri = Categorie !== '' && Categorie !== undefined && Categorie !== null ? '&parent=' + Categorie : '';
      const SousCatqueryuri = SousCategories !== '' && SousCategories !== undefined && SousCategories !== null && Categorie !== '' && Categorie !== undefined && Categorie !== null ? '&categorie=' + SousCategories : '';
      const Markuri = Markquery !== '' && Markquery !== undefined && Markquery !== null && Markquery !== false ? '&marque=' + Markquery : '';


      // navigate("/search/?key=" +search + "&page=1"+Markuri+Catqueryuri+SousCatqueryuri)
      // return navigate(0)
      setSearchParams({ page: 1, ...(search && { key: search }), ...(Markquery && { marque: Markquery }), ...(Categorie && { parent: Categorie }), ...(Categorie && SousCategories && { categorie: SousCategories }) })
      // setCurrentPage(1)

      console.log('firrrrrrrrrrrrrrrrreeeeeeeeeeee');
    }



    // setMarkquery(MarkChecked.length > 0 && MarkChecked.join())
    // setMarkquery(Object.keys(MarkChecked).reduce((a, c) => {
    //   if (MarkChecked[c] === true) {
    //     a.push(c);
    //   }
    //   return a;
    // }, []))


  }, [Categorie, Markquery, SousCategories])



  useEffect(() => {
    console.log('%c Oh my heavens hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh! ', 'background: #222; color: #bada55;font-size:30px;');

    console.log(Categorie);
    const Catqueryuri = Categorie !== '' && Categorie !== undefined && Categorie !== null ? '&parent=' + Categorie : '';
    const SousCatqueryuri = Categorie !== '' && Categorie !== undefined && Categorie !== null && SousCategories !== '' && SousCategories !== undefined && SousCategories !== null ? '&categorie=' + SousCategories : '';

    const fetchData = async () => {
      setLoading(true);
      try {


        const { data: response } = await axios.get(`https://diardzair.com.dz/ws/searchText?q=${(keywords !== undefined && keywords.trim() !== '' ? encodeURIComponent(keywords) : "")}&page=${pageQuery}&marque=${Markquery !== false ? Markquery : ''}${Catqueryuri}${SousCatqueryuri}`);

        console.log(typeof response[0]);
        console.log(response);
        if (response['errorCode'] == 5) {
          navigate("/search/?page=1")
          navigate(0)

        }
        if (typeof response[0] == 'object') {


          setSearchData(response[0]);
          if (response[0].length > 0) {

            setPageNumber(response['pageTotal'])
            setCurrentPage(response['page'])
            console.log('errrrr' + response['page']);
          } else {

            setPageNumber(0)
            setCurrentPage(1)

          }
          console.log(CurrentPage)
          console.log(PageNumber);
        } else {
          navigate("/search/?page=1")
          navigate(0)

        }
        setLoading(false)

      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();
    console.log(`https://diardzair.com.dz/ws/searchText?q=${(keywords !== undefined ? encodeURIComponent(keywords) : "")}&page=${CurrentPage}&marque=${Markquery !== false ? Markquery : ''}${Catqueryuri}${SousCatqueryuri}`);
    console.log(`https://diardzair.com.dz/ws/searchText?q=${(keywords !== undefined ? keywords : "p")}&page=${pageQuery}&marque=${Markquery !== false ? Markquery : ''}${Catqueryuri}`);
    console.log(`https://diardzair.com.dz/ws/searchText?q=${(keywords !== undefined && keywords.trim() !== '' ? encodeURIComponent(keywords) : "")}&page=${pageQuery}&marque=${Markquery !== false ? Markquery : ''}${Catqueryuri}${SousCatqueryuri}`);

  }, [keywords, pageQuery, MarkQueryParams, CatQueryParams, subCatQueryParams]);


  function SearchQuery(e) {
    // setsearch(e.target.value)
    // setCurrentPage(1)

    if (search.trim() !== "") {

      window.location.href = "/search/?key=" + encodeURIComponent(search.trim()) + "&page=1";

      // navigate()
      // navigate(0)
      // setsearch("");
    } else {
      window.location.href = "/search/?page=1";

      // navigate("/search/?page=1")
      // navigate(0)
      // setsearch("");
    }

  }

  return (
    <div className='search-page'>
      <div className=''></div>
      <div className='search-area'>
        <div className='container'>
          <div className='titel'>{NavMenu['search'][Lang]}</div>
          <div className="d-flex justify-content-center px-md-5"> <div className="search">
            <input type="search" onKeyPress={event => event.key === 'Enter' && SearchQuery()} className="search-input" onChange={e => setsearch(e.target.value)} placeholder={NavMenu['searchbar'][Lang]} value={search} />
            <a onClick={() => SearchQuery()} href="#" className="search-icon"> <i className="fa fa-search" /> </a> </div> </div>
        </div>
      </div>
      <div className='container'>
        {/* <input type="search" onChange={e => SearchQuery(e) } className="form-control input-search-dd mb-5" placeholder="Que recherchez-vous ?" aria-label="Search" aria-describedby="search-addon" /> */}

        <div className='row'>
        <section className='mobile-dar-taksit-cat d-flex d-lg-none mb-3' data-bs-toggle="offcanvas" href="#offcanvasExamplesearch" role="button" aria-controls="offcanvasExamplesearch">
        <div>
          <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="category-15" d="M101.675,107.049a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,101.675,107.049Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,101.675,98.857Zm0,18.843a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,101.675,117.7Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,101.675,109.507Zm12.337,8.193h-3.374a2.991,2.991,0,0,1-2.988-2.988v-3.374a.578.578,0,0,1,1.157,0v3.374a1.834,1.834,0,0,0,1.831,1.831h3.374a1.834,1.834,0,0,0,1.831-1.831v-3.374a1.834,1.834,0,0,0-1.831-1.831h-3.374a.578.578,0,0,1,0-1.157h3.374A2.991,2.991,0,0,1,117,111.339v3.374A2.991,2.991,0,0,1,114.012,117.7Zm-1.687-10.651a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,112.325,107.049Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,112.325,98.857Z" transform="translate(-97 -97.7)" fill="#fff" />
          </svg>
          <span>{(Lang === 'ar' ? 'تخصيص' : 'Filtre')}</span>
        </div>
        <div> {(Lang === 'ar' ? 'شاهد' : 'Voir')}</div>

      </section>
      <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExamplesearch" aria-labelledby="offcanvasExampleLabel2">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">{(Lang === 'ar' ? 'تخصيص' : 'Filtre')}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body">

        {
              LoadingCat &&



              <Skeleton height={200} borderRadius={20} />



            }
            <div className={LoadingCat ? 'd-none' : 'sidebar-wrapper  sidebar--categories'}>
              <h5 className="sidebarBlock-heading"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <path id="category-15" d="M102.843,109.387a5.862,5.862,0,1,1,4.127-1.717A5.792,5.792,0,0,1,102.843,109.387Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,102.843,99.146Zm0,23.554a5.862,5.862,0,1,1,4.127-1.717A5.793,5.793,0,0,1,102.843,122.7Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,102.843,112.459ZM118.265,122.7h-4.217a3.738,3.738,0,0,1-3.735-3.735v-4.217a.723.723,0,0,1,1.446,0v4.217a2.292,2.292,0,0,0,2.289,2.289h4.217a2.292,2.292,0,0,0,2.289-2.289v-4.217a2.292,2.292,0,0,0-2.289-2.289h-4.217a.723.723,0,0,1,0-1.446h4.217A3.738,3.738,0,0,1,122,114.748v4.217A3.738,3.738,0,0,1,118.265,122.7Zm-2.108-13.313a5.862,5.862,0,1,1,4.126-1.717A5.793,5.793,0,0,1,116.157,109.387Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,116.157,99.146Z" transform="translate(-97 -97.7)" fill="#ed335b" />
              </svg>
              {(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</h5>
              {/* <div>

              {CategoriesList.length > 0 && CategoriesList.map((item,i) => ( 
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" defaultValue id={`flexCheckDefault${item.nom}`} />
                  <label className="form-check-label search-checkbox" htmlFor={`flexCheckDefault${item.nom}`}>
                    {item.nom}
                  </label>
                </div>                  
              ))
              }
              </div> */}
              <div className="col-md-12 mb-3 mt-5">
                {/* <label htmlFor="inputEmail4" className="form-label">Wilaya</label> */}
                <select class="form-select" aria-label="Default select example" onChange={(e) => setCategorie(e.target.value)}>
                  <option disabled selected="selected">{(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</option>

                  {CategoriesList.length > 0 &&

                    <option value={''}>{Lang === 'ar' ? 'كل التصنيفات' : 'Tous Les Catégories'} </option>

                  }

                  <>
                    {CategoriesList.length > 0 && CategoriesList.map((item, i) => (

                      <option selected={Categorie == item.unicnom ? 'selected' : ''} value={item.unicnom}>{(Lang === 'ar' ? item.nomAr : item.nom)}</option>
                    ))
                    }
                  </>

                </select>

              </div>
              <div className="col-md-12 mb-3">
                <select class={`form-select ${SousCategoriesList.length == 0 && 'd-none'}`} aria-label="Default select example" onChange={(e) => setSousCategories(e.target.value)}>
                  {
                    SousCategoriesList.length == 0 && <option disabled selected="selected">{(Lang === 'ar' ? 'الفئات الفرعية' : 'Sous Catégories')} </option>
                  }

                  {
                    SousCategoriesList.length > 0 && <option value={''} selected="selected">{(Lang === 'ar' ? 'جميع الفئات الفرعية' : 'Tous Les Sous Catégories')} </option>
                  }

                  {SousCategoriesList.length > 0 && SousCategoriesList.map((item, i) => (
                    <option selected={SousCategories == item.unicnom ? 'selecte' : ''} value={item.unicnom}>{(Lang === 'ar' ? item.nomAr : item.nom)}</option>
                  ))
                  }
                </select>
              </div>

            </div>

            {
              LoadingMark &&


              <Skeleton height={314} borderRadius={20} />


            }
            <div className={LoadingMark ? 'd-none' : 'sidebar-wrapper  sidebar--categories mt-1'}>
              <h5 className="sidebarBlock-heading"><svg xmlns="http://www.w3.org/2000/svg" width="25.446" height="29" viewBox="0 0 25.446 29">
                <g id="mark_icon" data-name="mark icon" transform="translate(-81.65 -174.8)">
                  <path id="Tracé_211" data-name="Tracé 211" d="M96.872,174.8c.46.106.919.218,1.379.324,1.921.442,3.842.872,5.756,1.332a1.851,1.851,0,0,1,1.444,1.467q.813,3.447,1.585,6.894a2.04,2.04,0,0,1-.566,1.986Q100,193.289,93.513,199.764a1.465,1.465,0,0,1-2.168-.006q-4.613-4.6-9.209-9.2a1.465,1.465,0,0,1,0-2.18q6.46-6.46,12.915-12.921a2.2,2.2,0,0,1,1.243-.625.646.646,0,0,0,.1-.029Zm-4.431,23.962c.035-.029.077-.071.118-.112L104.013,187.2c.465-.465.937-.931,1.4-1.4a.67.67,0,0,0,.194-.648c-.518-2.268-1.049-4.537-1.567-6.811a.588.588,0,0,0-.489-.483q-3.367-.769-6.734-1.55a.738.738,0,0,0-.754.212L83.22,189.359c-.035.035-.065.071-.088.094Z" fill="#e0234f" />
                  <path id="Tracé_212" data-name="Tracé 212" d="M94.935,437.276a1.706,1.706,0,0,1-.813-.53Q89.384,432,84.636,427.26a.629.629,0,0,0-.136-.094c.194-.194.359-.359.56-.566a.868.868,0,0,0,.094.147q4.808,4.8,9.6,9.592c.283.283.436.283.719,0l13.316-13.316a.838.838,0,0,0,.1-.124l.524.524c-.012.012-.041.047-.071.082l-13.4,13.4a1.526,1.526,0,0,1-.654.377C95.17,437.276,95.052,437.276,94.935,437.276Z" transform="translate(-2.682 -233.482)" fill="#e0234f" />
                  <path id="Tracé_213" data-name="Tracé 213" d="M392.437,251.6a1.346,1.346,0,1,1-1.337,1.343A1.33,1.33,0,0,1,392.437,251.6Z" transform="translate(-291.217 -72.274)" fill="#e0234f" />
                </g>
              </svg>{(ProductLang['Marque'][Lang])}</h5>
              <div className='search-mark'>
                {
                  Mark.length > 0 && Mark.map((item, i) => (

                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name={item.title} checked={MarkChecked.includes(item.title)} onChange={handleChange} defaultValue id={`flexCheckDefault${item.title}`} />
                      <label className="form-check-label search-checkbox" htmlFor={`flexCheckDefault${item.title}`}>
                        {item.title}
                      </label>
                    </div>
                  ))
                }



              </div>

            </div>

          
        </div>
      </div>
          <div className='col-12 col-lg-3 mb-5 d-none d-lg-block'>
            {
              LoadingCat &&



              <Skeleton height={200} borderRadius={20} />



            }
            <div className={LoadingCat ? 'd-none' : 'sidebar-wrapper  sidebar--categories'}>
              <h5 className="sidebarBlock-heading"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <path id="category-15" d="M102.843,109.387a5.862,5.862,0,1,1,4.127-1.717A5.792,5.792,0,0,1,102.843,109.387Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,102.843,99.146Zm0,23.554a5.862,5.862,0,1,1,4.127-1.717A5.793,5.793,0,0,1,102.843,122.7Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,102.843,112.459ZM118.265,122.7h-4.217a3.738,3.738,0,0,1-3.735-3.735v-4.217a.723.723,0,0,1,1.446,0v4.217a2.292,2.292,0,0,0,2.289,2.289h4.217a2.292,2.292,0,0,0,2.289-2.289v-4.217a2.292,2.292,0,0,0-2.289-2.289h-4.217a.723.723,0,0,1,0-1.446h4.217A3.738,3.738,0,0,1,122,114.748v4.217A3.738,3.738,0,0,1,118.265,122.7Zm-2.108-13.313a5.862,5.862,0,1,1,4.126-1.717A5.793,5.793,0,0,1,116.157,109.387Zm0-10.241a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,116.157,99.146Z" transform="translate(-97 -97.7)" fill="#ed335b" />
              </svg>
              {(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</h5>
              {/* <div>

              {CategoriesList.length > 0 && CategoriesList.map((item,i) => ( 
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" defaultValue id={`flexCheckDefault${item.nom}`} />
                  <label className="form-check-label search-checkbox" htmlFor={`flexCheckDefault${item.nom}`}>
                    {item.nom}
                  </label>
                </div>                  
              ))
              }
              </div> */}
              <div className="col-md-12 mb-3 mt-5">
                {/* <label htmlFor="inputEmail4" className="form-label">Wilaya</label> */}
                <select class="form-select" aria-label="Default select example" onChange={(e) => setCategorie(e.target.value)}>
                  <option disabled selected="selected">{(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</option>

                  {CategoriesList.length > 0 &&

                    <option value={''}>{Lang === 'ar' ? 'كل التصنيفات' : 'Tous Les Catégories'} </option>

                  }

                  <>
                    {CategoriesList.length > 0 && CategoriesList.map((item, i) => (

                      <option selected={Categorie == item.unicnom ? 'selected' : ''} value={item.unicnom}>{(Lang === 'ar' ? item.nomAr : item.nom)}</option>
                    ))
                    }
                  </>

                </select>

              </div>
              <div className="col-md-12 mb-3">
                <select class={`form-select ${SousCategoriesList.length == 0 && 'd-none'}`} aria-label="Default select example" onChange={(e) => setSousCategories(e.target.value)}>
                  {
                    SousCategoriesList.length == 0 && <option disabled selected="selected">{(Lang === 'ar' ? 'الفئات الفرعية' : 'Sous Catégories')} </option>
                  }

                  {
                    SousCategoriesList.length > 0 && <option value={''} selected="selected">{(Lang === 'ar' ? 'جميع الفئات الفرعية' : 'Tous Les Sous Catégories')} </option>
                  }

                  {SousCategoriesList.length > 0 && SousCategoriesList.map((item, i) => (
                    <option selected={SousCategories == item.unicnom ? 'selecte' : ''} value={item.unicnom}>{(Lang === 'ar' ? item.nomAr : item.nom)}</option>
                  ))
                  }
                </select>
              </div>

            </div>
            {
              LoadingMark &&


              <Skeleton height={314} borderRadius={20} />


            }
            <div className={LoadingMark ? 'd-none' : 'sidebar-wrapper  sidebar--categories mt-5'}>
              <h5 className="sidebarBlock-heading"><svg xmlns="http://www.w3.org/2000/svg" width="25.446" height="29" viewBox="0 0 25.446 29">
                <g id="mark_icon" data-name="mark icon" transform="translate(-81.65 -174.8)">
                  <path id="Tracé_211" data-name="Tracé 211" d="M96.872,174.8c.46.106.919.218,1.379.324,1.921.442,3.842.872,5.756,1.332a1.851,1.851,0,0,1,1.444,1.467q.813,3.447,1.585,6.894a2.04,2.04,0,0,1-.566,1.986Q100,193.289,93.513,199.764a1.465,1.465,0,0,1-2.168-.006q-4.613-4.6-9.209-9.2a1.465,1.465,0,0,1,0-2.18q6.46-6.46,12.915-12.921a2.2,2.2,0,0,1,1.243-.625.646.646,0,0,0,.1-.029Zm-4.431,23.962c.035-.029.077-.071.118-.112L104.013,187.2c.465-.465.937-.931,1.4-1.4a.67.67,0,0,0,.194-.648c-.518-2.268-1.049-4.537-1.567-6.811a.588.588,0,0,0-.489-.483q-3.367-.769-6.734-1.55a.738.738,0,0,0-.754.212L83.22,189.359c-.035.035-.065.071-.088.094Z" fill="#e0234f" />
                  <path id="Tracé_212" data-name="Tracé 212" d="M94.935,437.276a1.706,1.706,0,0,1-.813-.53Q89.384,432,84.636,427.26a.629.629,0,0,0-.136-.094c.194-.194.359-.359.56-.566a.868.868,0,0,0,.094.147q4.808,4.8,9.6,9.592c.283.283.436.283.719,0l13.316-13.316a.838.838,0,0,0,.1-.124l.524.524c-.012.012-.041.047-.071.082l-13.4,13.4a1.526,1.526,0,0,1-.654.377C95.17,437.276,95.052,437.276,94.935,437.276Z" transform="translate(-2.682 -233.482)" fill="#e0234f" />
                  <path id="Tracé_213" data-name="Tracé 213" d="M392.437,251.6a1.346,1.346,0,1,1-1.337,1.343A1.33,1.33,0,0,1,392.437,251.6Z" transform="translate(-291.217 -72.274)" fill="#e0234f" />
                </g>
              </svg>{(ProductLang['Marque'][Lang])}</h5>
              <div className='search-mark'>
                {
                  Mark.length > 0 && Mark.map((item, i) => (

                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name={item.title} checked={MarkChecked.includes(item.title)} onChange={handleChange} defaultValue id={`flexCheckDefault${item.title}`} />
                      <label className="form-check-label search-checkbox" htmlFor={`flexCheckDefault${item.title}`}>
                        {item.title}
                      </label>
                    </div>
                  ))
                }



              </div>

            </div>
            <div onClick={() => filter()} className='search-btn mt-5 d-none'>Filtre
              <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
                <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                  <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff" />
                  <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff" />
                  <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff" />
                </g>
              </svg>
            </div>
          </div>
          <div className='col-12 col-lg-9 separation'>
            <div className='row mb-5 mt-5 mt-lg-0'>
              {!Loading && SearchData.length === 0 && <div className='text-center mt-5'>{(Lang === 'ar' ? 'لا توجد نتائج' : 'Pas de résultats')} <br></br>
                <img className='mt-5' width={300} src='/img/noresfound.png' />
              </div>}

              {
                Loading && SkeletonArray.map((item, i) => (


                  <div className="col">
                    <Skeleton height={314} width={258} />
                  </div>

                ))
              }

              {
                SearchData.length > 0 && SearchData.map((item, i) => (


                  <div className="col">
                    <Link to={`/product/${item.hash}`} href="#">
                      <div className="card-product">
                        <div className="first-section-product">
                          <div className="img-product"><img className="img-fluid" src={`https://www.diardzair.com.dz/${item['image']}`} /></div>
                          <div className="title-product">{item.nom.length > 40 ? item.nom.substring(0, 40) + '....' : item.nom.substring(0, 40)}</div>
                          <div className="mark-product">{item.marque}</div>
                        </div>
                        <div className="product-page-price price-product">
                          <div className="tag-taksit">تقسيط</div>
                          <div className={Lang === 'ar' ? "price d-flex" :"price" }>{(Lang === 'ar' ? <> <span className='me-1'> {( Global['Da'][Lang] )} </span> {GetTaksitPrice(parseInt(item?.prix))['36']}</> : <>{GetTaksitPrice(parseInt(item?.prix))['36']}<span> {( Global['Da'][Lang] )}</span></>)}</div>
                        </div>
                      </div>
                    </Link>
                  </div>

                ))
              }


              {PageNumber > 1 &&
                <div class="text-center mt-5 pt-5 mb-5 pt-5">
                  <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${pageQuery}`}>
                    {CurrentPage > 1 ? <a  onClick={() => Pagination("firstPage")}>{'<<'}</a> : <a className='non-clickable'>{'<<'}</a>}

                    {CurrentPage !== '1' ? <a onClick={() => Pagination("Back", CurrentPage - 1)} >&laquo;</a> : <a className='non-clickable'>&laquo;</a>}



                    {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                      <>
                        {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                          <a  onClick={() => Pagination("allPage", item + 1)} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                          : ''}
                      </>

                    ))}



                    {CurrentPage < PageNumber ? <a onClick={() => Pagination("Next", parseInt(CurrentPage) + 1)} >&raquo;</a> : <a className='non-clickable'>&raquo;</a>}

                    {CurrentPage < PageNumber ? <a  onClick={() => Pagination("lastPage", PageNumber)}>{'>>'}</a> : <a className='non-clickable'>{'>>'}</a>}
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Search