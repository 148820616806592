import {useContext} from 'react'
import LangContext from '../../Context/lang';


const Description = () => {
  const { Lang, setLang, NavMenu, Global,dari3mardef } = useContext(LangContext);

  return (
    <div className='description'>
        <svg className='svg-bt' xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="885" height="370" viewBox="0 0 885 370">
  <defs>
    <linearGradient id="linear-gradient" x1="0.484" y1="-0.209" x2="0.074" y2="1.419" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#7ff6ad"/>
      <stop offset="1" stop-color="#37d9e1"/>
      <stop offset="1"/>
    </linearGradient>
  </defs>
  <path id="Rectangle_197" data-name="Rectangle 197" d="M154,0H885a0,0,0,0,1,0,0V370a0,0,0,0,1,0,0H160A160,160,0,0,1,0,210V154A154,154,0,0,1,154,0Z" fill="url(#linear-gradient)"/>
</svg>
<svg className='svg-tp' xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="885" height="65" viewBox="0 0 885 65">
  <defs>
    <linearGradient id="linear-gradient" x1="0.334" y1="-1.729" x2="0.074" y2="1.419" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#7ff6ad"/>
      <stop offset="1" stop-color="#37d9e1"/>
      <stop offset="1" stop-color="#3ddcdc"/>
      <stop offset="1" stop-color="#37d9e1"/>
      <stop offset="1"/>
    </linearGradient>
  </defs>
  <rect id="Rectangle_201" data-name="Rectangle 201" width="885" height="65" rx="32.5" fill="url(#linear-gradient)"/>
</svg>

        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='desc-titel'>
                    {(dari3mardef['titre'][Lang])}
                    </div>
                    <div className='desc-desc'>
                     <p>
                     {(dari3mardef['definition'][Lang])}
                     </p>
                    </div>
                    <a href="/dari3mar/dossier"> <div className='box-btn'>{( Global['Plus'][Lang] )}</div></a>

                </div>
                <div className='col-lg-6 mt-5 mt-lg-0' style={{zIndex : +1}}>
                    <img className='desc-img img-fluid' src='./img/Image 6.png' />
                </div>
                

            </div>
        </div> 

    </div>
  )
}

export default Description