import {useContext} from 'react'
import LangContext from '../../Context/lang';

const Dossier = () => {
  const { Lang, Global,idjarsection1 } = useContext(LangContext);

    return (
        <div className='doc-daridjar'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-5'>
                        <img className='img-fluid' src='/img/idjardoc.png' />
                    </div>
                    <div className='col-12 col-lg-7 mt-5 mt-lg-0'>
                        <div className='doc-text'>
                            <h1>{(idjarsection1['titre'][Lang])}</h1>
                            <p className='mt-4'>{(idjarsection1['sous-titre'][Lang])}</p>
                            <a href='/daridjar/dossier'>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="245" height="43" viewBox="0 0 245 43">
                                    <defs>
                                        <linearGradient id="linear-gradient" x1="0.484" y1="-0.209" x2="0.074" y2="1.419" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#64d594" />
                                            <stop offset="1" stop-color="#2dd2c7" />
                                            <stop offset="1" stop-color="#37d9e1" />
                                            <stop offset="1" />
                                        </linearGradient>
                                    </defs>
                                    <rect id="Rectangle_204" data-name="Rectangle 204" width="245" height="43" rx="21.5" fill="url(#linear-gradient)" />
                                    <text id="Voir_Plus" data-name="Voir Plus" transform="translate(42 27)" fill="#fff" font-size="18" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">{(idjarsection1['voirplus'][Lang])} </tspan></text>
                                    <g id="show-more-icon-5" transform="translate(-339.179 -93.328)">
                                        <path id="Tracé_211" data-name="Tracé 211" d="M550.01,114.547A15.762,15.762,0,1,1,534.454,98.83,15.739,15.739,0,0,1,550.01,114.547ZM534.275,100.23c-7.833-.261-14.51,6.655-14.406,14.36a14.4,14.4,0,1,0,28.8-.071C548.639,106.724,541.941,99.987,534.275,100.23Z" transform="translate(0 0)" fill="#fff" />
                                        <path id="Tracé_212" data-name="Tracé 212" d="M733.161,377.494H721.218c-.764,0-1.114-.211-1.121-.675s.35-.7,1.1-.7h12.01c-.175-.193-.271-.311-.382-.414q-1.73-1.623-3.463-3.238c-.082-.075-.164-.146-.239-.229a.654.654,0,0,1-.039-.982.637.637,0,0,1,.978,0q2.683,2.5,5.355,5.013a.668.668,0,0,1,.014,1.064q-2.48,2.474-4.977,4.927a.635.635,0,0,1-.978.032.65.65,0,0,1,.046-1.018q1.623-1.612,3.249-3.22c.143-.143.311-.257.468-.386C733.214,377.615,733.189,377.555,733.161,377.494Z" transform="translate(-194.412 -262.487)" fill="#fff" />
                                    </g>
                                </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dossier