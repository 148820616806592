import { useEffect, useState } from 'react'
import axios from "axios";
import Skeleton from 'react-loading-skeleton'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Packpromo = () => {

  const [Packs, SetPacks] = useState('');
  const [Loading, setLoading] = useState(true)



  useEffect(() => {
    setLoading(true)
    axios.get('https://diardzair.com.dz/ws/homepage/slides?type=pack').then(response => {
      console.log('diar Packs')
      console.log(response.data[0])
      SetPacks(response.data[0])
      console.log('diar Packs 2')
      setLoading(false)

      // console.log(Packs[1]['imageDesk']['webPath'])
    });
    // console.log(window.innerWidth)
    // if (window.innerWidth < 600 ) {
    //      setMediaWidth('Mobile') 
    // }
    // console.log(MediaWidth)       
  }, []);


  return (
    <section className="pack-promo mt-2 pt-3 mb-1 text-center">
      {/* <div>
        <img src="./img/bnas.png" />
        <img src="./img/bnas.png" />
        <img src="./img/bnas.png" />
      </div> */}
      <div className="container">
        <div className="title-section">Pack Promotionnel</div>
        <div className="row gy-5  d-none d-lg-flex ">
          {!Loading && Packs.length > 0 && Packs.map((item, i) => (
            <>
              <div className="col-12 col-lg-6">
                <a href={'product/' + item['link']}>
                  <img loading='lazy' className="img-fluid" src={Packs !== '' ? `https://www.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/pack.png"} />
                </a>
              </div>
              {/* <div className="col-12 col-lg-6"><img loading='lazy' className="img-fluid" src={Packs !== '' ? `https://www.diardzair.com.dz/${Packs[1]['imageDesk']['webPath']}` :  "img/pack2.png"} /></div>
        <div className="col-12 col-lg-6"><img loading='lazy' className="img-fluid" src={Packs !== '' ? `https://www.diardzair.com.dz/${Packs[0]['imageDesk']['webPath']}` :  "img/pack.png"} /></div>
        <div className="col-12 col-lg-6"><img loading='lazy' className="img-fluid" src={Packs !== '' ? `https://www.diardzair.com.dz/${Packs[1]['imageDesk']['webPath']}` :  "img/pack2.png"} /></div> */}
            </>
          ))
          }

          {Loading &&
            <>
              <div className="col-12 col-lg-6" style={{ overflow: 'hidden' }}>
                <Skeleton height={224}  />

              </div>
              <div className="col-12 col-lg-6" style={{ overflow: 'hidden' }}>
                <Skeleton height={224}  />

              </div>
              <div className="col-12 col-lg-6" style={{ overflow: 'hidden' }}>
                <Skeleton height={224}  />

              </div>
              <div className="col-12 col-lg-6" style={{ overflow: 'hidden' }}>
                <Skeleton height={224}  />

              </div>             
            </>

          }
        </div>

        {/* <div id="PackPromoCarousel" className="carousel slide d-lg-none d-flex" data-ride="carousel" data-touch="true" data-bs-interval="false"> */}
        {/* <div className="carousel-inner"> */}

        <Swiper
          // install Swiper modules
          modules={[Navigation, Autoplay]}
          loop={true}
          // spaceBetween={20}
          slidesPerView={1}
          // breakpoints={{
          //   // when window width is >= 640px
          //   640: {
          //     slidesPerView: 1,
          //   },
          //   // when window width is >= 768px
          //   768: {
          //     slidesPerView: 2,
          //   },
          //   992: {
          //     slidesPerView: 3,
          //     spaceBetween: 8
          //   },
          //   1200: {
          //     slidesPerView: 5,
          //     spaceBetween: 20,

          //   },

          // }}
          navigation
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          className='d-lg-none d-flex'
        >

          {Packs.length > 0 && Packs.map((item, i) => (
            <SwiperSlide>
              {/* <div className={`carousel-item ${i === 0 && "active"}` }> */}
              <a href={'product/' + item['link']}>
                <img loading='lazy' className="img-fluid" src={Packs !== '' ? `https://www.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/pack.png"} />
              </a>
              {/* </div> */}
            </SwiperSlide>
          ))
          }
        </Swiper>
        {/* <div className="carousel-item active">
            <img src="img/pack.png" className="d-block w-100" alt="img/pack.png" />
          </div>
          <div className="carousel-item">
            <img src="img/pack2.png" className="d-block w-100" alt="img/pack.png" />
          </div>
          <div className="carousel-item">
            <img src="img/pack.png" className="d-block w-100" alt="img/pack.png" />
          </div> */}
        {/* </div> */}
        {/* <button className="carousel-control-prev" type="button" data-bs-target="#PackPromoCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#PackPromoCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button> */}
        {/* </div> */}


      </div>
    </section>

  )
}

export default Packpromo