import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import LangContext from '../../Context/lang';


const Register = ({ Alert, setAlert }) => {

  const navigate = useNavigate();

  const [email, setemail] = useState('')
  const [userName, setuserName] = useState('')
  const [pass, setpass] = useState('')
  const [repass, setrepass] = useState('')
  const [err, seterr] = useState(false)
  const [loading, setloading] = useState(false)

  const { Lang, Global, Account } = useContext(LangContext);




  function Register(e) {

    e.preventDefault();
    console.log(email, userName, pass);

    setloading(true)


    axios.post(`https://www.diardzair.com.dz/alogin/rest/register`, { "username": userName, "email": email, "password": pass }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(function (response) {

        console.log(response.data);

        if (response.data['errorCode'] == 1) {
          setloading(false)
          setAlert('Compte créé avec succès')
          return navigate('/account?page=login')
        }

        if (response.data['errorCode'] == "-5") {
          seterr("l'email existe déjà")
        } else if (response.data['errorCode'] == "-4") {
          seterr("L'utilisateur existe déjà")
        }

        setloading(false)


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (

    <div className='container'>
      {err &&
        <div className="alert alert-danger" role="alert">
          {err}
        </div>}
      {loading && <div className="screen">
        <div className="loader">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>}
      <form className="row g-3 m-auto justify-content-center" onSubmit={Register}>
        <div className="col-md-8">
          <label htmlFor="inputEmail" className="form-label">{(Account['e-mail'][Lang])}</label>
          <input type="email" required onChange={(e) => setemail(e.target.value)} className="form-control" id="inputEmail" />
        </div>
        <div className="col-md-8">
          <label htmlFor="inputusername" className="form-label">{(Account['Nomdutilisateur'][Lang])}</label>
          <input type="text" required onChange={(e) => setuserName(e.target.value)} className="form-control" id="inputusername" />
        </div>
        <div className="col-md-8">
          <label htmlFor="inputPasswordone" className="form-label">{(Account['MotdePasse'][Lang])}</label>
          <input type="password" required onChange={(e) => setpass(e.target.value)} className="form-control" id="inputPasswordone" />
        </div>
        <div className="col-md-8">
          <label htmlFor="inputPasswordtwo" className="form-label">{(Account['Répéterlemotdepasse'][Lang])}</label>
          <input type="password" required onChange={(e) => setrepass(e.target.value)} className="form-control" id="inputPasswordtwo" />
        </div>

        <div className="col-8">
          <button type="submit" className="login-btn">{(Account['Créeruncompte'][Lang])}</button>
        </div>
        
        {/* <div className="col-12 text-center">
          <button type="submit" className="btn-google login-btn-fb">
            <svg xmlns="http://www.w3.org/2000/svg" width="38.25" height="39.027" viewBox="0 0 38.25 39.027">
              <g id="_7123025_logo_google_g_icon" data-name="7123025_logo_google_g_icon" transform="translate(-30.025 -29.098)">
                <path id="Tracé_249" data-name="Tracé 249" d="M94.648,70.754a24.405,24.405,0,0,0-.34-3.954H75.9v7.525H86.443a8.869,8.869,0,0,1-3.911,5.909l6.292,4.889a19.189,19.189,0,0,0,5.824-14.369Z" transform="translate(-26.373 -21.674)" fill="#4280ef" />
                <path id="Tracé_250" data-name="Tracé 250" d="M52.33,99.629a18.667,18.667,0,0,0,12.924-4.719l-6.292-4.846a11.836,11.836,0,0,1-17.6-6.164L34.9,88.874A19.494,19.494,0,0,0,52.33,99.629Z" transform="translate(-2.803 -31.505)" fill="#34a353" />
                <path id="Tracé_251" data-name="Tracé 251" d="M38.559,66.9a11.854,11.854,0,0,1,0-7.482L32.1,54.4a19.539,19.539,0,0,0,0,17.515Z" transform="translate(0 -14.546)" fill="#f6b704" />
                <path id="Tracé_252" data-name="Tracé 252" d="M52.33,36.836a10.646,10.646,0,0,1,7.482,2.933l5.569-5.612A18.779,18.779,0,0,0,52.33,29.1,19.494,19.494,0,0,0,34.9,39.854l6.462,5.016A11.673,11.673,0,0,1,52.33,36.836Z" transform="translate(-2.803 0)" fill="#e54335" />
              </g>
            </svg>

            Continuer avec Google</button>
        </div> */}
        
      </form>
    </div>

  )
}

export default Register