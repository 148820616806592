
import { useEffect, useState, useContext } from 'react'
import axios from "axios";
import LangContext from '../../Context/lang';
import Skeleton from 'react-loading-skeleton'




function Categories() {


  const [DartaksitMENU, setDartaksitMENU] = useState([])
  const [subMenu, setsubMenu] = useState([])

  const { Lang, setLang, NavMenu } = useContext(LangContext);



  useEffect(() => {

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/allCategorie?unicnom=Dar-Taksit`);
        console.log(response[0]);
        const datamenu = response[0];
        // console.log(DartaksitMENU)    

        // for (let index = 0; index < datamenu.length; index++) {
        //   const {data: response} = await axios.get(`https://www.diardzair.com.dz/ws/categorie?unicnom=${datamenu[index].unicnom}`);
        //   setsubMenu(v => [...v , ...response[0]])
        // }

        const result = datamenu.filter(word => word.profondeur == 2);
        const result2 = datamenu.filter(word => word.profondeur == 3);


        // const map1 = result.map(myFunction);

        // function myFunction(num) {
        //   const childdata = datamenu.filter(word => word.idParent == num.id);
        //   return {...num ,...{"child" : childdata}} ;
        // }

        setDartaksitMENU(result);
        setsubMenu(result2)


        console.log(subMenu);


      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

  }, []);


  return (
    <>
      <section className='mobile-dar-taksit-cat d-flex d-lg-none' data-bs-toggle="offcanvas" href="#offcanvasExample2" role="button" aria-controls="offcanvasExample2">
        <div>
          <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="category-15" d="M101.675,107.049a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,101.675,107.049Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,101.675,98.857Zm0,18.843a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,101.675,117.7Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,101.675,109.507Zm12.337,8.193h-3.374a2.991,2.991,0,0,1-2.988-2.988v-3.374a.578.578,0,0,1,1.157,0v3.374a1.834,1.834,0,0,0,1.831,1.831h3.374a1.834,1.834,0,0,0,1.831-1.831v-3.374a1.834,1.834,0,0,0-1.831-1.831h-3.374a.578.578,0,0,1,0-1.157h3.374A2.991,2.991,0,0,1,117,111.339v3.374A2.991,2.991,0,0,1,114.012,117.7Zm-1.687-10.651a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,112.325,107.049Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,112.325,98.857Z" transform="translate(-97 -97.7)" fill="#fff" />
          </svg>
          <span>{(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</span>
        </div>
        <div> {(Lang === 'ar' ? 'شاهد' : 'Voir')}</div>

      </section>
      <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample2" aria-labelledby="offcanvasExampleLabel2">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">{(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body">

          <div className="accordion accordion-flush" id="accordionFlushExample">

            {/* </div> */}

            {DartaksitMENU.length > 0 && DartaksitMENU.map((item, i) => (
              <>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#flush-collapse' + i} aria-expanded="false" aria-controls={'flush-collapse' + i}>
                      {Lang === 'ar' ? item.nomAr : item.nom}
                    </button>
                  </h2>
                  <div id={'flush-collapse' + i} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className='taksit-cat-mobile-list'><a className='all-product-taksit-link' href={`/search/?page=1&parent=${item.unicnom}`}> {Lang === 'ar' ? 'كل المنتوجات' : 'Tous Les Produits'}</a></div>
                      {
                        subMenu.filter(cat_name => cat_name.idParent == item.id).map((subitem, i) => (
                          <>
                            <div className='taksit-cat-mobile-list'><a href={`/search/?page=1&parent=${item.unicnom}&categorie=${subitem.unicnom}`}> {'°  ' + (Lang === 'ar' ? subitem.nomAr : subitem.nom)}</a></div>
                          </>
                        ))
                      }</div>
                  </div>
                </div>
                {/* <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18.003" viewBox="0 0 18 18.003">
                  <g id="icons8-appliances-50" transform="translate(-18.255 -23.332)">
                    <path id="Tracé_1" data-name="Tracé 1" d="M30.68,30.193h.369c1.256,0,2.512,0,3.769,0a1.288,1.288,0,0,1,1.434,1.42q.006,4.142,0,8.285A1.3,1.3,0,0,1,34.8,41.332q-3.6,0-7.2,0c-1.056,0-1.379-.241-1.67-1.262h-3.4c0,.253.005.508,0,.761-.008.348-.144.492-.493.5q-.779.012-1.557,0c-.376-.005-.51-.154-.516-.542,0-.218,0-.436-.007-.653a.758.758,0,0,0-.02-.08c-.084,0-.182,0-.28,0a1.3,1.3,0,0,1-1.4-1.4q-.006-3.364,0-6.728,0-3.55,0-7.1a1.307,1.307,0,0,1,1.485-1.493q4.719,0,9.438,0a1.317,1.317,0,0,1,1.5,1.511v4.984C30.68,29.937,30.68,30.048,30.68,30.193Zm-4.718,9V37.317q0-2.835,0-5.67A1.306,1.306,0,0,1,27.4,30.193c.686,0,1.37,0,2.056,0H29.8V28.919H19.125c-.007.075-.018.135-.018.195q0,4.766,0,9.532c0,.4.147.541.549.541q2.258,0,4.517,0Zm.858-3.433c0,1.391,0,2.783,0,4.174,0,.4.148.551.544.551q3.738,0,7.477,0c.412,0,.558-.152.559-.572q0-4.144,0-8.287c0-.438-.142-.583-.579-.584q-3.708,0-7.414,0c-.453,0-.588.142-.588.6Q26.82,33.7,26.82,35.756Zm3-7.733c0-1.112,0-2.191,0-3.271,0-.436-.137-.572-.583-.572q-4.766,0-9.533,0c-.459,0-.6.137-.6.595q0,1.495,0,2.991c0,.081.012.161.019.256ZM20.851,40.071v.389h.81v-.389Z" fill="#989898"/>
                    <path id="Tracé_2" data-name="Tracé 2" d="M39.446,88.341c0-.28,0-.559,0-.838a.4.4,0,0,1,.411-.446.394.394,0,0,1,.439.42c.016.568.014,1.139,0,1.707a.407.407,0,0,1-.428.436c-.248.007-.41-.18-.424-.472v-.031C39.445,88.859,39.445,88.6,39.446,88.341Z" transform="translate(-19.05 -57.289)" fill="#989898"/>
                    <path id="Tracé_3" data-name="Tracé 3" d="M122.748,125.28a2.565,2.565,0,1,1-2.568,2.569A2.468,2.468,0,0,1,122.748,125.28Zm-.039,4.272a1.712,1.712,0,0,0,.07-3.424,1.712,1.712,0,1,0-.07,3.424Z" transform="translate(-91.633 -91.654)" fill="#989898"/>
                    <path id="Tracé_4" data-name="Tracé 4" d="M154.59,108.219a.429.429,0,1,1-.424.408A.426.426,0,0,1,154.59,108.219Z" transform="translate(-122.187 -76.316)" fill="#989898"/>
                    <path id="Tracé_5" data-name="Tracé 5" d="M171.905,108.646a.428.428,0,1,1-.411-.429A.439.439,0,0,1,171.905,108.646Z" transform="translate(-137.367 -76.313)" fill="#989898"/>
                    <path id="Tracé_6" data-name="Tracé 6" d="M40.3,41.349c0,.208.011.416,0,.623a.422.422,0,0,1-.453.446.412.412,0,0,1-.4-.462q0-.607,0-1.215a.413.413,0,0,1,.4-.461c.247-.025.426.161.454.447,0,.02,0,.041,0,.063v.56Z" transform="translate(-19.05 -15.234)" fill="#989898"/>
                  </g>
                </svg> */}


                {/* <span>{item.nom}</span>
                    <svg className='menu-btn' xmlns="http://www.w3.org/2000/svg" width="6.437" height="12.333" viewBox="0 0 6.437 12.333">
                      <path id="nexticon" d="M68.167,50.761c-.252-.139-.275-.315-.064-.525q2.768-2.768,5.535-5.534c.033-.033.074-.06.144-.117a1.059,1.059,0,0,1-.144-.1q-2.765-2.762-5.527-5.527a.566.566,0,0,1-.138-.229.222.222,0,0,1,.115-.255.238.238,0,0,1,.282,0,.723.723,0,0,1,.11.092l5.774,5.773c.189.189.189.321,0,.511l-3.342,3.342-2.424,2.422a1.557,1.557,0,0,1-.2.148Z" transform="translate(-67.96 -38.429)"/>
                    </svg> */}

                {/* <ul className='second-child'>
                      {
                        subMenu.filter(cat_name => cat_name.parent == item.unicnom).map((item,i) => (
                          <>
                          <li><a href='#'> {'°  ' + item.nom}</a></li>
                          </>
                        ))
                      } */}
                {/* <li><a href='#'>Lave Linge</a> </li>
                        <li><a href='#'>Climatiseurs</a> </li>
                        <li><a href='#'>Micro-ondes</a> </li> */}
                {/* <li><a href='#'>Robots Pétrins</a></li>
                        <li><a href='#'>Cuisinères</a></li>
                        <li><a href='#'>Plaques de cuisson</a></li>
                        <li><a href='#'>Fours</a></li>
                        <li><a href='#'>Congélateurs</a></li>
                        <li><a href='#'>Lave-vaisselle</a></li>
                        <li><a href='#'>Démos</a></li>
                        <li><a href='#'>Hottes</a></li>
                        <li><a href='#'>Petit électroménager</a></li>
                        <li><a href='#'>Chauffages</a></li>
                        <li><a href='#'>Distributeurs D'eau</a></li> */}
                {/* </ul>
                </li> */}
              </>

            ))
            }
          </div>
        </div>
      </div>
      <section className='slide-categories-content-categories d-none d-lg-block'>
        {
          DartaksitMENU.length === 0 && <Skeleton height={500} style={{minHeight:'500px'}} borderRadius={20} className="slide-skeleton" />
        }
        <ul className={DartaksitMENU.length > 0 ? 'first-child' : 'd-none'}>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path id="category-15" d="M101.675,107.049a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,101.675,107.049Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,101.675,98.857Zm0,18.843a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,101.675,117.7Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,101.675,109.507Zm12.337,8.193h-3.374a2.991,2.991,0,0,1-2.988-2.988v-3.374a.578.578,0,0,1,1.157,0v3.374a1.834,1.834,0,0,0,1.831,1.831h3.374a1.834,1.834,0,0,0,1.831-1.831v-3.374a1.834,1.834,0,0,0-1.831-1.831h-3.374a.578.578,0,0,1,0-1.157h3.374A2.991,2.991,0,0,1,117,111.339v3.374A2.991,2.991,0,0,1,114.012,117.7Zm-1.687-10.651a4.689,4.689,0,1,1,3.3-1.373A4.634,4.634,0,0,1,112.325,107.049Zm0-8.193a3.518,3.518,0,1,0,3.518,3.518A3.522,3.522,0,0,0,112.325,98.857Z" transform="translate(-97 -97.7)" fill="#fff" />
            </svg>

            <span> {(Lang === 'ar' ? 'التصنيفات' : 'Catégories')}</span>
          </li>

          {DartaksitMENU.length > 0 && DartaksitMENU.map((item, i) => (
            <>
              <li>
                {/* <a href={`/search/?page=1&parent=${item.unicnom}`}> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18.003" viewBox="0 0 18 18.003">
                  <g id="icons8-appliances-50" transform="translate(-18.255 -23.332)">
                    <path id="Tracé_1" data-name="Tracé 1" d="M30.68,30.193h.369c1.256,0,2.512,0,3.769,0a1.288,1.288,0,0,1,1.434,1.42q.006,4.142,0,8.285A1.3,1.3,0,0,1,34.8,41.332q-3.6,0-7.2,0c-1.056,0-1.379-.241-1.67-1.262h-3.4c0,.253.005.508,0,.761-.008.348-.144.492-.493.5q-.779.012-1.557,0c-.376-.005-.51-.154-.516-.542,0-.218,0-.436-.007-.653a.758.758,0,0,0-.02-.08c-.084,0-.182,0-.28,0a1.3,1.3,0,0,1-1.4-1.4q-.006-3.364,0-6.728,0-3.55,0-7.1a1.307,1.307,0,0,1,1.485-1.493q4.719,0,9.438,0a1.317,1.317,0,0,1,1.5,1.511v4.984C30.68,29.937,30.68,30.048,30.68,30.193Zm-4.718,9V37.317q0-2.835,0-5.67A1.306,1.306,0,0,1,27.4,30.193c.686,0,1.37,0,2.056,0H29.8V28.919H19.125c-.007.075-.018.135-.018.195q0,4.766,0,9.532c0,.4.147.541.549.541q2.258,0,4.517,0Zm.858-3.433c0,1.391,0,2.783,0,4.174,0,.4.148.551.544.551q3.738,0,7.477,0c.412,0,.558-.152.559-.572q0-4.144,0-8.287c0-.438-.142-.583-.579-.584q-3.708,0-7.414,0c-.453,0-.588.142-.588.6Q26.82,33.7,26.82,35.756Zm3-7.733c0-1.112,0-2.191,0-3.271,0-.436-.137-.572-.583-.572q-4.766,0-9.533,0c-.459,0-.6.137-.6.595q0,1.495,0,2.991c0,.081.012.161.019.256ZM20.851,40.071v.389h.81v-.389Z" fill="#989898" />
                    <path id="Tracé_2" data-name="Tracé 2" d="M39.446,88.341c0-.28,0-.559,0-.838a.4.4,0,0,1,.411-.446.394.394,0,0,1,.439.42c.016.568.014,1.139,0,1.707a.407.407,0,0,1-.428.436c-.248.007-.41-.18-.424-.472v-.031C39.445,88.859,39.445,88.6,39.446,88.341Z" transform="translate(-19.05 -57.289)" fill="#989898" />
                    <path id="Tracé_3" data-name="Tracé 3" d="M122.748,125.28a2.565,2.565,0,1,1-2.568,2.569A2.468,2.468,0,0,1,122.748,125.28Zm-.039,4.272a1.712,1.712,0,0,0,.07-3.424,1.712,1.712,0,1,0-.07,3.424Z" transform="translate(-91.633 -91.654)" fill="#989898" />
                    <path id="Tracé_4" data-name="Tracé 4" d="M154.59,108.219a.429.429,0,1,1-.424.408A.426.426,0,0,1,154.59,108.219Z" transform="translate(-122.187 -76.316)" fill="#989898" />
                    <path id="Tracé_5" data-name="Tracé 5" d="M171.905,108.646a.428.428,0,1,1-.411-.429A.439.439,0,0,1,171.905,108.646Z" transform="translate(-137.367 -76.313)" fill="#989898" />
                    <path id="Tracé_6" data-name="Tracé 6" d="M40.3,41.349c0,.208.011.416,0,.623a.422.422,0,0,1-.453.446.412.412,0,0,1-.4-.462q0-.607,0-1.215a.413.413,0,0,1,.4-.461c.247-.025.426.161.454.447,0,.02,0,.041,0,.063v.56Z" transform="translate(-19.05 -15.234)" fill="#989898" />
                  </g>
                </svg>


                <span>{Lang === 'ar' ? item.nomAr : item.nom}</span>
                <svg className='menu-btn' xmlns="http://www.w3.org/2000/svg" width="6.437" height="12.333" viewBox="0 0 6.437 12.333">
                  <path id="nexticon" d="M68.167,50.761c-.252-.139-.275-.315-.064-.525q2.768-2.768,5.535-5.534c.033-.033.074-.06.144-.117a1.059,1.059,0,0,1-.144-.1q-2.765-2.762-5.527-5.527a.566.566,0,0,1-.138-.229.222.222,0,0,1,.115-.255.238.238,0,0,1,.282,0,.723.723,0,0,1,.11.092l5.774,5.773c.189.189.189.321,0,.511l-3.342,3.342-2.424,2.422a1.557,1.557,0,0,1-.2.148Z" transform="translate(-67.96 -38.429)" />
                </svg>
                {/* </a> */}

                <ul className='second-child'>
                  <li><a className='all-product-taksit-link' href={`/search/?page=1&parent=${item.unicnom}`}> - {Lang === 'ar' ? 'كل المنتوجات' : 'Tous Les Produits'}</a></li>

                  {
                    subMenu.filter(cat_name => cat_name.idParent == item.id).map((subitem, i) => (
                      <>
                        <li><a href={`/search/?page=1&parent=${item.unicnom}&categorie=${subitem.unicnom}`}>{'°  ' + (Lang === 'ar' ? subitem.nomAr : subitem.nom)}  </a></li>
                      </>
                    ))
                  }
                  {/* <li><a href='#'>Lave Linge</a> </li>
                        <li><a href='#'>Climatiseurs</a> </li>
                        <li><a href='#'>Micro-ondes</a> </li> */}
                  {/* <li><a href='#'>Robots Pétrins</a></li>
                        <li><a href='#'>Cuisinères</a></li>
                        <li><a href='#'>Plaques de cuisson</a></li>
                        <li><a href='#'>Fours</a></li>
                        <li><a href='#'>Congélateurs</a></li>
                        <li><a href='#'>Lave-vaisselle</a></li>
                        <li><a href='#'>Démos</a></li>
                        <li><a href='#'>Hottes</a></li>
                        <li><a href='#'>Petit électroménager</a></li>
                        <li><a href='#'>Chauffages</a></li>
                        <li><a href='#'>Distributeurs D'eau</a></li> */}
                </ul>

              </li>
            </>

          ))
          }




          {/* <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="20.481" height="16.003" viewBox="0 0 20.481 16.003">
  <path id="Home_Furniture" data-name="Home Furniture" d="M40.919,54.579a.46.46,0,0,1-.451.229c-.76-.006-1.519,0-2.279,0h-.24v.234c0,.555,0,1.108,0,1.663,0,.324-.113.438-.431.439q-1.069,0-2.139,0c-.311,0-.423-.112-.423-.42,0-.627,0-1.254,0-1.9H26.283v.6c0,.447,0,.895,0,1.342a.325.325,0,0,1-.351.369q-1.14.007-2.279,0c-.227,0-.344-.133-.345-.375,0-.574,0-1.149,0-1.723v-.235h-.242q-1.089,0-2.179,0c-.336,0-.444-.109-.444-.444V49.914c0-.329.1-.433.426-.435.239,0,.476,0,.737,0V41.61c0-.376.094-.47.474-.47h17.2c.393,0,.485.09.485.479V49.48c.244,0,.475.01.705,0a.457.457,0,0,1,.457.213Q40.919,52.133,40.919,54.579Zm-19.811-.455H40.242V50.148H21.108ZM39.079,41.808H22.289v6.07a2.824,2.824,0,0,1,1.6-.894c-.025-.055-.042-.1-.063-.136a1.5,1.5,0,0,1,1.347-2.206q1.72-.005,3.439,0A1.5,1.5,0,0,1,29.979,46.8c-.022.045-.037.093-.057.145h1.521c-.04-.089-.075-.16-.1-.233a1.489,1.489,0,0,1,1.323-2.065q1.809-.027,3.619,0a1.475,1.475,0,0,1,1.4,1.744,5.116,5.116,0,0,1-.189.595,2.754,2.754,0,0,1,1.585.855C39.079,45.807,39.079,43.814,39.079,41.808Zm-.019,7.473a1.928,1.928,0,0,0-1.63-1.629,12.672,12.672,0,0,0-1.354-.013c-1.626-.008-3.251-.027-4.876-.025-1.919,0-3.837.018-5.756.024-.365,0-.732-.024-1.1-.025a2.08,2.08,0,0,0-1.971,1.4c-.029.084-.043.174-.066.271ZM26.883,46.956c.593,0,1.187,0,1.78,0a.812.812,0,0,0,.787-1.011.793.793,0,0,0-.773-.637c-1.193-.011-2.387,0-3.579,0a.8.8,0,0,0-.119.013.827.827,0,0,0-.666.909.8.8,0,0,0,.79.727C25.7,46.957,26.291,46.956,26.883,46.956Zm7.572,0c.6,0,1.2,0,1.8,0a.8.8,0,0,0,.792-.744.828.828,0,0,0-.9-.909c-1.112,0-2.224,0-3.336,0a1.438,1.438,0,0,0-.239.018.837.837,0,0,0-.683.861.812.812,0,0,0,.808.775Zm-8.835,9.508V54.818H23.972v1.646Zm10-1.647v1.647h1.645V54.817Z" transform="translate(-20.44 -41.14)" fill="#989898"/>
</svg>

                    <span>Meubles Maison</span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                    <ul className='second-child'>
                        <li><a href='#'>Armoires</a></li>
                        <li><a href='#'>Chambres d'enfants</a></li>
                        <li><a href='#'>Chambres adultes</a></li>
                        <li><a href='#'>Livings</a></li>
                        <li><a href='#'>Salons</a></li>
                        <li><a href='#'>Cuisines</a></li>
                        <li><a href='#'>Matelas</a></li>
                        <li><a href='#'>Tables basseeurs D'eau</a></li>
                    </ul>
                </li>
                <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="12.743" height="22" viewBox="0 0 12.743 22">
  <g id="mobile-application-2645" transform="translate(73.003 126.036)">
    <g id="Groupe_1" data-name="Groupe 1" transform="translate(-73.003 -126.036)">
      <path id="Tracé_7" data-name="Tracé 7" d="M64.036,0H54.773a1.742,1.742,0,0,0-1.74,1.74V20.26A1.742,1.742,0,0,0,54.773,22h9.263a1.742,1.742,0,0,0,1.74-1.74V1.74A1.742,1.742,0,0,0,64.036,0ZM53.522,3.035H65.287V18.6H53.522ZM54.773.489h9.263A1.253,1.253,0,0,1,65.287,1.74v.806H53.522V1.74A1.252,1.252,0,0,1,54.773.489Zm9.263,21.022H54.773a1.253,1.253,0,0,1-1.251-1.251V19.088H65.287V20.26A1.253,1.253,0,0,1,64.036,21.511Z" transform="translate(-53.033)" fill="#989898"/>
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="0.489" cy="0.489" r="0.489" transform="translate(5.883 19.81)" fill="#989898"/>
      <path id="Tracé_8" data-name="Tracé 8" d="M101.294,15.2h-2.7a.244.244,0,1,1,0-.489h2.7a.244.244,0,0,1,0,.489Z" transform="translate(-94.392 -13.431)" fill="#989898"/>
      <circle id="Ellipse_2" data-name="Ellipse 2" cx="0.367" cy="0.367" r="0.367" transform="translate(7.689 1.161)" fill="#989898"/>
      <path id="Tracé_9" data-name="Tracé 9" d="M73.149,60.356H71.687a.643.643,0,0,1-.642-.642V58.252a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,73.149,60.356ZM71.687,58.1a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153V58.252a.153.153,0,0,0-.153-.153Z" transform="translate(-69.473 -52.582)" fill="#989898"/>
      <path id="Tracé_10" data-name="Tracé 10" d="M112.408,60.356h-1.462a.643.643,0,0,1-.642-.642V58.252a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,112.408,60.356ZM110.946,58.1a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153V58.252a.153.153,0,0,0-.153-.153Z" transform="translate(-105.306 -52.582)" fill="#989898"/>
      <path id="Tracé_11" data-name="Tracé 11" d="M151.667,60.356H150.2a.643.643,0,0,1-.642-.642V58.252a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,151.667,60.356ZM150.2,58.1a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153V58.252a.153.153,0,0,0-.153-.153Z" transform="translate(-141.138 -52.582)" fill="#989898"/>
      <path id="Tracé_12" data-name="Tracé 12" d="M73.149,105.169H71.687a.643.643,0,0,1-.642-.642v-1.462a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,73.149,105.169Zm-1.463-2.258a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153v-1.462a.153.153,0,0,0-.153-.153Z" transform="translate(-69.473 -93.483)" fill="#989898"/>
      <path id="Tracé_13" data-name="Tracé 13" d="M112.408,105.169h-1.462a.643.643,0,0,1-.642-.642v-1.462a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,112.408,105.169Zm-1.462-2.258a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153v-1.462a.153.153,0,0,0-.153-.153Z" transform="translate(-105.306 -93.483)" fill="#989898"/>
      <path id="Tracé_14" data-name="Tracé 14" d="M151.667,105.169H150.2a.643.643,0,0,1-.642-.642v-1.462a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,151.667,105.169Zm-1.463-2.258a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153v-1.462a.153.153,0,0,0-.153-.153Z" transform="translate(-141.138 -93.483)" fill="#989898"/>
      <path id="Tracé_15" data-name="Tracé 15" d="M73.149,149.984H71.687a.643.643,0,0,1-.642-.642V147.88a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,73.149,149.984Zm-1.463-2.258a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153V147.88a.153.153,0,0,0-.153-.153Z" transform="translate(-69.473 -134.388)" fill="#989898"/>
      <path id="Tracé_16" data-name="Tracé 16" d="M112.408,149.984h-1.462a.643.643,0,0,1-.642-.642V147.88a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,112.408,149.984Zm-1.462-2.258a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153V147.88a.153.153,0,0,0-.153-.153Z" transform="translate(-105.306 -134.388)" fill="#989898"/>
      <path id="Tracé_17" data-name="Tracé 17" d="M151.667,149.984H150.2a.643.643,0,0,1-.642-.642V147.88a.643.643,0,0,1,.642-.642h1.462a.643.643,0,0,1,.642.642v1.462A.643.643,0,0,1,151.667,149.984Zm-1.463-2.258a.153.153,0,0,0-.153.153v1.462a.153.153,0,0,0,.153.153h1.462a.153.153,0,0,0,.153-.153V147.88a.153.153,0,0,0-.153-.153Z" transform="translate(-141.138 -134.388)" fill="#989898"/>
    </g>
  </g>
</svg>

                    <span>SmartPhones</span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                    <ul className='second-child'>
                        <li><a href='#'>OPPO</a></li>
                        <li><a href='#'>HUAWEI</a></li>
                        <li><a href='#'>Accessoires Smartphones</a></li>
                    </ul>
                </li>
                <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="17.273" height="15.046" viewBox="0 0 17.273 15.046">
  <g id="pack" transform="translate(-56.349 -62.41)">
    <path id="Tracé_18" data-name="Tracé 18" d="M64.947,77.456H56.923c-.54,0-.573-.034-.573-.568q0-5.362.005-10.725a1.449,1.449,0,0,1,.139-.586c.429-.919.884-1.828,1.307-2.749a.616.616,0,0,1,.646-.418q6.54.016,13.078,0a.594.594,0,0,1,.628.383c.436.944.9,1.876,1.341,2.818a1.352,1.352,0,0,1,.122.551q.011,5.381.005,10.763c0,.48-.048.528-.535.528Q69.019,77.459,64.947,77.456ZM73,66.264H66.85V66.7c0,.8,0,1.593,0,2.391,0,.5-.044.542-.558.543-.681,0-1.362,0-2.043,0-1.276-.007-1.124.154-1.131-1.128,0-.743,0-1.484,0-2.246H56.979V76.84H73.006C73,73.309,73,69.8,73,66.264Zm-6.181-.648h5.985c-.051-.131-.086-.236-.132-.335-.307-.649-.628-1.293-.921-1.948a.46.46,0,0,0-.5-.309c-1.438.012-2.878.005-4.316.007-.111,0-.224.012-.376.021C66.65,63.908,66.733,64.735,66.823,65.616Zm-9.682,0h6q.135-1.286.263-2.516c-.062-.035-.083-.058-.106-.058-1.579,0-3.158-.009-4.736,0a.356.356,0,0,0-.261.168C57.916,63.984,57.545,64.771,57.141,65.614Zm6.594,3.4h2.5V66.262h-2.5Zm2.488-3.4c-.085-.8-.159-1.581-.256-2.356a.334.334,0,0,0-.236-.215c-.5-.019-1-.018-1.5,0a.3.3,0,0,0-.221.191c-.1.785-.173,1.574-.258,2.382Z" fill="#989898"/>
    <path id="Tracé_19" data-name="Tracé 19" d="M131.168,99.939c.268,0,.536,0,.8,0,.219,0,.42.074.379.309a.483.483,0,0,1-.36.282,14.912,14.912,0,0,1-1.685,0,.485.485,0,0,1-.362-.282c-.041-.235.161-.311.379-.309C130.607,99.943,130.888,99.939,131.168,99.939Z" transform="translate(-60.606 -30.907)" fill="#989898"/>
    <path id="Tracé_20" data-name="Tracé 20" d="M131.116,91.655a5.675,5.675,0,0,1-.884-.014c-.136-.021-.249-.192-.372-.295.127-.1.249-.27.381-.277a15.041,15.041,0,0,1,1.692,0c.129.007.247.187.371.286-.127.1-.242.261-.381.286A4.545,4.545,0,0,1,131.116,91.655Z" transform="translate(-60.54 -23.583)" fill="#989898"/>
  </g>
</svg>

                    <span>Packs Diar Dzair</span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                    <ul className='second-child'>
                        <li><a href='#'>Packs Samsung</a></li>
                        <li><a href='#'>Packs Beko</a></li>
                    </ul>
                </li> */}
        </ul>
      </section>
    </>
  )
}

export default Categories