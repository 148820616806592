import { useEffect, useState, useContext } from "react"
import axios from "axios";
import Skeleton from 'react-loading-skeleton'
import BankContext from '../../Context/bank'
import 'react-loading-skeleton/dist/skeleton.css'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Slide = () => {

  const [Slides, setSlides] = useState([]);
  const [AllSlides, setAllSlides] = useState([])
  const [MediaWidth, setMediaWidth] = useState('Desktop')

  const [Loading, setLoading] = useState(false)

  const { bankActive, setBankActive } = useContext(BankContext);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  console.log(bankActive);

  useEffect(() => {
    setLoading(true)
    axios.get('https://diardzair.com.dz/ws/homepage/slides?type=slides').then(response => {
      console.log(response.data[0][0]['imageDesk']['webPath'])
      const data = response.data[0]?.reverse();
      setAllSlides(response.data[0])
      // if (bankActive === 'bna') {
      const result = data.filter(data => data.bank == bankActive);
      setSlides(result)
      setLoading(false)

      // }else{

      //   setSlides(data)
      // }
      console.log(Slides)
    });
    console.log(window.innerWidth)
    if (window.innerWidth < 880) {
      setMediaWidth('Mobile')
    }
    console.log(MediaWidth)
  }, []);

  useEffect(() => {

    const result = AllSlides.filter(slide => slide.bank == bankActive);
    setSlides(result)

  }, [bankActive,Loading]);


  return (
    <>

      {
        Slides.length === 0 && <Skeleton className="slide-skeleton" />
      }


      {Slides.length > 0 && <section className="slider">

        <Swiper
          // install Swiper modules
          modules={[Navigation, Autoplay,Pagination]}
          loop={true}
          // spaceBetween={20}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          // breakpoints={{
          //   // when window width is >= 640px
          //   640: {
          //     slidesPerView: 1,
          //   },
          //   // when window width is >= 768px
          //   768: {
          //     slidesPerView: 2,
          //   },
          //   992: {
          //     slidesPerView: 3,
          //     spaceBetween: 8
          //   },
          //   1200: {
          //     slidesPerView: 4,
          //     spaceBetween: 20,

          //   },

          // }}
          navigation
          speed={1000}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log('slide change')}
        >
          <div id="diar-dzair-slide" className="carousel slide" data-bs-ride="carousel">
            {/* <div className="carousel-indicators">
      {
     Slides.length > 0 && Slides.map((item,i) => (

       <button type="button" data-bs-target="#diar-dzair-slide" data-bs-slide-to={i} className={i ===0 ? 'active' : ''} aria-current="true" aria-label="Slide 1" />
      //  <button type="button" data-bs-target="#diar-dzair-slide" data-bs-slide-to={1} aria-label="Slide 2" />
      //  <button type="button" data-bs-target="#diar-dzair-slide" data-bs-slide-to={2} aria-label="Slide 3" />

     )) 

      }
    </div> */}
            <div className="carousel-inner">
              {
                Slides.length > 0 && Slides.map((item, i) => (
                  // <ol key = { item.id } >
                  //     User_Name: { item.username }, 
                  //     Full_Name: { item.name }, 
                  //     User_Email: { item.email } 
                  //     </ol>
                  <SwiperSlide>
                    <div>
                      <a href={`/${item['link']}`}>
                        <img src={item['imageDesk']['webPath'] !== undefined ? `https://www.diardzair.com.dz/${windowSize.innerWidth > 880 ? item['imageDesk']['webPath'] : item['imageMob']['webPath']}` : "/img/slide2.jpg"} className="d-block" alt="img/slide2.jpg" />
                      </a>
                    </div>
                  </SwiperSlide>
                ))
              }
              {/* <div className="carousel-inner">
      <div className="carousel-item active">
      <img src={Slides[0]['imageA'] !== undefined ? `https://www.diardzair.com.dz/${Slides[0]['imageA']}` : "/img/slide2.jpg"} className="d-block" alt="img/slide2.jpg" />
      </div>
      <div className="carousel-item">
      <img src={Slides[0]['imageB'] !== undefined ? `https://www.diardzair.com.dz/${Slides[0]['imageB']}` : "/img/slide2.jpg"} className="d-block" alt="img/slide2.jpg" />
      </div>
      <div className="carousel-item">
      <img src={Slides[0]['imageC'] !== undefined ? `https://www.diardzair.com.dz/${Slides[0]['imageC']}` : "/img/slide2.jpg"} className="d-block" alt="img/slide2.jpg" />
      </div>
    </div> */}
            </div>
            {/* {
     Slides?.length > 1 && Slides?.map((item,i) => ( 
      <>
    <button className="carousel-control-prev" type="button" data-bs-target="#diar-dzair-slide" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#diar-dzair-slide" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
    </>
    ))
  } */}
          </div>
        </Swiper>
      </section>}
    </>
  )
}

export default Slide