import React from 'react'
import Description from '../components/DarIdjar/Description'
import Dossier from '../components/DarIdjar/Dossier'
import Engagements from '../components/DarIdjar/Engagements'
import Pub from '../components/DarIdjar/Pub'
import Slide from '../components/DarIdjar/Slide'
import Footer from '../components/Global/Footer'

const Daridjar = () => {
  return (
     <div className='dar-idjar-page'>
      <Slide />
      <Description />
      <Dossier />
      <Engagements />
      <Pub />
      <Footer />
     </div>
  )
}

export default Daridjar