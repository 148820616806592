import { useEffect, useState ,useContext} from 'react'
import LangContext from '../../Context/lang';


const Charges = ({ Step, setStep, StepTitel, setFormdata }) => {

  const { Lang, Global, dartaksitsim, Account, RecapitulatifSimTaksit, dari3marsim, Panier, infoperssonnelle,adresse,situationprofessionnelle,chargesetpatrimoine } = useContext(LangContext);


    const [créditempruntimmobilier, setcréditempruntimmobilier] = useState('non')
    const [Mensualitéducréditimmobilier, setMensualitéducréditimmobilier] = useState('')
    const [Organismeprêteurimmobilier, setOrganismeprêteurimmobilier] = useState('')
    const [Datedefinducréditimmobilier, setDatedefinducréditimmobilier] = useState('')


    const [créditvoiture, setcréditvoiture] = useState('non')
    const [Mensualitéducréditvoiture, setMensualitéducréditvoiture] = useState('')
    const [Organismeprêteurdecréditvoiture, setOrganismeprêteurdecréditvoiture] = useState('')
    const [Datedefinducréditvoiture, setDatedefinducréditvoiture] = useState('')


    const [Autrecrédit, setAutrecrédit] = useState('non')
    const [Mensualitéduautrecrédit, setMensualitéduautrecrédit] = useState('')
    const [Organismeprêteurautrecrédit, setOrganismeprêteurautrecrédit] = useState('')
    const [Datedefinduautrecrédit, setDatedefinduautrecrédit] = useState('')

    const [Loyerpayer, setLoyerpayer] = useState('non')
    const [Loyer, setLoyer] = useState('')

    const [SéjourUSA, setSéjourUSA] = useState('non')
    const [GreenCard, setGreenCard] = useState('non')
    const [Compteépargne, setCompteépargne] = useState('non')
    const [CompteépargneText, setCompteépargneText] = useState('')
    const [immeublesoumeubles, setimmeublesoumeubles] = useState('')

    const [Mensualité, setMensualité] = useState('')

    const [FormValidate, setFormValidate] = useState(true)
    const [Showerr, setShowerr] = useState(false)
    // const [Datedefinducréditimmobilier, setDatedefinducréditimmobilier] = useState('')


    useEffect(() => {

        console.log(FormValidate);
        Validation();

    }, [créditempruntimmobilier, Mensualitéducréditimmobilier, Organismeprêteurimmobilier, Datedefinducréditimmobilier, créditvoiture, Loyer, Organismeprêteurdecréditvoiture, Datedefinducréditvoiture, Autrecrédit, Mensualitéduautrecrédit, Organismeprêteurautrecrédit, Datedefinduautrecrédit, Loyerpayer, GreenCard, Compteépargne, CompteépargneText, immeublesoumeubles, SéjourUSA])

    console.log(créditempruntimmobilier, Mensualitéducréditimmobilier, Organismeprêteurimmobilier, Datedefinducréditimmobilier, créditvoiture, Loyer, Organismeprêteurdecréditvoiture, Datedefinducréditvoiture, Autrecrédit, Mensualitéduautrecrédit, Organismeprêteurautrecrédit, Datedefinduautrecrédit, Loyerpayer, GreenCard, Compteépargne, immeublesoumeubles);



    function onlyLetters(str) {
        return /^[a-zA-Z]+$/.test(str);
    }
    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }
    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        // Test the string against the regular expression
        return regex.test(string)
    }

    function DateFormat(date) {

        var d = new Date(date);
        var datestring = d.getFullYear()  + "/" + d.getMonth().toString().padStart(2 , '0') + "/" + d.getDate().toString().padStart(2 , '0');
    
        return datestring;
        
      }

    function Validation() {

        setFormValidate(true)

        if ((créditempruntimmobilier !== 'non' && créditempruntimmobilier !== 'oui') || (créditvoiture !== 'non' && créditvoiture !== 'oui') || (Autrecrédit !== 'non' && Autrecrédit !== 'oui') || (Loyerpayer !== 'non' && Loyerpayer !== 'oui') || (SéjourUSA !== 'non' && SéjourUSA !== 'oui') || (GreenCard !== 'non' && GreenCard !== 'oui') || (Compteépargne !== 'non' && Compteépargne !== 'oui')) {
            setFormValidate(false)
        }

        if (créditempruntimmobilier == 'oui') {

            if (!onlyNumbers(Mensualitéducréditimmobilier)) {
                setFormValidate(false)
            }
            if (Organismeprêteurimmobilier == '') {
                setFormValidate(false)
            }
            if (Datedefinducréditimmobilier == '') {
                setFormValidate(false)
                
            }

        }

        if (créditvoiture == 'oui') {

            if (!onlyNumbers(Mensualitéducréditvoiture)) {
                setFormValidate(false)
            }
            if (Organismeprêteurdecréditvoiture == '') {
                setFormValidate(false)
            }
            if (Datedefinducréditvoiture == '') {
                setFormValidate(false)
                
            }

        }

        if (Autrecrédit == 'oui') {

            if (!onlyNumbers(Mensualitéduautrecrédit)) {
                setFormValidate(false)
            }
            if (Organismeprêteurautrecrédit == '') {
                setFormValidate(false)
            }
            if (Datedefinduautrecrédit == '') {
                setFormValidate(false)
            }

        }

        if (Loyerpayer == 'oui') {

            if (!onlyNumbers(Loyer)) {
                setFormValidate(false)
            }

        }

        if (Compteépargne == 'oui') {

            if (CompteépargneText.trim() == '') {
                setFormValidate(false)
            }

        }



        // if (Secteurdactivité !== "p" || Secteurdactivité !== "r" || Secteurdactivité !== "i" || Secteurdactivité !== "v") {
        //       setFormValidate(false)
        // }
        // if (Typedecontrat !== "CDD" || Typedecontrat !== "CDI" || Typedecontrat !== "INTERIM" || Typedecontrat !== "Autre") {
        //       setFormValidate(false)
        // }
        // if (!onlyLetters(Nomdelemployeur)) {
        //       setFormValidate(false)       
        // }
        // if (!onlyNumbers(Tel) || Tel.length > 11 ) {
        //       setFormValidate(false)    
        // }
        // if (!onlyNumbers(Salaire)) {
        //       setFormValidate(false)    
        // }
        // if (!onlyNumbers(CompteCCP)) {
        //       setFormValidate(false)
        // }
        // if (!onlyNumbers(CCPClé)) {
        //       setFormValidate(false)
        // }

    }

    useEffect(() => {

        if (Showerr) {
          const matches = document.querySelectorAll(".alert-form");
    
          for (let index = 0; index < matches.length; index++) {
    
            if (matches[index].textContent.trim() !== '') {
    
              let position = matches[index].getBoundingClientRect();
    
    
              window.scrollTo(position.left, position.top + window.scrollY - 120);
              return
            }
            // if (matches[index].textContent.trim() !== '') {
    
            //     matches[index].scrollIntoView({behavior: "smooth"});
            //     return
            // }              
          }
        }
    
    
      }, [Showerr])

    function GoNext() {
        setShowerr(true)

        if (!FormValidate) {
            const matches = document.querySelectorAll(".alert-form");

      for (let index = 0; index < matches.length; index++) {

        if (matches[index].textContent.trim() !== '') {

          let position = matches[index].getBoundingClientRect();


          window.scrollTo(position.left, position.top + window.scrollY - 120);
          return
        }
        // if (matches[index].textContent.trim() !== '') {

        //     matches[index].scrollIntoView({behavior: "smooth"});
        //     return
        // }              
      }
            return
        }

        setFormdata((prev) => [
            {
                ...prev[0], ...{
                    "rimmobilier": créditempruntimmobilier,
                    "mimmobilier": Mensualitéducréditimmobilier,
                    "oimmobilier": Organismeprêteurimmobilier,
                    "dimmobilier": DateFormat(Datedefinducréditimmobilier),
                    "rvoiture": créditvoiture,
                    "mvoiture": Mensualitéducréditvoiture,
                    "ovoiture": Organismeprêteurdecréditvoiture,
                    "dvoiture": DateFormat(Datedefinducréditvoiture),
                    "rautrec": Autrecrédit,
                    "mautrec": Mensualitéduautrecrédit,
                    "oautrec": Organismeprêteurautrecrédit,
                    "dautrec": DateFormat(Datedefinduautrecrédit),
                    "rloyer": Loyerpayer,
                    "loyer": Loyer,
                    "mtLoyer": "",
                    "rsejourUsa": SéjourUSA,
                    "rgreenCard": GreenCard,
                    "rcomptepargne": Compteépargne,
                    "rgreenCard": GreenCard,
                    "mcompteepargne": CompteépargneText,
                    "biens": immeublesoumeubles.trim()
                }
            }
        ])

        setStep(5)
        window.scrollTo(0, 0);
    }

    function back() {

        setStep(3)
        window.scrollTo(0, 0);
      
      }

    return (
        <div className={`form-info form-3 ${Step !== 4 ? 'd-none' : ''}`}>
            <div className='text-center info-step-titel mb-3'>{StepTitel[Step - 1]}</div>
            <form className="row g-3 m-auto justify-content-center">

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q1'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio1" checked={créditempruntimmobilier == 'oui'} onChange={(e) => setcréditempruntimmobilier(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio1">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" checked={créditempruntimmobilier == 'non'} onChange={(e) => setcréditempruntimmobilier(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio2">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>

                {
                    créditempruntimmobilier === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Mensualité du crédit immobilier</label>
                            <input type="text" className="form-control" onChange={(e) => setMensualitéducréditimmobilier(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéducréditimmobilier} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Mensualitéducréditimmobilier == "" ? 'Veuillez renseigner votre Mensualité du crédit immobilier' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Organisme prêteur immobilier</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurimmobilier(e.target.value)}>
                                <option disabled selected="selected">Choisissez</option>
                                <option value="AL-SALAM  ">Al salam Bank Algeria</option>
                                <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                <option value="BDL">BDL (Banque de Développement Local)</option>
                                <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                <option value="CITIBANK">CITIBANK</option>
                                <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                <option value="NATIXIS">Natixis ALGERIE</option>
                                <option value="SGA"> Société Générale Algérie</option>
                                <option value="BNP"> BNP Paribas Al-djazair</option>
                                <option value="TRUST-BANK"> TRUST BANK Algeria</option>
                                <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                <option value="AGB"> AGB (Gulf Bank Algérie)</option>
                                <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                <option value="FRANSABANK">Fransabank Al-Djazair</option>
                                <option value="HSBC "> HSBC Algeria</option>
                            </select>
                            <div className='alert-form'>{Showerr && (Organismeprêteurimmobilier == "" ? 'Veuillez renseigner votre Organisme prêteur immobilier' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label htmlFor="inputEmail4" className="form-label">Date de fin du crédit immobilier</label>
                            <input type="date" max={new Date().toISOString().slice(0, 10)}  onChange={(e) => setDatedefinducréditimmobilier(e.target.value)} value={Datedefinducréditimmobilier} className="form-control" id="inputEmail4" />
                            <div className='alert-form'>{Showerr && (Datedefinducréditimmobilier == "" ? 'Veuillez renseigner votre Date de fin du crédit immobilier' : '')}</div>
                        </div>
                    </>
                }




                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q2'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio3" checked={créditvoiture == 'oui'} onChange={(e) => setcréditvoiture(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio3">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio4" checked={créditvoiture == 'non'} onChange={(e) => setcréditvoiture(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio4">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>

                {
                    créditvoiture === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Mensualité du crédit voiture</label>
                            <input type="text" className="form-control" onChange={(e) => setMensualitéducréditvoiture(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéducréditvoiture} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Mensualitéducréditvoiture == "" ? 'Veuillez renseigner votre Mensualité du crédit voiture' : '')}</div>

                        </div>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Organisme prêteur de crédit voiture</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurdecréditvoiture(e.target.value)}>
                                <option disabled selected="selected">Choisissez</option>
                                <option value="AL-SALAM  ">Al salam Bank Algeria</option>
                                <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                <option value="BDL">BDL (Banque de Développement Local)</option>
                                <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                <option value="CITIBANK">CITIBANK</option>
                                <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                <option value="NATIXIS">Natixis ALGERIE</option>
                                <option value="SGA"> Société Générale Algérie</option>
                                <option value="BNP"> BNP Paribas Al-djazair</option>
                                <option value="TRUST-BANK"> TRUST BANK Algeria</option>
                                <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                <option value="AGB"> AGB (Gulf Bank Algérie)</option>
                                <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                <option value="FRANSABANK">Fransabank Al-Djazair</option>
                                <option value="HSBC "> HSBC Algeria</option>
                            </select>
                            <div className='alert-form'>{Showerr && (Organismeprêteurdecréditvoiture == "" ? 'Veuillez renseigner votre Organisme prêteur de crédit voiture' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label htmlFor="inputEmail4" className="form-label">Date de fin du crédit voiture</label>
                            <input type="date" max={new Date().toISOString().slice(0, 10)} onChange={(e) => setDatedefinducréditvoiture(e.target.value)} value={Datedefinducréditvoiture} className="form-control" id="inputEmail4" />
                            <div className='alert-form'>{Showerr && (Datedefinducréditvoiture == "" ? 'Veuillez renseigner votre Organisme prêteur de Date de fin du crédit voiture' : '')}</div>
                        </div>
                    </>
                }

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q3'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio5" checked={Autrecrédit == 'oui'} onChange={(e) => setAutrecrédit(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio5">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio6" checked={Autrecrédit == 'non'} onChange={(e) => setAutrecrédit(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio6">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>


                {
                    Autrecrédit === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Mensualité du autre crédit</label>
                            <input type="text" className="form-control" onChange={(e) => setMensualitéduautrecrédit(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéduautrecrédit} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Mensualitéduautrecrédit == "" ? 'Veuillez renseigner votre Mensualité du autre crédit' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Organisme prêteur autre crédit</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurautrecrédit(e.target.value)}>
                                <option disabled selected="selected">Choisissez</option>
                                <option value="AL-SALAM  ">Al salam Bank Algeria</option>
                                <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                <option value="BDL">BDL (Banque de Développement Local)</option>
                                <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                <option value="CITIBANK">CITIBANK</option>
                                <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                <option value="NATIXIS">Natixis ALGERIE</option>
                                <option value="SGA"> Société Générale Algérie</option>
                                <option value="BNP"> BNP Paribas Al-djazair</option>
                                <option value="TRUST-BANK"> TRUST BANK Algeria</option>
                                <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                <option value="AGB"> AGB (Gulf Bank Algérie)</option>
                                <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                <option value="FRANSABANK">Fransabank Al-Djazair</option>
                                <option value="HSBC "> HSBC Algeria</option>
                            </select>
                            <div className='alert-form'>{Showerr && (Organismeprêteurautrecrédit == "" ? 'Veuillez renseigner votre Organisme prêteur autre crédit' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label htmlFor="inputEmail4" className="form-label">Date de fin du autre crédit</label>
                            <input type="date" max={new Date().toISOString().slice(0, 10)} onChange={(e) => setDatedefinduautrecrédit(e.target.value)} value={Datedefinduautrecrédit} className="form-control" id="inputEmail4" />
                            <div className='alert-form'>{Showerr && (Datedefinduautrecrédit == "" ? 'Veuillez renseigner votre Date de fin du autre crédit' : '')}</div>
                        </div>
                    </>
                }



                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q4'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio7" checked={Loyerpayer == 'oui'} onChange={(e) => setLoyerpayer(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio7">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio8" checked={Loyerpayer == 'non'} onChange={(e) => setLoyerpayer(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio8">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>

                {Loyerpayer === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Loyer</label>
                            <input type="text" className="form-control" onChange={(e) => setLoyer(e.target.value.replace(/[^0-9]+/g, ''))} value={Loyer} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Loyer == "" ? 'Veuillez renseigner votre Loyer' : '')}</div>
                        </div>
                    </>}

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q5'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio9" checked={SéjourUSA == 'oui'} onChange={(e) => setSéjourUSA(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio9">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio10" checked={SéjourUSA == 'non'} onChange={(e) => setSéjourUSA(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio10">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q6'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio11" checked={GreenCard == 'oui'} onChange={(e) => setGreenCard(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio11">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio12" checked={GreenCard == 'non'} onChange={(e) => setGreenCard(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio12">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>{(chargesetpatrimoine['Q7'][Lang])}</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio13" checked={Compteépargne == 'oui'} onChange={(e) => setCompteépargne(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio13">{(chargesetpatrimoine['r1'][Lang])}</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio14" checked={Compteépargne == 'non'} onChange={(e) => setCompteépargne(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio14">{(chargesetpatrimoine['r2'][Lang])}</label>
                    </div>
                </div>
                {Compteépargne === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Compte épargne</label>
                            <input type="text" className="form-control" onChange={(e) => setCompteépargneText(e.target.value.replace(/[^0-9]+/g, ''))} value={CompteépargneText} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (CompteépargneText.trim() == "" ? 'Veuillez renseigner Votre Compte épargne' : '')}</div>
                        
                        </div>
                    </>}

                <div className="col-md-12 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">{(chargesetpatrimoine['Q8'][Lang])}</label>
                    <input type="text" className="form-control" id="inputPassword4" onChange={(e) => setimmeublesoumeubles(e.target.value.replace(/[^0-9]+/g, ''))} value={immeublesoumeubles} />
                    <span className='note-form'>{(chargesetpatrimoine['msg'][Lang])}</span>
                </div>



            </form>

            <div className='row mt-5'>
                <div className="col text-start">
                    <button type="submit" onClick={() => back()} className="next-info-btn">
                    <svg className='btn-re' xmlns="http://www.w3.org/2000/svg" width="44.906" height="33.07" viewBox="0 0 44.906 33.07">
                      <g id="show-more-icon-5" transform="translate(-594.581 -201.566)">
                        <path id="Tracé_254" data-name="Tracé 254" d="M37.627,18.609H3.229C1.029,18.609.021,18,0,16.666s1.008-2.005,3.167-2.005H37.761c-.5-.555-.782-.895-1.1-1.193q-4.982-4.674-9.975-9.327c-.236-.216-.473-.422-.689-.658A1.885,1.885,0,0,1,25.883.655,1.835,1.835,0,0,1,28.7.665q7.728,7.2,15.425,14.438a1.925,1.925,0,0,1,.041,3.064q-7.142,7.126-14.335,14.191a1.83,1.83,0,0,1-2.818.093,1.871,1.871,0,0,1,.134-2.931q4.674-4.643,9.358-9.276c.411-.411.895-.74,1.347-1.111C37.781,18.959,37.709,18.784,37.627,18.609Z" transform="translate(639.487 234.637) rotate(180)" fill="#fffcfc" />
                      </g>
                    </svg>
                        {(dari3marsim['Retour'][Lang])}</button>
                </div>
                <div className="col text-end">
                    <button type="submit" onClick={() => GoNext()} className="next-info-btn">{(dari3marsim['Suivant'][Lang])}
                    <svg className='btn-next' xmlns="http://www.w3.org/2000/svg" width="44.906" height="33.07" viewBox="0 0 44.906 33.07">
                        <g id="show-more-icon-5" transform="translate(639.487 234.637) rotate(180)">
                          <path id="Tracé_254" data-name="Tracé 254" d="M37.627,18.609H3.229C1.029,18.609.021,18,0,16.666s1.008-2.005,3.167-2.005H37.761c-.5-.555-.782-.895-1.1-1.193q-4.982-4.674-9.975-9.327c-.236-.216-.473-.422-.689-.658A1.885,1.885,0,0,1,25.883.655,1.835,1.835,0,0,1,28.7.665q7.728,7.2,15.425,14.438a1.925,1.925,0,0,1,.041,3.064q-7.142,7.126-14.335,14.191a1.83,1.83,0,0,1-2.818.093,1.871,1.871,0,0,1,.134-2.931q4.674-4.643,9.358-9.276c.411-.411.895-.74,1.347-1.111C37.781,18.959,37.709,18.784,37.627,18.609Z" transform="translate(639.487 234.637) rotate(180)" fill="#fffcfc" />
                        </g>
                      </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Charges