import {useContext} from 'react'
import { Link } from "react-router-dom";
import LangContext from '../../Context/lang';


const Darimar = () => {
  const { Lang, setLang, NavMenu, Global,dari3mardef } = useContext(LangContext);

  return (
    <section className="dar-i3mar">
  <div className="container">
    <div className="sectiont-left row">
      <div className="col-12 col-xl-8">
        <h1>Dar I3mar (دار الإعمار)</h1>
        <h2>{(dari3mardef['titre'][Lang])}</h2>
        <p>{(dari3mardef['definition'][Lang])}
        </p>
        <Link to={'/dari3mar'}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={257} height={63} viewBox="0 0 257 63">
          <defs>
            <filter id="Rectangle_174" x={0} y={0} width={257} height={63} filterUnits="userSpaceOnUse">
              <feOffset dy={3} input="SourceAlpha" />
              <feGaussianBlur stdDeviation={3} result="blur" />
              <feFlood floodOpacity="0.161" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_174)">
            <rect id="Rectangle_174-2" data-name="Rectangle 174" width={239} height={45} rx="22.5" transform="translate(9 6)" fill="#fff" />
          </g>
          <text id="_Dossier_à_fournir" data-name=" Dossier à fournir" transform="translate(42 35)" fill="#4cac48" fontSize={20} fontFamily="Poppins-SemiBold, Poppins" fontWeight={600}><tspan x={0} y={0} xmlSpace="preserve"> {(dari3mardef['dossier'][Lang])}</tspan></text>
        </svg>
        </Link>
      </div>
    </div>
  </div>
</section>
  )
}

export default Darimar