import React, { createContext, useState, useEffect } from 'react'

const BankContext = createContext();

export function Provider({ children }) {

  const getBank = localStorage.getItem('bank') !== null ? localStorage.getItem('bank') : 'bna';

  const [bankActive, setBankActive] = useState(getBank);


  useEffect(() => {
    window.addEventListener('storage', storageEventHandler, false);

  }, []);

  function storageEventHandler(e) {
    console.log(e);
    if (e.key === "bank") {
      setBankActive(localStorage.getItem('bank'))
    }
  }


  function ALSALAM(MT, DUREE) {

    var Settlement = [];
    Settlement.round = function (value, precision, mode) {
      // helper variables
      var m, f, isHalf, sgn;
      // making sure precision is integer
      precision |= 0;
      m = Math.pow(10, precision);
      value *= m;
      // sign of the number
      sgn = (value > 0) | -(value < 0);
      isHalf = value % 1 === 0.5 * sgn;
      f = Math.floor(value);
      if (isHalf) {
        switch (mode) {
          // PHP_ROUND_HALF_DOWN = 2
          case 2:
            // rounds .5 toward zero
            value = f + (sgn < 0);
            break;
          // PHP_ROUND_HALF_EVEN = 3
          case 3:
            // rouds .5 towards the next even integer
            value = f + (f % 2 * sgn);
            break;
          // PHP_ROUND_HALF_ODD = 4
          case 4:
            // rounds .5 towards the next odd integer
            value = f + !(f % 2);
            break;
          default:
            // rounds .5 away from zero
            value = f + (sgn > 0);
        }
      }
      return (isHalf ? value : Math.round(value)) / m;
    }
    function simulation_aslambanque(mt, duree) {
      var b1 = mt;
      // P = principal
      var b3 = duree - 1;
      //N = number of payments
      var b7 = 0.107;
      // RA  ols 0.12R
      var b6 = b7 * 1.19;
      //RATE in TTC
      var b4 = b6 / 12;
      //r = rate expressed per repayment period (eg. contract rate/4 for quarterly)
      var b2 = b1 * b4 * 2;
      //I = interest amount due in first payment
      var d1 = b1 + b2;
      var d2 = b4 + 1;
      var d3 = b3 - 1;
      //    =(((D1*(D2^D3)))*B4)/((D2^B3)-1)
      var e2 = d1 * Math.pow(d2, d3) * b4 / (Math.pow(d2, b3) - 1);
      return Settlement.round(e2, 2, 1);
    }

    return simulation_aslambanque(MT, DUREE)

  }

  function BNA(MF, DUREE, BNA) {

    var Settlement = [];
    Settlement.round = function (value, precision, mode) {
      // helper variables
      var m, f, isHalf, sgn;
      // making sure precision is integer
      precision |= 0;
      m = Math.pow(10, precision);
      value *= m;
      // sign of the number
      sgn = (value > 0) | -(value < 0);
      isHalf = value % 1 === 0.5 * sgn;
      f = Math.floor(value);
      if (isHalf) {
        switch (mode) {
          // PHP_ROUND_HALF_DOWN = 2
          case 2:
            // rounds .5 toward zero
            value = f + (sgn < 0);
            break;
          // PHP_ROUND_HALF_EVEN = 3
          case 3:
            // rouds .5 towards the next even integer
            value = f + (f % 2 * sgn);
            break;
          // PHP_ROUND_HALF_ODD = 4
          case 4:
            // rounds .5 towards the next odd integer
            value = f + !(f % 2);
            break;
          default:
            // rounds .5 away from zero
            value = f + (sgn > 0);
        }
      }
      return (isHalf ? value : Math.round(value)) / m;
    }


    function truncNumber(number, places) {
      var strN = number.toString();
      strN = strN.split(".")
      var num = strN[0];
      var frac = strN[1];
      num += places > 0 && frac ? "." + frac.substr(0, places) : "";
      return parseFloat(num);
    }
    function simulation_bnabanque_plus(montantFin, duree, bna) {
      duree = duree - 2;
      var formul1 , formul2 ,tauxMarge,compteur;
      if (bna == 1) {
        formul1 = 8.25 / 12 / 100;
      }
      if (bna == 2) {
        formul1 = 9.25 / 12 / 100;
      }
      formul2 = Math.pow(1 + formul1, duree) / (Math.pow(1 + formul1, duree) - 1);
      var mensualiteHT = montantFin * formul1 * formul2;
      if (bna == 1) {
        tauxMarge = 8.25;
      }
      if (bna == 2) {
        tauxMarge = 9.25;
      }
      var capitrestant = montantFin;
      var mntEchea = mensualiteHT;
      var totalMrg = 0;
      var diff = capitrestant * (tauxMarge * 30 / (360 * 100)) * 2;
      //2 mois
      for (compteur = 1; compteur <= duree; compteur++) {
        var mntMrg_echea = capitrestant * tauxMarge * 30 / (360 * 100);
        var partCap_remb = mntEchea - mntMrg_echea;
        capitrestant = capitrestant - partCap_remb;
        totalMrg = totalMrg + mntMrg_echea;
      }
      totalMrg = totalMrg + diff;
      var mntTVA = totalMrg * 0.19;
      var MntRemHt = montantFin + totalMrg;
      var MntRembTTC = MntRemHt + mntTVA;
      var MensTTC = MntRembTTC / duree;
      // $MntRembTTC=round($MntRembTTC,2,PHP_ROUND_HALF_UP);
      MntRembTTC = truncNumber(MntRembTTC, 2);
      MensTTC = Settlement.round(MensTTC, 2, 1);
      mntTVA = Settlement.round(mntTVA, 2, 1);
      mntTVA = truncNumber(mntTVA, 2);
      //$mntTVA=round($mntTVA,2,PHP_ROUND_HALF_UP);
      totalMrg = Settlement.round(totalMrg, 2, 1);
      mntEchea = Settlement.round(mntEchea, 2, 1);
      MntRemHt = Settlement.round(MntRemHt, 2, 1);
      var a = { 'mntTVA': mntTVA, 'MntRemHt': MntRemHt, 'mntEchea': mntEchea, 'MntRembTTC': MntRembTTC, 'MensTTC': MensTTC, 'totalMrg': totalMrg };
      return a;
    }

    return simulation_bnabanque_plus(MF, DUREE, BNA)

  }

  function GetTaksitPrice(Price) {

    if (bankActive === 'alsalam') {

      return {
        12: Math.round(ALSALAM(Price, 12)),
        24 : Math.round(ALSALAM(Price, 24)),
        36 : Math.round(ALSALAM(Price, 36)),
      }
      
    }else{

      return {
        12: Math.round(BNA(Price, 12 , 2)['MensTTC']),
        24 : Math.round(BNA(Price, 24 , 2)['MensTTC']),
        36 : Math.round(BNA(Price, 36 , 2)['MensTTC']),
      }

    }
    
  }

  // console.log(GetTaksitPrice(69938));

  return <BankContext.Provider value={{ bankActive, setBankActive ,GetTaksitPrice}}>{children}</BankContext.Provider>
}


export default BankContext