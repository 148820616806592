import {useEffect,useState} from 'react'
import IdjarSimulation from './IdjarSimulation'
import Skeleton from 'react-loading-skeleton'


const Slide = () => {
  const [imgLoaded, setimgLoaded] = useState(false)

  return (
    <div className='daridjar-slide'>
      {
        !imgLoaded && <Skeleton className="slide-skeleton" />
      }
      <img onLoad={() => setimgLoaded(true)} className={!imgLoaded ? 'd-none' : 'img-fluid'} src='/img/daridjar.png' />
      <IdjarSimulation />
      </div>
  )
}

export default Slide