import {useContext} from 'react'
import Engagements from '../components/DarIdjar/Engagements'
import Footer from '../components/Global/Footer'
import LangContext from '../Context/lang';



const DaridjarDossier = () => {

  const { Lang, Global,idjardossier } = useContext(LangContext);

    return (
        <div className='dar-idjar-page'>
            <div className='daridjar-head'><img className='img-fluid' src='/img/idjarlogo.png' /></div>

            <div className='container mt-5' dir={Lang === 'ar' && 'rtl'}>
                <div className='text-center'>
                <div className='daridjar-dossier-title'>{( idjardossier['titre1'][Lang] )}</div>
                <div className='dar-idjar-dossier-sub'>{( idjardossier['titre2'][Lang] )}</div>
                {/* <div>Tout document justifiant la propriété du bien (acte enregistré et publié, livret foncier…)</div> */}
                </div>
                <main className='mt-2'>
                    <ol className="gradient-list">
                    
                    {idjardossier['list1']?.length > 0 && idjardossier['list1']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                    </ol>
                </main>
                <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center'>{( idjardossier['titre3'][Lang] )}</div>
                <main className='mb-5'>
                    <ol className="gradient-list">
                    {idjardossier['list2']?.length > 0 && idjardossier['list2']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                    </ol>
                </main>

            </div>
            <Engagements />
            <div className='mb-5 pb-5'></div>
            <Footer />
        </div>
    )
}

export default DaridjarDossier