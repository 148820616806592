import { useEffect, useState, useContext } from 'react'
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import Skeleton from 'react-loading-skeleton'

import axios from 'axios';
import LangContext from '../../Context/lang';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1400 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1400, min: 991 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 991, min: 767 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

const DarTaksitCat = () => {

  const [DarTaksitCat, setDarTaksitCat] = useState([])
  const { Lang } = useContext(LangContext);

  const [Arry, setArry] = useState([1, 2, 3, 4])



  useEffect(() => {

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/dartaksit-categories`);
        // console.log(response[0]);
        setDarTaksitCat(response[0]);
        // console.log(DarTaksitCat)

      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

  }, []);


  return (
    <section className="dar-taksit taksit-cat-slider  pb-5  text-center">
      <div className="container">
        <div className="title-section">{(Lang === 'ar' ? 'دار التقسيط' : 'Dar Taksit')} </div>
        <div className="row">
          {/* <Carousel autoPlay={true} draggable={false} autoPlaySpeed={1000} responsive={responsive}> */}

          <Swiper
            // install Swiper modules
            modules={[Navigation, Autoplay]}
            loop={true}
            // spaceBetween={20}
            // slidesPerView={6}
            breakpoints={{
              // when window width is >= 640px
              0: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 2,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 8
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 20,

              },

            }}
            navigation
            // autoplay={{
            //   delay: 1000,
            //   disableOnInteraction: false,
            // }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >

            {DarTaksitCat.length > 0 ? DarTaksitCat.map((item, i) => (

              <SwiperSlide>


                <a href={`/search/?page=1&parent=${item.unicnom}`}>

                  <div className="col"><img className="img-fluid" src={`https://www.diardzair.com.dz/${item.imgSection}`} /></div>
                </a>
              </SwiperSlide>
            ))
            :
              Arry.map((item, i) => (
              <SwiperSlide>

                <div className="col">
                <div className="card-product-dartaksit">

                  <Skeleton height={445} width={318} style={{ minHeight: '400px' }} borderRadius={20} className="slide-skeleton" /></div>
                </div>
              </SwiperSlide>

              ))
            }

          </Swiper>
          {/* </Carousel> */}
        </div>
      </div>
    </section>
  )
}

export default DarTaksitCat