import {useContext} from 'react'
import Engagements from '../components/DarIdjar/Engagements'
import Footer from '../components/Global/Footer'
import LangContext from '../Context/lang';


const DaridjarDossier = () => {
  const { Lang, Global,dari3mardossier } = useContext(LangContext);

    return (
        <div className='dar-i3mar-page'>
            <div className='dari3mar-head'><img className='img-fluid' src='/img/i3marlogo.png' /></div>

            <div className='container mt-5' dir={Lang === 'ar' && 'rtl'}>
                <div className='text-center'>
                <div className='daridjar-dossier-title'>{( dari3mardossier['titre1'][Lang] )}</div>
                <div className='dar-idjar-dossier-sub'>{( dari3mardossier['titre2'][Lang] )}</div>
                
                </div>
                <main className='mt-3'>
                    <ol className="gradient-list">
                    {dari3mardossier['list1']?.length > 0 && dari3mardossier['list1']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                        
                    </ol>
                </main>
                <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center'>{( dari3mardossier['titre3'][Lang] )}</div>
                <div className='text-center'>{( dari3mardossier['sous-titre'][Lang] )}</div>
                <main className='mb-5'>
                    <ol className="gradient-list">
                    {dari3mardossier['list2']?.length > 0 && dari3mardossier['list2']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                    </ol>
                </main>

                <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center'>{( dari3mardossier['titre4'][Lang] )}</div>
                <main className='mb-5'>
                    <ol className="gradient-list">
                    {dari3mardossier['list3']?.length > 0 && dari3mardossier['list3']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                       
                    </ol>
                </main>

                <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center'>{( dari3mardossier['titre5'][Lang] )}</div>
                <main className='mb-5'>
                    <ol className="gradient-list">
                    {dari3mardossier['list4']?.length > 0 && dari3mardossier['list4']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                    </ol>
                </main>

                <div className='dar-idjar-dossier-sub mt-5 pt-5 text-center'>{( dari3mardossier['titre6'][Lang] )}</div>
                <main className='mb-5'>
                    <ol className="gradient-list">
                    {dari3mardossier['list5']?.length > 0 && dari3mardossier['list5']?.map((item,i) => (
                        <li>{item[Lang]}.</li>
                        ))
                    }
                    </ol>
                </main>

            </div>
            {/* <Engagements /> */}
            <div className='mb-5 pb-5'></div>
            <Footer />
        </div>
    )
}

export default DaridjarDossier