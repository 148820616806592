import {useContext} from 'react'
import { Link } from "react-router-dom";
import LangContext from '../../Context/lang';



const Daridjar = () => {

  const { Lang, setLang, NavMenu, Global,daridjardef } = useContext(LangContext);

  return (
    <section className="dar-idjar">
    <div className="container">
        <div className="sectiont-left row">
        <div className="col-12 col-xl-8">
            <h1>Dar Idjar (دار الإيجار)</h1>
            <h2>{(daridjardef['titre'][Lang])}</h2>
            <p>{(daridjardef['definition'][Lang])}</p>
            <Link to={'/daridjar'}>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={257} height={63} viewBox="0 0 257 63">
            <defs>
                <filter id="Rectangle_174" x={0} y={0} width={257} height={63} filterUnits="userSpaceOnUse">
                <feOffset dy={3} input="SourceAlpha" />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_174)">
                <rect id="Rectangle_174-2" data-name="Rectangle 174" width={239} height={45} rx="22.5" transform="translate(9 6)" fill="#fff" />
            </g>
            <text id="_Dossier_à_fournir" data-name=" Dossier à fournir" transform="translate(42 35)" fill="#4cac48" fontSize={20} fontFamily="Poppins-SemiBold, Poppins" fontWeight={600}><tspan x={0} y={0} xmlSpace="preserve"> {(daridjardef['dossier'][Lang])}</tspan></text>
            </svg>
            </Link>
        </div>
        </div>
    </div>
    </section>
  )
}

export default Daridjar