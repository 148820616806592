import {useEffect,useState} from 'react';
import axios from "axios";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1300 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};




const Brands = () => {

  const [Mark, setMark] = useState([]);

  useEffect(() => {
    axios.get('https://diardzair.com.dz/ws/homepage/slides?type=marque').then(response => {
          console.log(response.data[0])
          setMark(response.data[0])
          console.log(Mark)
        });      
  }, []);


  useEffect(() => {

    const GetSousCategories = async () =>{
        try {
          const {data: response} = await axios.get(`https://jsonplaceholder.typicode.com/todos/1`);
          console.log(response);
         
        } catch (error) {
          console.error(error.message);
        }
    }
  
    GetSousCategories();
  
   
   
  
  }, [])


  return (
    <section className="brands pt-3 pb-3 text-center">
        <div className="container">
        <div className="row justify-content-center align-items-center">
        {/* <Carousel autoPlay={true} draggable={false} autoPlaySpeed={1000} responsive={responsive}> */}

        <Swiper
            // install Swiper modules
            modules={[Navigation, Autoplay]}
            loop={true}
            // spaceBetween={20}
            // slidesPerView={6}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 8
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 20,

              },

            }}
            navigation
            // autoplay={{
            //   delay: 1000,
            //   disableOnInteraction: false,
            // }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >
          
          {
            Mark.length > 0 && Mark.map((item,i) => (
              <SwiperSlide>
              <div className="col"><Link to={"/search/?page=1&marque="+item.title} ><img className="img-fluid" loading="lazy" src={item['imageDesk']['webPath'] !== undefined ? `https://www.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/brandt.png"}  /></Link></div>
            </SwiperSlide>
            ))
          }
            {/* <div className="col"><img className="img-fluid" src="img/midea.png" /></div>
            <div className="col"><img className="img-fluid" src="img/beko.png" /></div>
            <div className="col"><img className="img-fluid" src="img/samsung.png" /></div>
            <div className="col"><img className="img-fluid" src="img/brandt.png" /></div>
            <div className="col"><img className="img-fluid" src="img/midea.png" /></div>
            <div className="col"><img className="img-fluid" src="img/beko.png" /></div>
            <div className="col"><img className="img-fluid" src="img/samsung.png" /></div> */}

            </Swiper>
        {/* </Carousel> */}
        </div>
        </div>
    </section>
  )
}

export default Brands