import React from 'react'

const Etap = () => {
  return (
    <div className="container mt-5 pt-5"> 
    <div className='etap text-center'>
        <div className="etap-title">Lancez votre boutique</div>
        <div className="etap-sub">comment créer  une boutique en ligne en 3 étapes faciles ?</div>
    </div>

    <div className="row mt-5 pt-5">
        <div className='col-md-4 mt-5'>
            <div className="etap-item">
                <img src="./img/etap-1.png" />
                <div className="etap-item-title">Etap 1</div>
                <div className="etap-item-sub mt-3">Inscrivez vous en quelque clics </div>
                <p className="mt-3">Après avoir pris connaissance de nos conditions générales  de mise a disposition , complétez notre d'inscription clique ici !! </p>
            </div>
        </div>
        <div className='col-md-4 mt-5'>
            <div className="etap-item">
                <img src="./img/etap-2.png" />
                <div className="etap-item-title">Etap 2</div>
                <div className="etap-item-sub mt-3">Devenez un expert du e-commerce</div>
                <p className="mt-3"> Un guide complet sur la création de boutique vous aide a progresser a fin d'obtenir une boutique parfaite.</p>
            </div>
        </div>
        <div className='col-md-4 mt-5'>
            <div className="etap-item">
                <img src="./img/etap-3.png" />
                <div className="etap-item-title">Etap 3</div>
                <div className="etap-item-sub mt-3">Mettez vos produits en ligne et commencez a vendre</div>
                <p className="mt-3"> Intégrez les différents produits que vous souhaitez proposez a la vente sur votre boutique très facilement</p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Etap