import React from 'react'
import Avantage from '../../components/Aide/Avantage'
import Etap from '../../components/Aide/Etap'
import Header from '../../components/Aide/Header'
import Qr from '../../components/Aide/Qr'
// import Footer from '../../components/Home/Footer'

const Aide = () => {
  return (
    <div className='Aide-page'> 
        <Header />
        <Etap />
        <Avantage />
        <Qr />
        {/* <Footer /> */}
    </div>
  )
}

export default Aide