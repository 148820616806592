import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import AuthContext from '../../Context/auth';
import LangContext from '../../Context/lang';




const Login = () => {

  const [userName, setuserName] = useState('')
  const [passLogin, setpassLogin] = useState('')
  const [loading, setloading] = useState(false)
  const [err, seterr] = useState(false)

  const { Auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const { Lang, Global, Account } = useContext(LangContext);



  console.log(userName, passLogin);

  function Login(e) {

    e.preventDefault();
    setloading(true)
    console.log(userName, passLogin);

    axios.post(`https://www.diardzair.com.dz/alogin/rest/login`, { "username": userName, "password": passLogin }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(function (response) {

        if (response.data['email'] && response.data['username'] && response.data['token']) {
          console.log(response.data['email'], response.data['username'], response.data['token']);
          setAuth({ email: response.data['email'], username: response.data['username'], token: response.data['token'] })
          setloading(false)
          navigate('/profil')
          navigate(0)
        }

        if (response.data['errorCode'] == "-1") {
          seterr("l'utilisateur n'existe pas")
        } else if (response.data['errorCode'] == "-2") {
          seterr('Le mot de passe est incorrect')
        }

        setloading(false)
        console.log(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  return (
    <div className='container'>
      {err &&
        <div className="alert alert-danger" role="alert">
          {err}
        </div>}

      {loading && <div className="screen">
        <div className="loader">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>}
      <form className="row g-3 m-auto justify-content-center" onSubmit={Login}>
        <div className="col-md-8">
          <label htmlFor="inputEmail4" className="form-label">{(Account['Nomdutilisateur'][Lang])}</label>
          <input type="text" required onChange={(e) => setuserName(e.target.value)} className="form-control" id="inputEmail4" />
        </div>
        <div className="col-md-8">
          <label htmlFor="inputPassword4" className="form-label">{(Account['MotdePasse'][Lang])}</label>
          <input type="password" required onChange={(e) => setpassLogin(e.target.value)} className="form-control" id="inputPassword4" />
        </div>

        <div className="col-8">
          <div className="form-check">

            <label className="form-check-label" htmlFor="gridCheck">
              {(Account['Motdepasseoublié'][Lang])}
            </label>
          </div>
        </div>
        <div className="col-8">
          <button type="submit" className="login-btn">{(Account['Seconnecter'][Lang])}</button>
        </div>
        
        {/* <div className="col-12 text-center">
          <button type="submit" className="btn-google login-btn-fb">
            <svg xmlns="http://www.w3.org/2000/svg" width="38.25" height="39.027" viewBox="0 0 38.25 39.027">
              <g id="_7123025_logo_google_g_icon" data-name="7123025_logo_google_g_icon" transform="translate(-30.025 -29.098)">
                <path id="Tracé_249" data-name="Tracé 249" d="M94.648,70.754a24.405,24.405,0,0,0-.34-3.954H75.9v7.525H86.443a8.869,8.869,0,0,1-3.911,5.909l6.292,4.889a19.189,19.189,0,0,0,5.824-14.369Z" transform="translate(-26.373 -21.674)" fill="#4280ef" />
                <path id="Tracé_250" data-name="Tracé 250" d="M52.33,99.629a18.667,18.667,0,0,0,12.924-4.719l-6.292-4.846a11.836,11.836,0,0,1-17.6-6.164L34.9,88.874A19.494,19.494,0,0,0,52.33,99.629Z" transform="translate(-2.803 -31.505)" fill="#34a353" />
                <path id="Tracé_251" data-name="Tracé 251" d="M38.559,66.9a11.854,11.854,0,0,1,0-7.482L32.1,54.4a19.539,19.539,0,0,0,0,17.515Z" transform="translate(0 -14.546)" fill="#f6b704" />
                <path id="Tracé_252" data-name="Tracé 252" d="M52.33,36.836a10.646,10.646,0,0,1,7.482,2.933l5.569-5.612A18.779,18.779,0,0,0,52.33,29.1,19.494,19.494,0,0,0,34.9,39.854l6.462,5.016A11.673,11.673,0,0,1,52.33,36.836Z" transform="translate(-2.803 0)" fill="#e54335" />
              </g>
            </svg>

            Continuer avec Google</button>
        </div> */}

      </form>
    </div>
  )
}

export default Login