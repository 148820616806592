

import React from 'react'
import Slide from './Slide'
import Categories from './Categories'
import SlideCategories from './SlideCategories'

function SlideCategoriesContent() {
    return (
        <section className='slide-categories-content background-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 col-lg-3'>
                        <Categories />
                    </div>
                    <div className='col-md-12 col-lg-9'>
                        <Slide />
                    </div>
                    <div className='col-md-12'>
                        <SlideCategories />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SlideCategoriesContent