import axios from 'axios'
import { useEffect, useState } from 'react'

const Info = ({ Step, setStep, StepTitel, setFormdata }) => {

  const [Civilite, setCivilite] = useState('M')
  const [Nom, setNom] = useState('')
  const [Prenom, setPrenom] = useState('')
  const [PrenomPere, setPrenomPere] = useState('')
  const [NomMere, setNomMere] = useState('')
  const [PrenomMere, setPrenomMere] = useState('')
  const date = localStorage.getItem('Year') + '-' + localStorage.getItem('Month') + '-' + localStorage.getItem('day');
  const [Datedenaissance, setDatedenaissance] = useState(date)
  const [Wilaya, setWilaya] = useState('')
  const [Commune, setCommune] = useState('')
  const [CommuneList, setCommuneList] = useState([])
  const [Actedenaissance, setActedenaissance] = useState('')
  const [Typeactnaissnce, setTypeactnaissnce] = useState('')
  const [Situationfamiliale, setSituationfamiliale] = useState('')
  const [NiveauDinstructions, setNiveauDinstructions] = useState('')
  const [Children, setChildren] = useState(0)
  const [Personnes, setPersonnes] = useState(0)
  // const [MaxQuantity, setMaxQuantity] = useState(2)

  const [FormValidate, setFormValidate] = useState(true)
  const [Showerr, setShowerr] = useState(false)
  const [FormChecked, setFormChecked] = useState(false)

  useEffect(() => {


    Validation();

  }, [Civilite, Nom, Prenom, PrenomPere, NomMere, PrenomMere, Wilaya, Commune, Children, Datedenaissance, Actedenaissance, Typeactnaissnce, Situationfamiliale, NiveauDinstructions, Personnes])



  function GoNext() {
    setShowerr(true)

    console.log(FormValidate);

    if (!FormValidate) {
      return
    }

    setFormdata((prev) => [
      {
        ...prev[0], ...{
          "r_civilite": Civilite,
          "nom": Nom,
          "prenom": Prenom,
          "prenompere": PrenomPere,
          "nommere": NomMere,
          "prenommere": PrenomMere,
          "datenaissance": DateFormat(Datedenaissance),
          "wnaissance": Wilaya,
          "communen": Commune,
          "numActNaissance": Actedenaissance,
          "typeActNaissance": Typeactnaissnce,
          "stfamiliale": Situationfamiliale,
          "niveau": NiveauDinstructions,
          "nbenfants": Children.toString(),
          "nbenfantsplus": Personnes.toString(),
        }
      }
    ])

    setStep(2)

    console.log('test', FormValidate);
  }

  function onlyLetters(str) {
    return /^[a-zA-Z]+$/.test(str);
  }
  function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
  }
  function onlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  function DateFormat(date) {

    var d = new Date(date);
    var datestring = d.getFullYear()  + "/" + d.getMonth().toString().padStart(2 , '0') + "/" + d.getDate().toString().padStart(2 , '0');

    return datestring;
    
  }

  function Validation() {

    setFormValidate(true)

    if (Civilite !== "M" && Civilite !== "Mme" && Civilite !== "Mle") {
      setFormValidate(false)
    }
    if (!onlyLetters(Nom)) {
      setFormValidate(false)
    }
    if (!onlyLetters(Prenom)) {
      setFormValidate(false)
    }
    if (!onlyLetters(PrenomPere)) {
      setFormValidate(false)
    }
    if (!onlyLetters(NomMere)) {
      setFormValidate(false)
    }
    if (!onlyLetters(PrenomMere)) {
      setFormValidate(false)
    }
    if (!dateIsValid(new Date(Datedenaissance))) {
      setFormValidate(false)
    }
    if (Wilaya === "") {
      setFormValidate(false)
    }
    if (Commune === "") {
      setFormValidate(false)
    }
    if (!onlyNumbers(Actedenaissance)) {
      setFormValidate(false)
    }
    if (Typeactnaissnce !== "biss" && Typeactnaissnce !== "ter" && Typeactnaissnce !== "autre") {
      setFormValidate(false)
    }
    if (Situationfamiliale !== "Célibataire" && Situationfamiliale !== "Marié" && Situationfamiliale !== "Divorcé" && Situationfamiliale !== "veuf") {
      setFormValidate(false)
    }
    if (NiveauDinstructions !== "1" && NiveauDinstructions !== "2" && NiveauDinstructions !== "3" && NiveauDinstructions !== "4") {
      setFormValidate(false)
    }
    if (!onlyNumbers(Children)) {
      setFormValidate(false)
    }
    if (!onlyNumbers(Personnes)) {
      setFormValidate(false)
    }


  }


  console.log(Civilite, Nom, Prenom, PrenomPere, Wilaya, Commune, Children, Datedenaissance, Actedenaissance, Typeactnaissnce, Situationfamiliale, NiveauDinstructions);

  useEffect(() => {

    const GetCommune = async () => {
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/region/dairas?valeur=${Wilaya}`);
        console.log(response);
        setCommuneList(response['codepostals'])
      } catch (error) {
        console.error(error.message);
      }
    }

    if (Wilaya !== '') {
      GetCommune();
    }



  }, [Wilaya])



  return (
    <div className={`form-info row form-1 ${Step !== 1 ? 'd-none' : ''}`}>
      <div className='text-center info-step-titel mb-3'>{StepTitel[Step - 1]}</div>
      {/* <form className="row g-3 m-auto justify-content-center"> */}
      <div className="col-md-12">
        <div className='mb-2'>Civilité </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Civilite == 'M'} onChange={(e) => setCivilite(e.target.value)} defaultChecked={true} defaultValue="M" />
          <label className="form-check-label" htmlFor="inlineRadio1">M</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Civilite == 'Mme'} onChange={(e) => setCivilite(e.target.value)} defaultValue="Mme" />
          <label className="form-check-label" htmlFor="inlineRadio2">Mme</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" checked={Civilite == 'Mle'} onChange={(e) => setCivilite(e.target.value)} defaultValue="Mle" />
          <label className="form-check-label" htmlFor="inlineRadio3">Mle</label>
        </div>
      </div>

      <div className="col-md-6 mb-5 mt-5">
        <label htmlFor="inputEmail4" className="form-label">Nom</label>
        <input type="text" placeholder='Nom' onChange={(e) => setNom(e.target.value.replace(/[^a-zA-Z]+/g, ''))} value={Nom} className="form-control" id="inputEmail4" />
        <div className='alert-form'>{Showerr && (Nom.trim().length < 2 ? 'Veuillez renseigner votre nom.' : '')}</div>
        <div className='alert-form'>{Showerr && (!onlyLetters(Nom) ? ' Veuillez saisir votre nom sans chiffres ni caractères spéciaux.' : '')}</div>


      </div>
      <div className="col-md-6 mb-5 mt-5">
        <label htmlFor="inputEmail4" className="form-label">Prénom</label>
        <input type="text" placeholder='Prénom' onChange={(e) => setPrenom(e.target.value.replace(/[^a-zA-Z]+/g, ''))} value={Prenom} className="form-control" id="inputEmail4" />
        <div className='alert-form'>{Showerr && (Prenom.trim().length < 2 ? 'Veuillez renseigner votre prenom.' : '')}</div>
        <div className='alert-form'>{Showerr && (!onlyLetters(Prenom) ? 'Veuillez saisir votre prenom sans chiffres ni caractères spéciaux.' : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputEmail4" className="form-label">Prénom du père</label>
        <input type="text" placeholder='Prénom du père' onChange={(e) => setPrenomPere(e.target.value.replace(/[^a-zA-Z]+/g, ''))} value={PrenomPere} className="form-control" id="inputEmail4" />
        <div className='alert-form'>{Showerr && (PrenomPere.trim().length < 2 ? 'Veuillez renseigner votr Prénom du pèr' : '')}</div>
        <div className='alert-form'>{Showerr && (!onlyLetters(PrenomPere) ? 'Veuillez saisir votre prenom sans chiffres ni caractères spéciaux.' : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputEmail4" className="form-label">Nom de la mère</label>
        <input type="text" placeholder='Nom de la mère' onChange={(e) => setNomMere(e.target.value.replace(/[^a-zA-Z]+/g, ''))} value={NomMere} className="form-control" id="inputEmail4" />
        <div className='alert-form'>{Showerr && (NomMere.trim().length < 2 ? 'Veuillez renseigner votre nom du mère' : '')}</div>
        <div className='alert-form'>{Showerr && (!onlyLetters(NomMere) ? 'Veuillez saisir votre prenom sans chiffres ni caractères spéciaux.' : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputEmail4" className="form-label">Prenom de mère</label>
        <input type="text" placeholder='Prenom de mère' onChange={(e) => setPrenomMere(e.target.value.replace(/[^a-zA-Z]+/g, ''))} value={PrenomMere} className="form-control" id="inputEmail4" />
        <div className='alert-form'>{Showerr && (PrenomMere.trim().length < 2 ? 'Veuillez renseigner votre prenom Mere' : '')}</div>
        <div className='alert-form'>{Showerr && (!onlyLetters(PrenomMere) ? 'Veuillez saisir votre prenom sans chiffres ni caractères spéciaux.' : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputEmail4" className="form-label">Date de naissance</label>
        <input type="date" onChange={(e) => setDatedenaissance(e.target.value)} defaultValue={Datedenaissance} className="form-control" id="inputEmail4" />
        <div className='alert-form'>{Showerr && (Datedenaissance == "" ? 'Veuillez renseigner votre Date de naissance' : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputEmail4" className="form-label">Wilaya de naissance</label>
        <select class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
          <option disabled selected="selected">wilaya</option>
          <option value="ADRAR">(01) ADRAR</option>
          <option value="CHLEF">(02) CHLEF</option>
          <option value="LAGHOUAT">(03) LAGHOUAT</option>
          <option value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
          <option value="BATNA">(05) BATNA</option>
          <option value="BEJAIA">(06) BEJAIA</option>
          <option value="BISKRA">(07) BISKRA</option>
          <option value="BECHAR">(08) BECHAR</option>
          <option value="BLIDA">(09) BLIDA</option>
          <option value="BOUIRA">(10) BOUIRA</option>
          <option value="TAMANRASSET">(11) TAMANRASSET</option>
          <option value="TEBESSA">(12) TEBESSA</option>
          <option value="TLEMCEN">(13) TLEMCEN</option>
          <option value="TIARET">(14) TIARET</option>
          <option value="TIZI OUZOU">(15) TIZI-OUZOU</option>
          <option value="ALGER">(16) ALGER</option>
          <option value="DJELFA">(17) DJELFA</option>
          <option value="JIJEL">(18) JIJEL</option>
          <option value="SETIF">(19) SETIF</option>
          <option value="SAIDA">(20) SAIDA</option>
          <option value="SKIKDA">(21) SKIKDA</option>
          <option value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
          <option value="ANNABA">(23) ANNABA</option>
          <option value="GUELMA">(24) GUELMA</option>
          <option value="CONSTANTINE">(25) CONSTANTINE</option>
          <option value="MEDEA">(26) MEDEA</option>
          <option value="MOSTAGANEM">(27) MOSTAGANEM</option>
          <option value="M SILA">(28) M-SILA</option>
          <option value="MASCARA">(29) MASCARA</option>
          <option value="OUARGLA">(30) OUARGLA</option>
          <option value="ORAN">(31) ORAN</option>
          <option value="EL BAYADH">(32) EL-BAYADH</option>
          <option value="ILLIZI">(33) ILLIZI</option>
          <option value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
          <option value="BOUMERDES">(35) BOUMERDES</option>
          <option value="EL TARF">(36) EL-TARF</option>
          <option value="TINDOUF">(37) TINDOUF</option>
          <option value="TISSEMSILT">(38) TISSEMSILT</option>
          <option value="EL OUED">(39) EL-OUED</option>
          <option value="KHENCHELA">(40) KHENCHELA</option>
          <option value="SOUK AHRAS">(41) SOUK-AHRAS</option>
          <option value="TIPAZA">(42) TIPAZA</option>
          <option value="MILA">(43) MILA</option>
          <option value="AIN DEFLA">(44) AIN-DEFLA</option>
          <option value="NAAMA">(45) NAAMA</option>
          <option value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
          <option value="GHARDAIA">(47) GHARDAIA</option>
          <option value="RELIZANE">(48) RELIZANE</option>
          <option value="Timimoun">(49) Timimoun</option>
          <option value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
          <option value="Ouled Djellal">(51) Ouled Djellal</option>
          <option value="Béni Abbès">(52) Béni Abbès</option>
          <option value="In Salah">(53) In Salah</option>
          <option value="In Guezzam">(54) In Guezzam</option>
          <option value="Touggourt">(55) Touggourt</option>
          <option value="Djanet">(56) Djanet</option>
          <option value="El M'Ghair">(57) El M'Ghair</option>
          <option value="El Meniaa">(58) El Meniaa</option>
        </select>
        <div className='alert-form'>{Showerr && (Wilaya == "" ? 'Veuillez renseigner votre Wilaya de naissance' : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputPassword4" className="form-label">Commune de naissance</label>
        <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>

          {
            CommuneList.length == 0 && <option disabled selected="selected">Commune</option>
          }

          {
            CommuneList.length > 0 && <option disabled selected="selected">Commune</option>
          }

          {CommuneList.length > 0 && CommuneList.map((item, i) => (

            <option value={item.dairas}>{item.dairas}</option>

          ))
          }
        </select>
        <div className='alert-form'>{Showerr && (Commune == "" ? 'Veuillez renseigner votre Commune de naissance.' : '')}</div>
      </div>
      {/* <label htmlFor="inputPassword4" className="form-label">Numéro d'acte de naissance</label> */}
      <div className="col-md-6 mb-5">
        <input type="text" placeholder="Numéro d'acte de naissance" onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g, ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" />
        <div className='alert-form'>{Showerr && (Actedenaissance == "" ? "Saisissez votre numéro d'acte de naissance à 5 chiffres" : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        {/* <input type="text" onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g , ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" /> */}

        <select class="form-select" aria-label="Default select example" onChange={(e) => setTypeactnaissnce(e.target.value)}>
          <option disabled selected="selected">Type act naissnce</option>
          <option value="biss">BISS </option>
          <option value="ter">TER</option>
          <option value="autre">Autre</option>
        </select>
        <div className='alert-form'>{Showerr && (Typeactnaissnce == "" ? "Veuillez renseigner votre type act naissnce" : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputPassword4" className="form-label" >Situation familiale</label>
        <select class="form-select" aria-label="Default select example" onChange={(e) => setSituationfamiliale(e.target.value)}>
          <option disabled selected="selected">Choisissez</option>
          <option value="Célibataire">Célibataire</option>
          <option value="Marié">Marié </option>
          <option value="Divorcé">Divorcé</option>
          <option value="veuf">veuf</option>
        </select>
        <div className='alert-form'>{Showerr && (Situationfamiliale == "" ? "Veuillez renseigner votre Situation familiale." : '')}</div>
      </div>
      <div className="col-md-6 mb-5">
        <label htmlFor="inputPassword4" className="form-label" >Niveau D'instructions</label>
        <select class="form-select" aria-label="Default select example" onChange={(e) => setNiveauDinstructions(e.target.value)}>
          <option disabled selected="selected">Choisissez</option>
          <option value={1}>Moins que le niveau secondaire</option>
          <option value={2}>Niveau secondaire</option>
          <option value={3}>Bachelier</option>
          <option value={4}>Universitaire</option>
        </select>
        <div className='alert-form'>{Showerr && (NiveauDinstructions == "" ? "Veuillez renseigner votre Niveau D'instructions." : '')}</div>
      </div>
      <div className="col-md-12 mb-2 mt-3">
        <label htmlFor="inputPassword4" className="form-label">Nombre d'enfants à charge</label>
        <div className='product-quantity'>Enfants :
          <div className='quantity-btn'>
            <button className="qtyminus" onClick={() => Children !== 0 ? setChildren(Children - 1) : ''} aria-hidden="true">−</button>
            <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Children} />
            <button className="qtyplus" onClick={() => Children < 10 ? setChildren(Children + 1) : ''} aria-hidden="true">+</button>
          </div>
        </div>
        {/* <input type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
      </div>
      <div className="col-md-12 mb-5 mt-3">
        <label htmlFor="inputPassword4" className="form-label">Nombre d'autres personnes à charge</label>
        <div className='product-quantity'>Personnes :
          <div className='quantity-btn'>
            <button className="qtyminus" onClick={() => Personnes !== 0 ? setPersonnes(Personnes - 1) : ''} aria-hidden="true">−</button>
            <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Personnes} />
            <button className="qtyplus" onClick={() => Personnes < 10 ? setPersonnes(Personnes + 1) : ''} aria-hidden="true">+</button>
          </div>
        </div>
        {/* <input type="number" max={2} min={0} className="form-control" id="inputPassword4" /> */}
      </div>
      {/* <div className="col-md-12 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Nombre d'autres personnes à charge
</label>
    <input type="number" max={2} min={0} className="form-control" id="inputPassword4" />
  </div> */}



      {/* </form> */}

      <div className='row mt-5'>
        <div className="col text-end">
          <button type="submit" onClick={() => GoNext()} className="next-info-btn">Suivant</button>

          {/* {
    FormValidate ? 
    <button type="submit" onClick={() => GoNext() } className="next-info-btn">Suivant</button>
    :
    <button className="next-info-btn-disable">Suivant</button>
   } */}
        </div>
      </div>
    </div>
  )
}

export default Info