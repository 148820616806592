import logo from './logo.svg';
import React from 'react'
import { useEffect , useState , useContext } from 'react'
import ReactDOM from 'react-dom'
import { Routes, Route ,useLocation} from "react-router-dom"
import './App.css';
import './components/HomePage/NavBar'
import NavBar from './components/HomePage/NavBar';
import Home from './pages/Home';
import Dartaksit from './pages/Dartaksit'
import Daridjar from './pages/Daridjar';
import Dari3mar from './pages/Dari3mar';
import Popup from './components/Global/Popup';
import {Provider} from './Context/bank';
import {LangProvider} from './Context/lang';
import { CartProvider } from './Context/cart';
import AuthContext from './Context/auth';
import Product from './pages/Product';
import Dari3marProduct from './pages/DarI3mar/Product' ;
import MyAccount from './pages/MyAccount';
import Search from './pages/Search';
import ShoppingCart from './pages/ShoppingCart';
import ScrollToTop from './ScrollToTop';
import Simulation from './pages/Simulation';
import Dashboard from './pages/Dashboard/Dashboard';
import MesCommandes from './pages/Dashboard/MesCommandes';
import CartSimulation from './pages/CartSimulation';
import SimulationCart from './pages/SimulationCart';
import CatProduct from './pages/CatProduct';
import DaridjarDossier from './pages/DaridjarDossier';
import Dari3marDossier from './pages/Dari3marDossier';
import Validation from './pages/Validation';
import Assistence from './pages/Assistence';
import CategorieDarI3mar from './pages/CategorieDarI3mar';
import DarSiyahaHome from './pages/DarSiyaha/DarSiyahaHome';
import Inscription from './pages/DarIdjar/Inscription';
import I3marSimulation from './components/DarI3mar/I3marSimulation';
import DarSen3aHome from './pages/DarSen3a/DarSen3aHome';
import Dar3a9arHome from './pages/Dar3a9ar/Dar3a9arHome';
import DarTaksitDossier from './pages/DarTaksitDossier';
import Faq from './pages/Faq';
import Aide from './pages/Store/Aide';
import Call from './components/Global/Call';


function App() {


  const {Auth,setAuth} = useContext(AuthContext);


  const location = useLocation();
  console.log('pathname', location.pathname);


  const getBank = localStorage.getItem('bank');
  useEffect(() => {

    console.log(Auth);

      
  }, []);

  console.log(location.pathname.split('/')[1]);


  return (
    <div className="App">
      <LangProvider>
      <CartProvider>
      <Provider>
      {location.pathname !== "/account" && location.pathname.split('/')[1]  !== "simulation"  && (location.pathname.split('/')[1]+"/"+location.pathname.split('/')[2])  !== "daridjar/inscription" && location.pathname.split('/')[1] !== "validation" && location.pathname !== "/simulationcart" && location.pathname !== "/profil" && location.pathname !== "/mescommandes" ? <NavBar /> : ''}

       {getBank == null ? <Popup />  : ''}
       
       <ScrollToTop>
        <Call />
       <Routes>
       <Route path="/" element={ <Home/> } />
       <Route path="*" element={ <Home/> } />

       {/* Dar Idjar */}
       <Route path="/daridjar" element={<Daridjar/>} />
       <Route path="/daridjar/dossier" element={<DaridjarDossier/>} />
       <Route path="/daridjar/inscription/:hash" element={<Inscription/>} />

       {/* Dar Idjar */}

       {/* Dar I3mar */}
       <Route path="/dari3mar" element={<Dari3mar/>} />
       <Route path="/dari3mar/dossier" element={<Dari3marDossier/>} />
       <Route path="/dari3mar/categorie/:id" element={<CategorieDarI3mar/>} />
       <Route path="/dari3mar/product/:id" element={<Dari3marProduct/>} />
       <Route path="/dari3mar/simulation/:id" element={<I3marSimulation/>} />
       {/* Dar I3mar */}


       {/* Dar Siayaha */}
       <Route path="/darsiyaha" element={<DarSiyahaHome/>} />
       {/* Dar Siayaha */}

       {/* Dar Sen3a */}
       <Route path="/darsan3a" element={<DarSen3aHome/>} />
       {/* Dar Sen3a */}

       {/* Dar 3a9ar */}
       <Route path="/dar3a9ar" element={<Dar3a9arHome/>} />
       {/* Dar 3a9ar */}

       {/* Dar Store */}
       <Route path="/Store" element={<Aide/>} />
       {/* Dar Store */}

       <Route path="/dartaksit" element={ <Dartaksit/> } />
       <Route path="/cat/:catname" element={ <CatProduct/> } />
       <Route path="/cat" element={ <CatProduct/> } />
       <Route path="/product/:id"  element={<Product />} />
       <Route path="/account"  element={<MyAccount />} />
       <Route path="/search/:keywords"  element={<Search />} />
       <Route path="/search/"  element={<Search />} />


       <Route path="/cart"  element={<ShoppingCart />} />
       <Route path="/simulation/:hash" element={<Simulation/>} />
       <Route path="/simulationcart/:hash" element={<SimulationCart/>} />
       <Route path="/cartsimulation" element={<CartSimulation/>} />
       <Route path="/validation/:hash" element={<Validation/>} />



       <Route path="/profil" element={<Dashboard/>} />
       <Route path='/mescommandes' element={<MesCommandes/>}  />


       <Route path='/assistence' element={<Assistence/>}  />
       <Route path='/faq' element={<Faq/>}  />
       <Route path='/dartaksit/dossier' element={<DarTaksitDossier/>}  />







       </Routes>
       </ScrollToTop>
      </Provider>
      </CartProvider>
      </LangProvider>
    </div>
  );
}

export default App;
