import { useEffect, useState, useContext } from 'react'
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import LangContext from '../../Context/lang';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';






const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1400 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1400, min: 991 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 991, min: 767 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1
  }
};

const SlideCategories = () => {

  const [DarTaksitCat, setDarTaksitCat] = useState([])
  const [Arry, setArry] = useState([1, 2, 3, 4])

  const { Lang, setLang, NavMenu } = useContext(LangContext);



  useEffect(() => {

    const fetchData = async () => {
      // setLoading(true);
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/dartaksit-categories`);
        console.log(response[0]);
        setDarTaksitCat(response[0]);
        console.log(DarTaksitCat)

      } catch (error) {
        console.error(error.message);
      }
      // setLoading(false);
    }

    fetchData();

  }, []);


  return (
    <section className='slide-categories-content-slide-categories mt-5 pb-0'>
      <div className='container container-fluid'>
        <div className="row gy-5">

          {/* <Carousel autoPlay={true} autoPlaySpeed={1000} responsive={responsive}> */}
          <Swiper
            // install Swiper modules
            modules={[Navigation, Autoplay]}
            loop={true}
            // spaceBetween={20}
            // slidesPerView={6}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 8
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 20,

              },

            }}
            navigation
            // autoplay={{
            //   delay: 1000,
            //   disableOnInteraction: false,
            // }}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >
            {DarTaksitCat.length > 0 ? DarTaksitCat.map((item, i) => (
              <SwiperSlide>

              <div className="col">
                <a href={`/search/?page=1&parent=${item.unicnom}`}>
                  <div className="card-product-dartaksit">
                    <div className="image-product">
                      <img className="img-fluid-dartaksit" src={`https://www.diardzair.com.dz/${item.img}`} />
                      <div className="title-product-dartaksit">
                        <span>{(Lang === 'ar' ? item.nomAr : item.nom)}</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              </SwiperSlide>
            ))
              :
              Arry.map((item, i) => (
              <SwiperSlide>

                <div className="col">
                <div className="card-product-dartaksit">

                  <Skeleton height={210} width={252} style={{ minHeight: '210px' }} borderRadius={20} className="slide-skeleton" /></div>
                </div>
              </SwiperSlide>

              ))
            }

          {/* </Carousel> */}
          </Swiper>
        </div>
        <div className={`btn-call-nav mt-5 mb-3 taksit-btn`}>
          <a href="/dartaksit/dossier">
            <svg className='me-3' xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 50 62">
            <path id="icons8-document" d="M15,9V71H65V23.586L50.414,9Zm2,2H49V25H63V69H17Zm34,1.414L61.586,23H51ZM22,13a1,1,0,1,0,1,1A1,1,0,0,0,22,13Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,17Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,21Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,25Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,29Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,33Zm6,1v2H55V34Zm-6,3a1,1,0,1,0,1,1A1,1,0,0,0,22,37Zm6,3v2H51V40Zm-6,1a1,1,0,1,0,1,1A1,1,0,0,0,22,41Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,45Zm6,1v2H55V46Zm-6,3a1,1,0,1,0,1,1A1,1,0,0,0,22,49Zm6,3v2H51V52Zm-6,1a1,1,0,1,0,1,1A1,1,0,0,0,22,53Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,57Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,61Zm0,4a1,1,0,1,0,1,1A1,1,0,0,0,22,65Z" transform="translate(-15 -9)" fill="#fff" />
          </svg>
            {NavMenu['Dossier-dartaksit'][Lang]}</a>
        </div>
      </div>
    </section>
  )
}

export default SlideCategories