import React from 'react'

const Pub = () => {
  return (
    <section className="draidjar-pub pb-5 text-center mt-5 mb-5">
    <div className="container mt-5 mb-5">
        <div className="row g-2 g-lg-4">
        <div className="col-12 col-lg-12"><img className="img-fluid" src="/img/dari3maridjarpub.png" /></div>
        </div>
    </div>
    </section>
  )
}

export default Pub