import { useEffect, useState, useContext } from 'react'
import axios from "axios";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import LangContext from '../../Context/lang';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 120 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1200, min: 992 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 992, min: 0 },
        items: 1
    }
};

const Category = () => {

    const { Lang, Global, dari3marsection } = useContext(LangContext);


    const [Category, setCategory] = useState([]);

    useEffect(() => {
        axios.get('https://www.diardzair.com.dz/i3mar/categorie').then(response => {
            console.log(response.data['categorie'])
            setCategory(response.data['categorie'])
            console.log(Category)
        });
    }, []);

    console.log(Category)



    return (

        // <section className="brands pt-5 text-center">
        //     <div className="container">
        //     <div className="row justify-content-center align-items-center">
        //     <Carousel autoPlay={true} autoPlaySpeed={1000} responsive={responsive}>

        //       {
        //         Category.length > 0 && Category.map((item,i) => (

        //           <div className="col"><Link to={"/search/?page=1&marque="+item.title} ><img className="img-fluid" loading="lazy" src={item['imageDesk']['webPath'] !== undefined ? `https://www.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/brandt.png"}  /></Link></div>
        //         ))
        //       }
        //         {/* <div className="col"><img className="img-fluid" src="img/midea.png" /></div>
        //         <div className="col"><img className="img-fluid" src="img/beko.png" /></div>
        //         <div className="col"><img className="img-fluid" src="img/samsung.png" /></div>
        //         <div className="col"><img className="img-fluid" src="img/brandt.png" /></div>
        //         <div className="col"><img className="img-fluid" src="img/midea.png" /></div>
        //         <div className="col"><img className="img-fluid" src="img/beko.png" /></div>
        //         <div className="col"><img className="img-fluid" src="img/samsung.png" /></div> */}
        //     </Carousel>
        //     </div>
        //     </div>
        // </section>


        <div className='category-dari3mar' id="i3marcat">
            <div className='container mt-2 pt-5'>
                <h1 className='mb-5'>{(dari3marsection['Catégories'][Lang])}</h1>
                <div className='row'>

                    {/* <Carousel autoPlay={true} autoPlaySpeed={1000} responsive={responsive}> */}
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Autoplay]}
                        loop={true}
                        // spaceBetween={20}
                        // slidesPerView={6}
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20

                            },
                            992: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,

                            },

                        }}
                        navigation
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }}
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >

                        {
                            Category.length > 0 && Category.map((item, i) => (
                                <SwiperSlide>

                                    <div className='col-lg-4 mt-5 mt-lg-0' style={{ 'width': '100%' }}>
                                        <a href={`/dari3mar/categorie/${item.unicnom}`} >
                                            <div className='dar-i3mar-cat-card'>
                                                <img src={Category !== '' ? `https://www.diardzair.com.dz/${item.img}` : ""}></img>
                                                <div className='dar-i3mar-cat-card-p'><p>{Lang === "ar" ? item.nomAr : item.nom}</p></div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="43" viewBox="0 0 63 43">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.044" y1="-0.585" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7ff6ad" />
                                                            <stop offset="1" stop-color="#37d9e1" />
                                                        </linearGradient>
                                                    </defs>
                                                    <rect id="Rectangle_196" data-name="Rectangle 196" width="63" height="43" rx="21.5" fill="url(#linear-gradient)" />
                                                    <text id="_" data-name="+" transform="translate(23 31)" fill="#fff" font-size="27" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">+</tspan></text>
                                                </svg>

                                            </div>
                                        </a>
                                    </div>
                                </SwiperSlide>
                                //   <div className="col"><Link to={"/search/?page=1&marque="+item.title} ><img className="img-fluid" loading="lazy" src={item['imageDesk']['webPath'] !== undefined ? `https://www.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/brandt.png"}  /></Link></div>
                            ))
                        }
                    </Swiper>
                    {/* </Carousel> */}










                </div>
            </div>
        </div>
    )
}

export default Category