import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'


const Header = () => {
  const [imgLoaded, setimgLoaded] = useState(false)

  const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

  return (
    <div className='dar-i3mar-header'>
      {
        !imgLoaded && <Skeleton className="slide-skeleton" />
      }
      <img onLoad={() => setimgLoaded(true)} className={!imgLoaded ? 'd-none' : 'img-fluid'} src={windowSize.innerWidth > 1180 ? '/img/dari3marpagebg.jpg' : '/img/dari3marpagebg.jpg'} />
    </div>
  )
}

export default Header