import { useEffect, useState, useContext } from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import BankContext from '../../Context/bank'




const Diar = () => {

    const [DiarBox, SetDiarBox] = useState('');
    const { bankActive, setBankActive } = useContext(BankContext);
    const [Loading, setLoading] = useState(true)


    useEffect(() => {
        setLoading(true)
        axios.get('https://diardzair.com.dz/ws/homepage/slides?type=diar').then(response => {

            console.log('diar box')
            console.log(response.data[0])
            const result = response.data[0].filter(data => data.bank == bankActive);
            SetDiarBox(result)
            setLoading(false)
            console.log('diar box 2')
            console.log(DiarBox[1]['imageDesk']['webPath'])
        });
        // console.log(window.innerWidth)
        // if (window.innerWidth < 600 ) {
        //      setMediaWidth('Mobile') 
        // }
        // console.log(MediaWidth)       
    }, [bankActive]);


    return (
        <section className="diar-box">
            {/* <div className='text-center'>
        <img src="./img/bnas.png" />
        <img src="./img/bnas.png" />
        <img src="./img/bnas.png" />
        </div> */}
            <div className="container">
                <div className="row g-2 g-lg-2 justify-content-center">
                    {!Loading && DiarBox.length > 0 && DiarBox.map((item, i) => (

                        <div className="col-lg-4 col-11 col-md-6 d-flex justify-content-center">
                            <div className='diar-card'>
                            <div class="junk-css"></div>
                            <Link className='diar-animation' to={`/${item?.title}`}>
                                <img loading="lazy" className="img-fluid" src={DiarBox !== '' ? `https://www.diardzair.com.dz/${item['imageDesk']['webPath']}` : "img/diarskeleton.png"} />
                            </Link>
                            </div>
                        </div>

                    ))
                    }

                    {Loading &&
                        <>
                            <div className="col-lg-4 col-12 col-md-6 d-flex justify-content-center" style={{overflow:'hidden'}}>
                                <Skeleton width={416} height={280} borderRadius={20}/>

                            </div>
                            <div className="col-lg-4 col-12 col-md-6 d-flex justify-content-center" style={{overflow:'hidden'}}>
                                <Skeleton width={416} height={280} borderRadius={20}/>

                            </div>
                            <div className="col-lg-4 col-12 col-md-6 d-flex justify-content-center" style={{overflow:'hidden'}}>
                                <Skeleton width={416} height={280} borderRadius={20}/>

                            </div>
                            <div className="col-lg-4 col-12 col-md-6 d-flex justify-content-center" style={{overflow:'hidden'}}>
                                <Skeleton width={416} height={280} borderRadius={20}/>

                            </div>
                            <div className="col-lg-4 col-12 col-md-6 d-flex justify-content-center" style={{overflow:'hidden'}}>
                                <Skeleton width={416} height={280} borderRadius={20}/>

                            </div>
                            <div className="col-lg-4 col-12 col-md-6 d-flex justify-content-center" style={{overflow:'hidden'}}>
                                <Skeleton width={416} height={280} borderRadius={20}/>

                            </div>
                        </>

                    }
                    {/* <div className="col-lg-4 col-612 col-md-6 d-flex justify-content-center">
                <Link to={`/${DiarBox[1]?.title}`}>
                <img loading="lazy" className="img-fluid" src={DiarBox !== '' ? `https://www.diardzair.com.dz/${DiarBox[1]['imageDesk']['webPath']}` : "img/diarskeleton.png"}  />
                </Link>
            </div>
            <div className="col-lg-4 col-612 col-md-6 d-flex justify-content-center">
                <Link to={`/daridjar`}>
                <img loading="lazy" className="img-fluid" src={DiarBox !== '' ? `https://www.diardzair.com.dz/${DiarBox[2]['imageDesk']['webPath']}` : "img/diarskeleton.png"}  />
                </Link>
            </div> */}
                </div>
            </div>
        </section>
    )
}

export default Diar