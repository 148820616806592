import { useEffect, useState, useContext } from 'react'
import ProductCart from '../components/Cart/ProductCart';
import CartContext from '../Context/cart';
import BankContext from '../Context/bank';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Topannonces from '../components/HomePage/Topannonces'
import Footer from '../components/Global/Footer'
import LangContext from '../Context/lang';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'




const ShoppingCart = () => {
  const { bankActive, GetTaksitPrice } = useContext(BankContext);
  const { Lang, ProductLang, Global, Panier } = useContext(LangContext);


  const { Cart, AddToCart, RemoveFromCart, Alert, MaxAmount, MinAmount, CartTotal, TimeCart } = useContext(CartContext);
  const [Quantity, setQuantity] = useState(1);
  const [HashCart, setHashCart] = useState(localStorage.getItem('HashCart'));
  const [Loading, setLoading] = useState(false)
  const Total = Cart.reduce((n, { quantité, price }) => n + (quantité * price), 0);

  console.log(HashCart, TimeCart);


  const navigate = useNavigate();

  console.log(Cart.length);

  useEffect(() => {

    if (!Number.isInteger(Number(MaxAmount)) || !Number.isInteger(Number(MinAmount)) || (Number(TimeCart) !== 12 && Number(TimeCart) !== 24 && Number(TimeCart) !== 36)) {


      console.log(!Number.isInteger(Number(MaxAmount)), !Number.isInteger(Number(MinAmount)), (Number(TimeCart) !== 12 && Number(TimeCart) !== 24 && Number(TimeCart) !== 36));

      navigate('/cartsimulation')
      navigate(0)
    }

  }, [])

  useEffect(() => {


    const GetHashCart = async () => {
      try {
        const { data: response } = await axios.get(`https://www.diardzair.com.dz/apicart/viewcart`);

        console.log(response['panier']['hash']);
        localStorage.setItem('HashCart', response['panier']['hash']);
        setHashCart(response['panier']['hash'])

      } catch (error) {
        console.error(error.message);
      }
    }


    if (localStorage.getItem('HashCart') == '' || localStorage.getItem('HashCart') == null) {
      GetHashCart();
    }




  }, [])





  async function sentCartData(e) {

    // e.preventDefault();

    setLoading(true)

    const CartProduct = Cart.map(x => { return { "hash": x["hash"], "qte": x["quantité"] } });

    console.log(HashCart);


    // console.log({
    //   "panier":{
    //       "hash" : "761a6e2e49f95f5fc50c91c61599a7c2",
    //       "total_product" : Cart.length,
    //       "total_price" : Total,
    //       "panierProduit": CartProduct
    //   }
    // });

    axios.post(`https://www.diardzair.com.dz/apicart/add?hash=`, {
      "panier": {
        "hash": localStorage.getItem('HashCart'),
        "total_product": Cart.length,
        "total_price": Total,
        "panierProduit": CartProduct
      }
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(async function (response) {
      console.log(response);
      console.log(response.data['panier']);
      
      if (response.data['errorCode'] == "1") {
        console.log('ggggggggggggg');

        try {
          const { data: response } = await axios.get(`https://www.diardzair.com.dz/apicart/viewcart`);
  
          console.log(response['panier']['hash']);
          localStorage.setItem('HashCart', response['panier']['hash']);
          setHashCart(response['panier']['hash'])

          if (response['panier']['hash']) {
            
            return sentCartData(e)
          }

  
        } catch (error) {
          console.error(error.message);
        }
        
        
        
      }

      const hashCart = response.data['panier']['hash'];
      // localStorage.removeItem('HashCart')
      // localStorage.removeItem('cart')
      navigate('/simulationcart/' + hashCart)
      // setMax(response.data['max'])
      // setMin(response.data['min'])
      // console.log(response.data);
      // console.log(Max , Min);
      // console.log(calculate_age(new Date(Year, Month, Day)));
      // setMaxAmount(response.data['max']);
      // setMinAmount(response.data['min']);
      // setTimeCart(Time);
      // setStep(2)
    })
      .catch(function (error) {
        // console.log(error);
      });
    console.log('hi!');

  }


  const [mtTime, setmtTime] = useState('')
  // const [mt24, setmt24] = useState('')
  // const [mt12, setmt12] = useState('')

  useEffect(() => {


    const fetchData = async () => {
      try {
        const { data: response36 } = await axios.get(`https://www.diardzair.com.dz/apicart/simulation?mt=${Total}&d=${TimeCart}`);

        setmtTime(response36['mtEcho'])

      } catch (error) {
        console.error(error.message);
      }
    }

    fetchData();

  }, [Total])


  console.log(Cart);

  function AlertMsg(params) {

    var msg;

    if (CartTotal() > MaxAmount) {
      msg = Lang === 'fr' ?
        `Vous avez dépassé le montant max autorisé de votre panier ,veuillez réduire ou modifier la liste des produits achetés pour ne pas dépassé ce montant ${MaxAmount?.toLocaleString()} DA Maximum`
        :
        ` لقد تجاوزت الحد الأقصى للمبلغ المسموح به من سلتك ، يرجى تقليل أو تعديل قائمة المنتجات المشتراة حتى لا تتجاوز هذا المبلغ ${MaxAmount?.toLocaleString()} دج  كحد أقصى`
        ;

    } else if (CartTotal() < MinAmount) {


      msg = Lang === 'fr' ?
        `Pour vous permettre de valider votre panier, merci d’augmenter le montant de votre panier à ${MinAmount?.toLocaleString()} DA minimum`
        :
        ` لتاكيد طلبيتكم ، يرجى زيادة مبلغ سلتك إلى ${MinAmount?.toLocaleString()}  دينار جزائري كحد أدنى`


    } else if ((HashCart == '' || HashCart == null)) {

      msg = Lang === 'fr' ?
        `Veuillez attendre que votre panier s'active et réessayez bientôt`
        :
        ` يرجى الانتظار حتى يتم تنشيط سلة التسوق الخاصة بك وحاول مرة أخرى قريبًا`

    }
    Swal.fire({
      title: '',
      type: 'error',
      text: msg,
      // timer: 2000,
      // showConfirmButton: false,
      confirmButtonText: "D'accord",
      confirmButtonColor: '#8d8a8a',


      icon: 'error',
      // didClose: () => window.scrollTo(0, 0)
    });
  }

  return (
    <>

      <div className='cart'>

        <div className='search-area d-flex align-items-center' style={{ height: '167px', padding: 0, backgroundPosition: 'center center', background: `linear-gradient(45deg,#f33f6959,#000000fc), url(https://cdn.create.vista.com/api/media/medium/421356038/stock-photo-presents-bows-shopping-cart-isolated?token=) center` }}>
          <div className='container'>
            <div className='titel ass-title'>{(Panier['panier'][Lang])}</div>

          </div>
        </div>

        {Loading && <div className="screen">
          <div className="loader">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="170" height="71" viewBox="0 0 102.428 24.04">
                <path id="Tracé_172" data-name="Tracé 172" d="M-451.687,716.448a4.469,4.469,0,0,0,1.372-.878.965.965,0,0,0,.311-.652.565.565,0,0,0-.127-.388.421.421,0,0,0-.332-.144.652.652,0,0,0-.408.158,1.571,1.571,0,0,0-.447.635,5.717,5.717,0,0,0-.369,1.27m1.983.821.187.249a3.849,3.849,0,0,1-2.38,1.321.733.733,0,0,1-.615-.272,1.425,1.425,0,0,1-.213-.873,4.581,4.581,0,0,1,.737-2.414,2.352,2.352,0,0,1,1.978-1.366.864.864,0,0,1,.615.2.727.727,0,0,1,.213.553,1.236,1.236,0,0,1-.255.725,2.947,2.947,0,0,1-.912.742q-.329.187-1.405.675a3.507,3.507,0,0,0-.051.583.919.919,0,0,0,.127.561.446.446,0,0,0,.371.159,1.214,1.214,0,0,0,.509-.13,7.148,7.148,0,0,0,1.094-.714" transform="translate(452.724 -695.019)" fill="#fff" />
                <path id="Tracé_173" data-name="Tracé 173" d="M-440.6,720.476l-.147.561h2.64l.137-.561Z" transform="translate(444.71 -699.41)" fill="#fff" />
                <path id="Tracé_174" data-name="Tracé 174" d="M-427.7,717.485l.119.328a4.019,4.019,0,0,1-2.278,1.06.786.786,0,0,1-.716-.352,1.622,1.622,0,0,1-.224-.889,4.081,4.081,0,0,1,.476-1.842,4,4,0,0,1,1.156-1.451,1.981,1.981,0,0,1,1.2-.391,1.065,1.065,0,0,1,.635.159.468.468,0,0,1,.215.385.381.381,0,0,1-.114.286.434.434,0,0,1-.317.111,1.093,1.093,0,0,1-.51-.182,1.124,1.124,0,0,0-.538-.193.823.823,0,0,0-.553.252,2.449,2.449,0,0,0-.524.976,4.441,4.441,0,0,0-.25,1.454,1.415,1.415,0,0,0,.21.832.491.491,0,0,0,.431.226,2.666,2.666,0,0,0,1.581-.771" transform="translate(438.051 -695.043)" fill="#fff" />
                <path id="Tracé_175" data-name="Tracé 175" d="M-416.58,714.374q-.482,0-.932.688a3.449,3.449,0,0,0-.451,1.9,2.105,2.105,0,0,0,.278,1.162.624.624,0,0,0,.544.312,1.08,1.08,0,0,0,.867-.589,3.408,3.408,0,0,0,.5-1.9,2.378,2.378,0,0,0-.229-1.213.671.671,0,0,0-.575-.363m.481-.425a1.154,1.154,0,0,1,.924.442,1.865,1.865,0,0,1,.369,1.229,3.319,3.319,0,0,1-.884,2.259,2.516,2.516,0,0,1-1.869,1,1.277,1.277,0,0,1-.978-.4,1.519,1.519,0,0,1-.371-1.083,3.854,3.854,0,0,1,.68-2.2,2.519,2.519,0,0,1,2.131-1.252" transform="translate(430.096 -695.043)" fill="#fff" />
                <path id="Tracé_176" data-name="Tracé 176" d="M-404.763,715.218l-.17-.227a4.575,4.575,0,0,1,1.067-.827,1.878,1.878,0,0,1,.8-.266.268.268,0,0,1,.179.051.177.177,0,0,1,.06.142,2.024,2.024,0,0,1-.091.436q-.13.476-.181.754,1.355-1.382,2.074-1.383a.368.368,0,0,1,.282.1.459.459,0,0,1,.1.323,3.371,3.371,0,0,1-.169.81,5.639,5.639,0,0,1,1.123-.954,1.637,1.637,0,0,1,.743-.281.486.486,0,0,1,.368.136.586.586,0,0,1,.13.42,1.738,1.738,0,0,1-.057.4q-.057.233-.414,1.417a10.231,10.231,0,0,0-.448,1.751c0,.129.053.193.159.193a.376.376,0,0,0,.21-.068,5.43,5.43,0,0,0,.686-.589l.187.2q-.119.1-.448.4a5.338,5.338,0,0,1-.623.51,1.165,1.165,0,0,1-.555.193.377.377,0,0,1-.3-.167.733.733,0,0,1-.13-.461,3.032,3.032,0,0,1,.079-.638q.078-.366.464-1.64a6.5,6.5,0,0,0,.289-1.162c0-.128-.057-.193-.17-.193a.816.816,0,0,0-.379.142,2.948,2.948,0,0,0-.816.72,2.71,2.71,0,0,0-.4.935q-.38,1.4-.567,2.284h-.8l.844-3.462a3.592,3.592,0,0,0,.091-.431.2.2,0,0,0-.045-.139.171.171,0,0,0-.13-.048.993.993,0,0,0-.475.237,3.144,3.144,0,0,0-.806.779,3.635,3.635,0,0,0-.421,1.148l-.436,1.915h-.814l.64-2.7q.283-1.173.284-1.275c0-.087-.034-.13-.1-.13q-.221,0-.9.646" transform="translate(420.744 -695.009)" fill="#fff" />
                <path id="Tracé_177" data-name="Tracé 177" d="M-383.009,715.218l-.17-.227a4.574,4.574,0,0,1,1.067-.827,1.879,1.879,0,0,1,.8-.266.268.268,0,0,1,.179.051.177.177,0,0,1,.06.142,2.021,2.021,0,0,1-.091.436q-.13.476-.182.754,1.356-1.382,2.075-1.383a.369.369,0,0,1,.282.1.459.459,0,0,1,.1.323,3.37,3.37,0,0,1-.169.81,5.638,5.638,0,0,1,1.123-.954,1.637,1.637,0,0,1,.743-.281.486.486,0,0,1,.368.136.586.586,0,0,1,.13.42,1.74,1.74,0,0,1-.057.4q-.057.233-.414,1.417a10.232,10.232,0,0,0-.448,1.751c0,.129.053.193.159.193a.375.375,0,0,0,.21-.068,5.428,5.428,0,0,0,.686-.589l.187.2q-.119.1-.448.4a5.347,5.347,0,0,1-.623.51,1.165,1.165,0,0,1-.556.193.377.377,0,0,1-.3-.167.733.733,0,0,1-.13-.461,3.033,3.033,0,0,1,.079-.638q.078-.366.464-1.64a6.5,6.5,0,0,0,.289-1.162c0-.128-.057-.193-.17-.193a.816.816,0,0,0-.379.142,2.95,2.95,0,0,0-.816.72,2.711,2.711,0,0,0-.4.935q-.38,1.4-.567,2.284h-.8l.844-3.462a3.511,3.511,0,0,0,.091-.431.2.2,0,0,0-.046-.139.169.169,0,0,0-.13-.048.993.993,0,0,0-.475.237,3.144,3.144,0,0,0-.806.779,3.634,3.634,0,0,0-.421,1.148l-.436,1.915h-.814l.64-2.7q.283-1.173.284-1.275c0-.087-.034-.13-.1-.13q-.221,0-.9.646" transform="translate(406.186 -695.009)" fill="#fff" />
                <path id="Tracé_178" data-name="Tracé 178" d="M-360.489,716.448a4.47,4.47,0,0,0,1.372-.878.966.966,0,0,0,.311-.652.564.564,0,0,0-.127-.388.421.421,0,0,0-.331-.144.652.652,0,0,0-.408.158,1.569,1.569,0,0,0-.447.635,5.7,5.7,0,0,0-.369,1.27m1.983.821.187.249a3.849,3.849,0,0,1-2.38,1.321.733.733,0,0,1-.615-.272,1.426,1.426,0,0,1-.212-.873,4.58,4.58,0,0,1,.736-2.414,2.352,2.352,0,0,1,1.977-1.366.864.864,0,0,1,.615.2.726.726,0,0,1,.212.553,1.236,1.236,0,0,1-.255.725,2.95,2.95,0,0,1-.912.742q-.329.187-1.405.675a3.545,3.545,0,0,0-.051.583.919.919,0,0,0,.127.561.446.446,0,0,0,.371.159,1.214,1.214,0,0,0,.509-.13,7.145,7.145,0,0,0,1.094-.714" transform="translate(391.697 -695.019)" fill="#fff" />
                <path id="Tracé_179" data-name="Tracé 179" d="M-348.483,718.141h-.844l.4-1.525q.29-1.122.5-2.131a2.155,2.155,0,0,0,.051-.311c0-.091-.041-.136-.125-.136a.569.569,0,0,0-.26.116,5.377,5.377,0,0,0-.635.575l-.176-.2a4.325,4.325,0,0,1,1.085-.876,1.98,1.98,0,0,1,.83-.292.251.251,0,0,1,.161.049.151.151,0,0,1,.06.122,2.633,2.633,0,0,1-.075.393l-.246,1.019a7.26,7.26,0,0,1,1.294-1.547.98.98,0,0,1,.579-.307.39.39,0,0,1,.284.111.348.348,0,0,1,.114.258.351.351,0,0,1-.111.261,1.686,1.686,0,0,1-.552.255,2.417,2.417,0,0,0-.682.34,3.235,3.235,0,0,0-.578.606,3.268,3.268,0,0,0-.439.711,11.762,11.762,0,0,0-.381,1.408Z" transform="translate(383.697 -694.469)" fill="#fff" />
                <path id="Tracé_180" data-name="Tracé 180" d="M-335.8,717.485l.119.328a4.021,4.021,0,0,1-2.278,1.06.787.787,0,0,1-.717-.352,1.621,1.621,0,0,1-.224-.889,4.084,4.084,0,0,1,.476-1.842,4,4,0,0,1,1.156-1.451,1.979,1.979,0,0,1,1.2-.391,1.065,1.065,0,0,1,.635.159.467.467,0,0,1,.215.385.384.384,0,0,1-.113.286.435.435,0,0,1-.317.111,1.094,1.094,0,0,1-.51-.182,1.124,1.124,0,0,0-.538-.193.825.825,0,0,0-.553.252,2.449,2.449,0,0,0-.524.976,4.449,4.449,0,0,0-.25,1.454,1.415,1.415,0,0,0,.21.832.491.491,0,0,0,.431.226,2.667,2.667,0,0,0,1.581-.771" transform="translate(376.555 -695.043)" fill="#fff" />
                <path id="Tracé_181" data-name="Tracé 181" d="M-326.128,716.448a4.47,4.47,0,0,0,1.372-.878.964.964,0,0,0,.311-.652.564.564,0,0,0-.127-.388.42.42,0,0,0-.332-.144.652.652,0,0,0-.408.158,1.574,1.574,0,0,0-.447.635,5.678,5.678,0,0,0-.368,1.27m1.983.821.187.249a3.848,3.848,0,0,1-2.38,1.321.731.731,0,0,1-.614-.272,1.421,1.421,0,0,1-.213-.873,4.58,4.58,0,0,1,.736-2.414,2.352,2.352,0,0,1,1.978-1.366.864.864,0,0,1,.615.2.725.725,0,0,1,.213.553,1.236,1.236,0,0,1-.255.725,2.946,2.946,0,0,1-.912.742q-.329.187-1.405.675a3.517,3.517,0,0,0-.051.583.918.918,0,0,0,.127.561.446.446,0,0,0,.371.159,1.216,1.216,0,0,0,.51-.13,7.162,7.162,0,0,0,1.094-.714" transform="translate(368.703 -695.019)" fill="#fff" />
                <path id="Tracé_182" data-name="Tracé 182" d="M-306.5,716.448a4.47,4.47,0,0,0,1.372-.878.965.965,0,0,0,.311-.652.564.564,0,0,0-.127-.388.421.421,0,0,0-.331-.144.653.653,0,0,0-.408.158,1.567,1.567,0,0,0-.447.635,5.679,5.679,0,0,0-.369,1.27m1.983.821.187.249a3.848,3.848,0,0,1-2.38,1.321.731.731,0,0,1-.614-.272,1.421,1.421,0,0,1-.213-.873,4.58,4.58,0,0,1,.737-2.414,2.353,2.353,0,0,1,1.978-1.366.863.863,0,0,1,.615.2.725.725,0,0,1,.213.553,1.236,1.236,0,0,1-.255.725,2.947,2.947,0,0,1-.912.742q-.329.187-1.4.675a3.511,3.511,0,0,0-.051.583.918.918,0,0,0,.127.561.446.446,0,0,0,.371.159,1.216,1.216,0,0,0,.51-.13,7.162,7.162,0,0,0,1.094-.714" transform="translate(355.568 -695.019)" fill="#fff" />
                <path id="Tracé_183" data-name="Tracé 183" d="M-293.37,710.613l.9-.345-.407,1.586h1.174l-.091.4h-1.174l-.494,1.9a5.373,5.373,0,0,0-.235,1.189.31.31,0,0,0,.065.221.234.234,0,0,0,.178.068,1.266,1.266,0,0,0,.539-.21,6.962,6.962,0,0,0,.906-.629l.215.261a14.354,14.354,0,0,1-1.661,1.145,1.624,1.624,0,0,1-.674.21.4.4,0,0,1-.332-.152.808.808,0,0,1-.122-.5,5.608,5.608,0,0,1,.215-1.247l.577-2.255h-.918l.1-.4h.919Z" transform="translate(346.984 -692.58)" fill="#fff" />
                <path id="Tracé_184" data-name="Tracé 184" d="M-274.26,706.584a.388.388,0,0,1,.289.116.411.411,0,0,1,.113.3.633.633,0,0,1-.173.439.514.514,0,0,1-.388.19.374.374,0,0,1-.284-.119.433.433,0,0,1-.114-.312.618.618,0,0,1,.164-.433.514.514,0,0,1,.391-.178m-2.227,3.751-.249-.193a4.393,4.393,0,0,1,1.033-.887,1.7,1.7,0,0,1,.8-.263.426.426,0,0,1,.278.079.264.264,0,0,1,.1.215,10.479,10.479,0,0,1-.367,1.394,13.97,13.97,0,0,0-.6,2.425.214.214,0,0,0,.04.133.108.108,0,0,0,.084.054q.182,0,.929-.7l.221.215a7.363,7.363,0,0,1-1.133.935,1.27,1.27,0,0,1-.584.2.409.409,0,0,1-.314-.124.487.487,0,0,1-.117-.346,17.1,17.1,0,0,1,.85-3.462.8.8,0,0,0,.04-.232c0-.075-.034-.114-.1-.114a.5.5,0,0,0-.258.091,5.789,5.789,0,0,0-.643.578" transform="translate(334.957 -690.114)" fill="#fff" />
                <path id="Tracé_185" data-name="Tracé 185" d="M-268,715.247l-.193-.2a5.874,5.874,0,0,1,1.157-.922,1.641,1.641,0,0,1,.737-.228.309.309,0,0,1,.188.051.149.149,0,0,1,.068.119,1.028,1.028,0,0,1-.028.181l-.255,1.141a6.972,6.972,0,0,1,1.448-1.2,1.561,1.561,0,0,1,.764-.289.436.436,0,0,1,.345.142.6.6,0,0,1,.124.408,1.82,1.82,0,0,1-.051.4q-.09.368-.474,1.6a6.687,6.687,0,0,0-.362,1.535.352.352,0,0,0,.034.175.1.1,0,0,0,.1.058q.165,0,.516-.317c.166-.152.332-.291.5-.419l.142.2a5.149,5.149,0,0,1-1.082.925,1.19,1.19,0,0,1-.589.226.4.4,0,0,1-.335-.156.847.847,0,0,1-.119-.508,12.36,12.36,0,0,1,.629-2.51,5.932,5.932,0,0,0,.193-.833c0-.117-.07-.176-.21-.176a1.145,1.145,0,0,0-.583.239,3.521,3.521,0,0,0-.89.828,2.7,2.7,0,0,0-.38.976l-.459,1.98h-.844q.238-.852.414-1.629l.5-2.156a1,1,0,0,0,.028-.187q0-.153-.113-.153a.526.526,0,0,0-.26.12,3.538,3.538,0,0,0-.657.571" transform="translate(329.238 -695.009)" fill="#fff" />
                <path id="Tracé_186" data-name="Tracé 186" d="M-251.861,715.247l-.192-.2a5.884,5.884,0,0,1,1.157-.922,1.642,1.642,0,0,1,.737-.228.31.31,0,0,1,.188.051.149.149,0,0,1,.068.119,1.03,1.03,0,0,1-.028.181l-.255,1.141a6.973,6.973,0,0,1,1.448-1.2,1.561,1.561,0,0,1,.764-.289.435.435,0,0,1,.345.142.6.6,0,0,1,.124.408,1.8,1.8,0,0,1-.051.4q-.09.368-.474,1.6a6.668,6.668,0,0,0-.362,1.535.351.351,0,0,0,.034.175.1.1,0,0,0,.1.058q.164,0,.516-.317c.166-.152.332-.291.5-.419l.142.2a5.156,5.156,0,0,1-1.083.925,1.192,1.192,0,0,1-.589.226.4.4,0,0,1-.335-.156.847.847,0,0,1-.119-.508,12.383,12.383,0,0,1,.629-2.51,5.868,5.868,0,0,0,.193-.833c0-.117-.069-.176-.209-.176a1.145,1.145,0,0,0-.583.239,3.53,3.53,0,0,0-.89.828,2.7,2.7,0,0,0-.38.976l-.459,1.98h-.844q.238-.852.414-1.629l.5-2.156a1.013,1.013,0,0,0,.028-.187q0-.153-.113-.153a.527.527,0,0,0-.26.12,3.55,3.55,0,0,0-.658.571" transform="translate(318.441 -695.009)" fill="#fff" />
                <path id="Tracé_187" data-name="Tracé 187" d="M-233.52,714.374q-.482,0-.932.688a3.447,3.447,0,0,0-.451,1.9,2.1,2.1,0,0,0,.278,1.162.623.623,0,0,0,.544.312,1.081,1.081,0,0,0,.867-.589,3.411,3.411,0,0,0,.5-1.9,2.374,2.374,0,0,0-.23-1.213.671.671,0,0,0-.575-.363m.481-.425a1.154,1.154,0,0,1,.924.442,1.865,1.865,0,0,1,.369,1.229,3.319,3.319,0,0,1-.884,2.259,2.516,2.516,0,0,1-1.87,1,1.277,1.277,0,0,1-.978-.4,1.52,1.52,0,0,1-.371-1.083,3.854,3.854,0,0,1,.68-2.2,2.52,2.52,0,0,1,2.131-1.252" transform="translate(307.597 -695.043)" fill="#fff" />
                <path id="Tracé_188" data-name="Tracé 188" d="M-219.541,714.3a5.518,5.518,0,0,0-.6,1.23,3.532,3.532,0,0,0-.167,1.06,8.373,8.373,0,0,0,.215,1.524,7.276,7.276,0,0,0,1.774-1.782,2.827,2.827,0,0,0,.521-1.43,1.068,1.068,0,0,0-.131-.442,1.093,1.093,0,0,1-.142-.419.369.369,0,0,1,.1-.258.32.32,0,0,1,.243-.11.35.35,0,0,1,.3.162.985.985,0,0,1,.114.547,3.889,3.889,0,0,1-.856,2.352,6.972,6.972,0,0,1-2.658,2.164c-.026-.178-.079-.46-.159-.85a9.978,9.978,0,0,1-.232-1.632,2.607,2.607,0,0,1,.283-1.175,2.788,2.788,0,0,1,.839-.989,3.748,3.748,0,0,0-1.428.221c-.2.1-.306.213-.306.345a.8.8,0,0,0,.079.266.62.62,0,0,1,.051.221.214.214,0,0,1-.077.162.267.267,0,0,1-.19.071.311.311,0,0,1-.249-.125.512.512,0,0,1-.1-.334.929.929,0,0,1,.221-.6,1.5,1.5,0,0,1,.765-.422,5.25,5.25,0,0,1,1.44-.142,1.722,1.722,0,0,1,.459-.158.227.227,0,0,1,.164.074.22.22,0,0,1,.074.158q0,.176-.345.318" transform="translate(298.542 -694.859)" fill="#fff" />
                <path id="Tracé_189" data-name="Tracé 189" d="M-203.489,714.365a2.649,2.649,0,0,0-.516-.062,1.454,1.454,0,0,0-.889.28,2.5,2.5,0,0,0-.705,1.009,3.527,3.527,0,0,0-.326,1.453.925.925,0,0,0,.13.555.409.409,0,0,0,.34.17,1.276,1.276,0,0,0,.839-.422,2.5,2.5,0,0,0,.657-1.216Zm.7-.867.374-.079-.425,1.434q-.256.856-.482,1.972a5.292,5.292,0,0,0-.124.782c0,.117.047.176.142.176a.68.68,0,0,0,.289-.119,3.681,3.681,0,0,0,.675-.521l.181.221q-.646.589-.827.714a3.117,3.117,0,0,1-.692.368,1.208,1.208,0,0,1-.419.079.313.313,0,0,1-.221-.068.255.255,0,0,1-.073-.2.853.853,0,0,1,.045-.232l.227-.77a4.8,4.8,0,0,1-1.162,1,1.452,1.452,0,0,1-.759.261.594.594,0,0,1-.5-.232,1.309,1.309,0,0,1-.238-.816,3.4,3.4,0,0,1,.321-1.337,4.932,4.932,0,0,1,.859-1.337,3.642,3.642,0,0,1,1.133-.884,2.781,2.781,0,0,1,1.178-.215,3.129,3.129,0,0,1,.369.023Z" transform="translate(288.146 -694.687)" fill="#fff" />
                <path id="Tracé_190" data-name="Tracé 190" d="M-189.568,710.613l.9-.345-.407,1.586h1.174l-.091.4h-1.174l-.494,1.9a5.374,5.374,0,0,0-.235,1.189.31.31,0,0,0,.065.221.234.234,0,0,0,.178.068,1.266,1.266,0,0,0,.539-.21,6.966,6.966,0,0,0,.906-.629l.215.261a14.354,14.354,0,0,1-1.661,1.145,1.624,1.624,0,0,1-.674.21.4.4,0,0,1-.332-.152.809.809,0,0,1-.122-.5,5.606,5.606,0,0,1,.215-1.247l.577-2.255h-.918l.1-.4h.919Z" transform="translate(277.522 -692.58)" fill="#fff" />
                <path id="Tracé_191" data-name="Tracé 191" d="M-178.165,706.584a.387.387,0,0,1,.289.116.41.41,0,0,1,.113.3.633.633,0,0,1-.173.439.514.514,0,0,1-.388.19.374.374,0,0,1-.284-.119.431.431,0,0,1-.113-.312.616.616,0,0,1,.164-.433.514.514,0,0,1,.391-.178m-2.227,3.751-.249-.193a4.407,4.407,0,0,1,1.033-.887,1.7,1.7,0,0,1,.8-.263.424.424,0,0,1,.278.079.265.265,0,0,1,.1.215,10.476,10.476,0,0,1-.367,1.394,13.987,13.987,0,0,0-.6,2.425.216.216,0,0,0,.04.133.109.109,0,0,0,.085.054q.182,0,.929-.7l.221.215a7.35,7.35,0,0,1-1.133.935,1.27,1.27,0,0,1-.584.2.41.41,0,0,1-.314-.124.488.488,0,0,1-.116-.346,17.111,17.111,0,0,1,.85-3.462.787.787,0,0,0,.04-.232c0-.075-.034-.114-.1-.114a.5.5,0,0,0-.258.091,5.845,5.845,0,0,0-.643.578" transform="translate(270.654 -690.114)" fill="#fff" />
                <path id="Tracé_192" data-name="Tracé 192" d="M-169.7,714.374q-.482,0-.932.688a3.45,3.45,0,0,0-.451,1.9,2.1,2.1,0,0,0,.278,1.162.624.624,0,0,0,.544.312,1.079,1.079,0,0,0,.867-.589,3.405,3.405,0,0,0,.5-1.9,2.382,2.382,0,0,0-.229-1.213.671.671,0,0,0-.575-.363m.482-.425a1.155,1.155,0,0,1,.924.442,1.867,1.867,0,0,1,.368,1.229,3.32,3.32,0,0,1-.884,2.259,2.516,2.516,0,0,1-1.87,1,1.278,1.278,0,0,1-.978-.4,1.519,1.519,0,0,1-.371-1.083,3.854,3.854,0,0,1,.68-2.2,2.519,2.519,0,0,1,2.131-1.252" transform="translate(264.888 -695.043)" fill="#fff" />
                <path id="Tracé_193" data-name="Tracé 193" d="M-157.873,715.247l-.192-.2a5.874,5.874,0,0,1,1.157-.922,1.641,1.641,0,0,1,.737-.228.31.31,0,0,1,.188.051.149.149,0,0,1,.068.119,1.031,1.031,0,0,1-.028.181l-.255,1.141a6.975,6.975,0,0,1,1.448-1.2,1.561,1.561,0,0,1,.764-.289.435.435,0,0,1,.345.142.6.6,0,0,1,.124.408,1.8,1.8,0,0,1-.051.4q-.09.368-.474,1.6a6.67,6.67,0,0,0-.362,1.535.351.351,0,0,0,.034.175.1.1,0,0,0,.1.058q.164,0,.516-.317c.166-.152.332-.291.5-.419l.142.2a5.147,5.147,0,0,1-1.082.925,1.193,1.193,0,0,1-.589.226.4.4,0,0,1-.335-.156.846.846,0,0,1-.119-.508,12.382,12.382,0,0,1,.629-2.51,5.872,5.872,0,0,0,.193-.833c0-.117-.069-.176-.209-.176a1.145,1.145,0,0,0-.583.239,3.529,3.529,0,0,0-.89.828,2.7,2.7,0,0,0-.38.976l-.459,1.98h-.844q.238-.852.414-1.629l.5-2.156a1.011,1.011,0,0,0,.028-.187q0-.153-.113-.153a.526.526,0,0,0-.26.12,3.548,3.548,0,0,0-.658.571" transform="translate(255.546 -695.009)" fill="#fff" />
                <path id="Tracé_194" data-name="Tracé 194" d="M-446.43,662.3l.123-.532a21.947,21.947,0,0,0,3.178-.545,23.492,23.492,0,0,0-.94,3.117l-.815,3.451a21.6,21.6,0,0,1-1.978,5.64,9.827,9.827,0,0,0,1.878.21,7.521,7.521,0,0,0,3.768-.909,6.549,6.549,0,0,0,2.552-2.653,7.947,7.947,0,0,0,.945-3.847,6.14,6.14,0,0,0-1.607-4.434,5.556,5.556,0,0,0-4.2-1.676,8.613,8.613,0,0,0-4.128,1.039,8.284,8.284,0,0,0-3.071,2.752,6.053,6.053,0,0,0-1.131,3.333,2.464,2.464,0,0,0,1.386,2.326,1.7,1.7,0,0,1,.513.383.654.654,0,0,1,.118.4.72.72,0,0,1-.23.525.727.727,0,0,1-.528.229,1.677,1.677,0,0,1-1.279-.866,4.3,4.3,0,0,1-.845-2.486,7.536,7.536,0,0,1,1.267-4.112,9.013,9.013,0,0,1,3.532-3.161,10.764,10.764,0,0,1,5.034-1.175,7.809,7.809,0,0,1,5.436,1.824,5.908,5.908,0,0,1,2.023,4.582,8.4,8.4,0,0,1-1.409,4.706,8.115,8.115,0,0,1-3.405,3.055,9.54,9.54,0,0,1-3.986.909q-.643,0-2.224-.124-1.335-.1-2.533-.1-.557,0-1.694.037l.284-.606a4.879,4.879,0,0,0,1.416-.2,1.176,1.176,0,0,0,.476-.458,13.346,13.346,0,0,0,.976-2.474l.853-3.228.989-4.032a2.169,2.169,0,0,0,.074-.507.414.414,0,0,0-.124-.315.481.481,0,0,0-.345-.118,3.151,3.151,0,0,0-.346.037" transform="translate(452.723 -658.476)" fill="#fff" />
                <path id="Tracé_195" data-name="Tracé 195" d="M-392.955,656.8a.845.845,0,0,1,.631.253.894.894,0,0,1,.247.649,1.38,1.38,0,0,1-.377.959,1.127,1.127,0,0,1-.848.415.817.817,0,0,1-.618-.26.944.944,0,0,1-.247-.68,1.345,1.345,0,0,1,.359-.946,1.12,1.12,0,0,1,.854-.39m-4.861,8.188-.545-.42a9.635,9.635,0,0,1,2.255-1.936,3.708,3.708,0,0,1,1.736-.575.93.93,0,0,1,.6.173.579.579,0,0,1,.21.47,22.961,22.961,0,0,1-.8,3.043,30.458,30.458,0,0,0-1.307,5.294.475.475,0,0,0,.086.291.238.238,0,0,0,.186.117q.4,0,2.028-1.534l.483.47a16.046,16.046,0,0,1-2.474,2.041,2.765,2.765,0,0,1-1.274.446.894.894,0,0,1-.687-.272,1.064,1.064,0,0,1-.254-.755q0-1.547,1.856-7.557a1.774,1.774,0,0,0,.087-.508q0-.247-.223-.247a1.1,1.1,0,0,0-.563.2,12.721,12.721,0,0,0-1.4,1.262" transform="translate(416.346 -656.8)" fill="#fff" />
                <path id="Tracé_196" data-name="Tracé 196" d="M-373.227,673.783a5.816,5.816,0,0,0-1.125-.136,3.18,3.18,0,0,0-1.942.612,5.482,5.482,0,0,0-1.54,2.2,7.713,7.713,0,0,0-.711,3.173,2.016,2.016,0,0,0,.285,1.212.892.892,0,0,0,.742.371,2.789,2.789,0,0,0,1.831-.922,5.475,5.475,0,0,0,1.435-2.653Zm1.534-1.892.816-.173-.928,3.129q-.557,1.868-1.051,4.3a11.414,11.414,0,0,0-.273,1.707q0,.383.31.383a1.489,1.489,0,0,0,.63-.26,8.1,8.1,0,0,0,1.472-1.138l.4.482q-1.41,1.287-1.806,1.559a6.777,6.777,0,0,1-1.509.8,2.638,2.638,0,0,1-.915.173.687.687,0,0,1-.482-.148.559.559,0,0,1-.161-.433,1.852,1.852,0,0,1,.1-.507l.495-1.682a10.481,10.481,0,0,1-2.535,2.189,3.175,3.175,0,0,1-1.658.569,1.3,1.3,0,0,1-1.1-.507,2.857,2.857,0,0,1-.519-1.781,7.407,7.407,0,0,1,.7-2.919,10.8,10.8,0,0,1,1.874-2.919,7.97,7.97,0,0,1,2.474-1.929,6.073,6.073,0,0,1,2.573-.47,6.791,6.791,0,0,1,.8.049Z" transform="translate(404.335 -666.783)" fill="#fff" />
                <path id="Tracé_197" data-name="Tracé 197" d="M-345.287,682.028h-1.843l.865-3.328q.631-2.449,1.1-4.65a4.944,4.944,0,0,0,.111-.681c0-.2-.091-.3-.272-.3a1.24,1.24,0,0,0-.569.254,11.774,11.774,0,0,0-1.385,1.255l-.383-.433a9.424,9.424,0,0,1,2.367-1.911,4.3,4.3,0,0,1,1.812-.638.549.549,0,0,1,.352.1.33.33,0,0,1,.13.266,5.687,5.687,0,0,1-.163.858l-.538,2.225a15.857,15.857,0,0,1,2.826-3.377,2.138,2.138,0,0,1,1.264-.671.852.852,0,0,1,.619.241.759.759,0,0,1,.248.563.768.768,0,0,1-.241.569,3.7,3.7,0,0,1-1.206.556,5.283,5.283,0,0,0-1.489.743A7.049,7.049,0,0,0-342.94,675a7.155,7.155,0,0,0-.958,1.553,25.7,25.7,0,0,0-.831,3.073Z" transform="translate(382.419 -666.307)" fill="#fff" />
                <path id="Tracé_198" data-name="Tracé 198" d="M-302.523,662.3l.123-.532a21.953,21.953,0,0,0,3.178-.545,23.493,23.493,0,0,0-.94,3.117l-.816,3.451a21.592,21.592,0,0,1-1.978,5.64,9.823,9.823,0,0,0,1.878.21,7.519,7.519,0,0,0,3.768-.909,6.544,6.544,0,0,0,2.552-2.653,7.942,7.942,0,0,0,.945-3.847,6.141,6.141,0,0,0-1.607-4.434,5.557,5.557,0,0,0-4.2-1.676,8.612,8.612,0,0,0-4.128,1.039,8.282,8.282,0,0,0-3.071,2.752,6.056,6.056,0,0,0-1.131,3.333,2.463,2.463,0,0,0,1.385,2.326,1.707,1.707,0,0,1,.513.383.653.653,0,0,1,.118.4.72.72,0,0,1-.23.525.725.725,0,0,1-.528.229,1.676,1.676,0,0,1-1.279-.866,4.3,4.3,0,0,1-.845-2.486,7.536,7.536,0,0,1,1.267-4.112,9.014,9.014,0,0,1,3.531-3.161,10.765,10.765,0,0,1,5.034-1.175,7.809,7.809,0,0,1,5.436,1.824,5.909,5.909,0,0,1,2.023,4.582,8.4,8.4,0,0,1-1.409,4.706,8.111,8.111,0,0,1-3.4,3.055,9.541,9.541,0,0,1-3.985.909q-.643,0-2.225-.124-1.335-.1-2.533-.1-.557,0-1.694.037l.285-.606a4.878,4.878,0,0,0,1.416-.2,1.178,1.178,0,0,0,.476-.458,13.34,13.34,0,0,0,.976-2.474l.852-3.228.989-4.032a2.155,2.155,0,0,0,.074-.507.414.414,0,0,0-.124-.315.481.481,0,0,0-.346-.118,3.145,3.145,0,0,0-.346.037" transform="translate(356.424 -658.476)" fill="#fff" />
                <path id="Tracé_199" data-name="Tracé 199" d="M-248.69,673.587a7.851,7.851,0,0,1-2.066.309c-.346,0-.73-.02-1.15-.062q-.878-.1-1.175-.1a.838.838,0,0,0-.569.173.565.565,0,0,0-.2.445.421.421,0,0,0,.445.458q.557.061.557.506a.515.515,0,0,1-.174.378.555.555,0,0,1-.4.167,1.082,1.082,0,0,1-.823-.439,1.617,1.617,0,0,1-.378-1.094,1.85,1.85,0,0,1,.7-1.447,2.583,2.583,0,0,1,1.744-.606,9.823,9.823,0,0,1,1.472.149,15.848,15.848,0,0,0,2.152.235,2.019,2.019,0,0,0,1.249-.445.838.838,0,0,1,.445-.2.369.369,0,0,1,.254.093.252.252,0,0,1,.105.179.425.425,0,0,1-.136.273q-.421.519-1.917,1.952l-3.835,3.67q-1.682,1.607-2.8,2.6a10.438,10.438,0,0,1,1.1-.08,5.11,5.11,0,0,1,1.014.093,14.624,14.624,0,0,1,1.472.426,6.948,6.948,0,0,0,2.152.458,1.183,1.183,0,0,0,.8-.241.775.775,0,0,0,.279-.612,1.441,1.441,0,0,0-.2-.594,1.5,1.5,0,0,1-.173-.569.714.714,0,0,1,.2-.507.653.653,0,0,1,.5-.21.749.749,0,0,1,.6.291,1.14,1.14,0,0,1,.241.751,2.652,2.652,0,0,1-.507,1.458,3.485,3.485,0,0,1-1.274,1.173,3.983,3.983,0,0,1-1.892.409,10.36,10.36,0,0,1-2.709-.534,8.8,8.8,0,0,0-2.177-.435,1.675,1.675,0,0,0-.977.44.919.919,0,0,1-.556.281q-.457,0-.457-.225a.86.86,0,0,1,.26-.513q.692-.816,3.512-3.373,1.905-1.73,3.339-3.151Z" transform="translate(322.248 -666.982)" fill="#fff" />
                <path id="Tracé_200" data-name="Tracé 200" d="M-213.992,673.783a5.811,5.811,0,0,0-1.125-.136,3.179,3.179,0,0,0-1.942.612,5.483,5.483,0,0,0-1.54,2.2,7.708,7.708,0,0,0-.711,3.173,2.014,2.014,0,0,0,.285,1.212.892.892,0,0,0,.742.371,2.789,2.789,0,0,0,1.83-.922,5.475,5.475,0,0,0,1.435-2.653Zm1.534-1.892.816-.173-.928,3.129q-.557,1.868-1.051,4.3a11.394,11.394,0,0,0-.272,1.707q0,.383.31.383a1.489,1.489,0,0,0,.631-.26,8.127,8.127,0,0,0,1.472-1.138l.4.482q-1.41,1.287-1.806,1.559a6.765,6.765,0,0,1-1.509.8,2.636,2.636,0,0,1-.915.173.687.687,0,0,1-.482-.148.558.558,0,0,1-.161-.433,1.85,1.85,0,0,1,.1-.507l.5-1.682a10.5,10.5,0,0,1-2.536,2.189,3.175,3.175,0,0,1-1.658.569,1.3,1.3,0,0,1-1.1-.507,2.855,2.855,0,0,1-.519-1.781,7.407,7.407,0,0,1,.7-2.919,10.782,10.782,0,0,1,1.874-2.919,7.969,7.969,0,0,1,2.474-1.929,6.074,6.074,0,0,1,2.573-.47,6.785,6.785,0,0,1,.8.049Z" transform="translate(297.779 -666.783)" fill="#fff" />
                <path id="Tracé_201" data-name="Tracé 201" d="M-183.207,656.8a.846.846,0,0,1,.631.253.891.891,0,0,1,.247.649,1.378,1.378,0,0,1-.377.959,1.126,1.126,0,0,1-.847.415.818.818,0,0,1-.618-.26.946.946,0,0,1-.247-.68,1.344,1.344,0,0,1,.359-.946,1.12,1.12,0,0,1,.854-.39m-4.861,8.188-.544-.42a9.62,9.62,0,0,1,2.254-1.936,3.707,3.707,0,0,1,1.736-.575.929.929,0,0,1,.6.173.579.579,0,0,1,.21.47,23.059,23.059,0,0,1-.8,3.043,30.441,30.441,0,0,0-1.307,5.294.477.477,0,0,0,.086.291.239.239,0,0,0,.186.117q.4,0,2.029-1.534l.483.47a16.06,16.06,0,0,1-2.474,2.041,2.766,2.766,0,0,1-1.274.446.894.894,0,0,1-.686-.272,1.065,1.065,0,0,1-.254-.755q0-1.547,1.855-7.557a1.765,1.765,0,0,0,.087-.508q0-.247-.223-.247a1.1,1.1,0,0,0-.563.2,12.825,12.825,0,0,0-1.4,1.262" transform="translate(275.987 -656.8)" fill="#fff" />
                <path id="Tracé_202" data-name="Tracé 202" d="M-167.694,682.028h-1.843l.865-3.328q.632-2.449,1.1-4.65a4.948,4.948,0,0,0,.111-.681q0-.3-.272-.3a1.24,1.24,0,0,0-.569.254,11.776,11.776,0,0,0-1.385,1.255l-.383-.433a9.422,9.422,0,0,1,2.367-1.911,4.3,4.3,0,0,1,1.812-.638.549.549,0,0,1,.352.1.33.33,0,0,1,.13.266,5.692,5.692,0,0,1-.163.858l-.538,2.225a15.87,15.87,0,0,1,2.826-3.377,2.137,2.137,0,0,1,1.264-.671.852.852,0,0,1,.619.241.76.76,0,0,1,.248.563.766.766,0,0,1-.241.569,3.686,3.686,0,0,1-1.2.556,5.28,5.28,0,0,0-1.489.743A7.049,7.049,0,0,0-165.346,675a7.154,7.154,0,0,0-.958,1.553,25.716,25.716,0,0,0-.831,3.073Z" transform="translate(263.578 -666.307)" fill="#fff" />
              </svg>

            </div>
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </div>
        </div>}

        {Cart.length == 0 ? <div className='container mt-5 pt-5 text-center'>
          <div className='max-amount-cart mb-5'>
            {Lang === 'fr' ?
              <div>Montant max autorisé de votre panier (La somme des produits achetés ne doit pas dépasser ce montant) : <span>{MaxAmount?.toLocaleString()} DA </span> Pour modifier <b><a className='a-simulation' href="/cartsimulation"> cliquez ici</a></b></div>
              :
              <div>الحد الأقصى للمبلغ المصرح به  لسلتك يجب ألا يتجاوز مجموع المنتجات المشتراة هذا المبلغ: <span>{MaxAmount?.toLocaleString()}</span>  دينار جزائري للتعديل  <b><a className='a-simulation' href="/cartsimulation"> انقر هنا </a></b></div>
            }
          </div>
          <h3>{(Panier['paniervide'][Lang])}</h3>
          <div onClick={() => navigate('/')} className='sell-btn m-auto'>{(Panier['Continuermesachats'][Lang])}
            <svg xmlns="http://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
              <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff" />
                <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff" />
                <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff" />
              </g>
            </svg>
          </div>
        </div> :

          <>

            <div className='container mt-1'>
              <div className='max-amount-cart'>
                {Lang === 'fr' ?
                  <div>Montant max autorisé de votre panier (La somme des produits achetés ne doit pas dépasser ce montant) : <span className='price-max-cart'>{MaxAmount?.toLocaleString()} DA </span> Pour modifier <b><a className='a-simulation' href="/cartsimulation"> cliquez ici</a></b></div>
                  :
                  <div>الحد الأقصى للمبلغ المصرح به  لسلتك يجب ألا يتجاوز مجموع المنتجات المشتراة هذا المبلغ: <span className='price-max-cart'>{MaxAmount?.toLocaleString()}</span>  دينار جزائري للتعديل  <b><a className='a-simulation' href="/cartsimulation"> انقر هنا </a></b></div>
                }
              </div>

              {CartTotal() < MinAmount && <div className='min-amount-cart mt-3'>
                {Lang === 'fr' ?
                  <div> Pour vous permettre de valider votre panier, merci d’augmenter le montant de votre panier à <span>{MinAmount?.toLocaleString()} DA </span> minimum</div>
                  :
                  <div>لتاكيد طلبيتكم ، يرجى زيادة مبلغ سلتك إلى <span>{MinAmount?.toLocaleString()} </span> دينار جزائري كحد أدنى</div>
                }
              </div>}

              {CartTotal() > MaxAmount &&
                <div className='min-amount-cart mt-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="106.87" height="93.964" viewBox="0 0 106.87 93.964">
                    <path id="alert-28" d="M156.842,192.806l-43.533-74.7c-2.022-3.568-4.877-5.471-8.088-5.471-3.092,0-6.066,1.9-8.088,5.471l-43.651,74.7c-2.022,3.449-2.26,6.9-.714,9.634s4.639,4.163,8.683,4.163h87.541c4.044,0,7.136-1.546,8.683-4.163,1.546-2.736,1.189-6.185-.833-9.634Zm-3.33,7.255c-.714,1.189-2.26,1.784-4.52,1.784H61.451c-2.26,0-3.925-.714-4.52-1.784-.714-1.189-.476-2.974.714-4.877l43.77-74.7c1.189-2.022,2.617-3.092,3.925-3.092s2.855,1.189,3.925,3.092l43.533,74.7C153.868,197.088,154.225,198.872,153.512,200.062ZM108.79,182.815a3.568,3.568,0,1,1-3.568-3.568A3.5,3.5,0,0,1,108.79,182.815Zm0-34.493v22.6a3.568,3.568,0,0,1-7.136,0v-22.6a3.568,3.568,0,0,1,7.136,0Z" transform="translate(-51.763 -112.64)" fill="#fff" />
                  </svg>
                  {Lang === 'fr' ?
                    <div>Vous avez dépassé le montant max autorisé de votre panier ,veuillez réduire ou modifier la liste des produits achetés pour ne pas dépassé ce montant <span>{MaxAmount?.toLocaleString()} DA </span> Maximum</div>
                    :
                    <div>لقد تجاوزت الحد الأقصى للمبلغ المسموح به من سلتك ، يرجى تقليل أو تعديل قائمة المنتجات المشتراة حتى لا تتجاوز هذا المبلغ <span>{MaxAmount?.toLocaleString()} دج </span> كحد أقصى</div>
                  }
                </div>

              }

              {Alert !== '' && Alert.page == "Cart" ?
                <div class={`alert alert-${Alert.flag} alert-dismissible fade show`} role="alert">
                  {Lang === 'ar' ? Alert.msgar : Alert.msg}.
                  {/* <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
                </div> : ''
              }
            </div>
            <div className='container mt-4 pt-4'>
              <div className='row'>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='cart-title'><p>{(Panier['panier'][Lang])}</p><p>{Cart.length + " " + (Panier['Produits'][Lang])}</p></div>
                  </div>

                  {
                    Cart.length > 0 && Cart.map((item, i) => (

                      <ProductCart item={item} key={item.hash} />

                      // <div className='row align-items-center mb-5 mt-3'>
                      //     <div className='col-2'>
                      //     <img className="cart-img" src={`https://www.diardzair.com.dz/${item.image}`}/>
                      //     </div>
                      //     <div className='col-2'>
                      //       <p>{item.nom}</p>
                      //       <p>{item.marque}</p>
                      //     </div>
                      //     <div className='col-3'>
                      //     <div className='product-quantity'>Quantité :
                      //                     <div className='quantity-btn'>
                      //                     <button className="qtyminus" onClick={() => Quantity !== 1 ? setQuantity(Quantity-1) : ''} aria-hidden="true">−</button>
                      //                     <input className='qtyinput' onKeyPress="event.preventDefault();" type="number" name="qty" id="qty" min={1} step={1} value={Quantity} />
                      //                     <button className="qtyplus" onClick={() => setQuantity(Quantity+1)} aria-hidden="true">+</button>
                      //                     </div>
                      //                     </div>
                      //     </div>
                      //     <div className='col-3 text-center'>
                      //         <p>89000 DA</p>
                      //     </div>
                      //     <div className='col-2'>
                      //        <div onClick={() => RemoveFromCart(item.hash)} className='remove-btn'>Supprimer</div>
                      //     </div>

                      // </div>
                    ))
                  }
                </div>
                <div className='col-lg-4'>

                <div className='cart-validation sticky-top'>
                <div className='total-price m-auto mb-5'>
                  
                  <div className='cart-price-taksit'>
                    {(Panier['prix_total'][Lang])} : {Total?.toLocaleString()} {(Global['Dajust'][Lang])}
                    <span dir={Lang === "ar" && 'rtl'}>
                      {/* {Math.round(mtTime)} DA / mois jusqu'à {TimeCart} mois  */}
                      {GetTaksitPrice(parseInt(Total))[(TimeCart ? TimeCart : '36')]}{(ProductLang['Taksit' + (TimeCart ? TimeCart : '36')][Lang])}
                    </span>
                  </div>
                </div>

                <div onClick={() => navigate('/')} className='sell-btn gosell mb-3 m-auto'>{(Panier['Continuermesachats'][Lang])}
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
                    <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                      <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff" />
                      <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff" />
                      <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff" />
                    </g>
                  </svg>
                </div>


                {CartTotal() > MaxAmount || CartTotal() < MinAmount || (HashCart == '' || HashCart == null) ?


                  <div onClick={() => AlertMsg('2')} className='sell-btn mt-3 mt-md-0 m-auto'>{(Panier['Commander'][Lang])}
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
                      <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                        <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff" />
                        <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff" />
                        <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff" />
                      </g>
                    </svg>
                  </div> :
                  <div onClick={() => sentCartData()} className='sell-btn mt-3 mt-md-0 m-auto'>{(Panier['Commander'][Lang])}
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.417" height="21.964" viewBox="0 0 23.417 21.964">
                      <g id="_270-2708173_cart-add-512x512-icon-online-shopping" data-name="270-2708173_cart-add-512x512-icon-online-shopping" transform="translate(-669.8 -330.1)">
                        <path id="Tracé_148" data-name="Tracé 148" d="M669.8,354.018c.238-.4.408-.463.848-.316l2.911.976c.39.128.472.248.509.665.073.857.151,1.719.229,2.595.578.087,1.142.17,1.705.257,1.284.188,2.567.38,3.851.564a.6.6,0,0,1,.555.371.538.538,0,0,1-.623.706c-.743-.105-1.481-.22-2.219-.33-.976-.147-1.948-.289-2.925-.431-.073-.009-.147-.014-.252-.023.028.339.05.656.092.972,0,.046.119.1.183.11,1.357.211,2.719.422,4.076.628l10.393,1.582c.651.1,1.3.193,1.948.3.16.027.234-.009.266-.174.06-.289.147-.573.229-.885l-1.123-.17c-1.036-.156-2.068-.307-3.1-.458a.536.536,0,0,1-.513-.628.526.526,0,0,1,.665-.431q1.939.282,3.874.573c.481.073.477.078.605-.4.055-.206.105-.413.165-.619.083-.293.248-.454.5-.463a.521.521,0,0,1,.536.348.22.22,0,0,0,.041.05v.229c-.028.087-.064.17-.087.257q-1,3.789-2.008,7.573c-.147.559-.243.633-.83.633H674.824c-.105,0-.215,0-.321,0a1.065,1.065,0,0,0-.156,2.1,1.931,1.931,0,0,0,.431.027h15.56a1.839,1.839,0,0,1,.275.009.535.535,0,0,1-.11,1.064c-.417.009-.834,0-1.284,0,.046.11.073.183.105.261a2.145,2.145,0,0,1-1.527,2.9c-.087.018-.174.041-.266.064h-.367a1.058,1.058,0,0,0-.128-.041,2.139,2.139,0,0,1-1.623-3c.023-.05.037-.11.06-.17h-7.427c.037.092.069.16.1.229a2.143,2.143,0,0,1-1.531,2.916c-.087.018-.179.041-.266.06h-.321c-.05-.014-.1-.032-.151-.041a2.14,2.14,0,0,1-1.627-3.007c.027-.06.046-.119.073-.179a2.169,2.169,0,0,1-.321-4.218c0-.073,0-.138,0-.2q-.475-5.515-.949-11.026a.278.278,0,0,0-.225-.289c-.834-.271-1.664-.559-2.5-.83a.783.783,0,0,1-.527-.431ZM675.081,367h.27q7.276,0,14.551,0c.183,0,.261-.041.312-.229.234-.926.486-1.848.729-2.769.023-.092.046-.188.078-.3-5.492-.839-10.952-1.673-16.444-2.508C674.746,363.145,674.911,365.053,675.081,367Zm2.168,5.355a1.064,1.064,0,1,0-1.059,1.068A1.07,1.07,0,0,0,677.249,372.356Zm10.122,1.064a1.066,1.066,0,1,0-1.073-1.05A1.054,1.054,0,0,0,687.372,373.419Z" transform="translate(0 -22.451)" fill="#fff" />
                        <path id="Tracé_149" data-name="Tracé 149" d="M905.713,330.1a1.487,1.487,0,0,1,.229.183.486.486,0,0,1,.105.532.491.491,0,0,1-.44.348c-.183.018-.367.023-.546.055a2.673,2.673,0,1,0,3.113,3.072c.023-.142.027-.289.037-.436a.534.534,0,1,1,1.064.055,3.6,3.6,0,0,1-2,3.246,3.718,3.718,0,0,1-5.313-4.241,3.606,3.606,0,0,1,2.984-2.76c.073-.014.147-.032.22-.05C905.346,330.1,905.53,330.1,905.713,330.1Z" transform="translate(-221.381)" fill="#fff" />
                        <path id="Tracé_150" data-name="Tracé 150" d="M950.716,379.035v.316c0,.5-.174.752-.523.756s-.536-.252-.536-.77v-.3c-.179,0-.335,0-.491,0a.535.535,0,1,1,.009-1.068c.151,0,.3,0,.486,0,0-.188,0-.353,0-.518a.53.53,0,1,1,1.059.009c0,.16,0,.316,0,.5.179,0,.335,0,.5,0a.533.533,0,0,1,.568.532.52.52,0,0,1-.555.536C951.065,379.04,950.9,379.035,950.716,379.035Z" transform="translate(-266.017 -44.653)" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                }


              </div>

                </div>
              </div>
            </div>
            <div className='container'>
            
              <div className='product-taksit-section mt-5'>
                <div className='taksit-titel'><p> تقسـيط </p><span class="line"></span></div>
                <div className='product-taksit-list-price mt-4'>
                <div className='bank-img-simulation me-3 mb-4 mb-md-0'>
                    <img src={'/img/' + bankActive + '.png'} />

                  </div>
                  <p dir={Lang === "ar" && 'rtl'}>
                    {/* {mtTime !== '' ? Math.round(mtTime) : ''} DA <span className='product-mounth'> / mois  </span>   <span className='product-mounth-time'> jusqu'à {TimeCart} mois</span> */}
                    {Total > 0 ? GetTaksitPrice(parseInt(Total))[(TimeCart ? TimeCart : '36')] : ''} {(ProductLang['Taksit' + (TimeCart ? TimeCart : '36')][Lang])}
                  </p>
                  {/* <p>{mt36 !== '' ? Math.round(mt24) : ''} DA <span className='product-mounth'> / mois  </span>    <span className='product-mounth-time'> jusqu'à 24 mois</span></p>
                                <p>{mt36 !== '' ? Math.round(mt12) : ''} DA <span className='product-mounth'> / mois  </span>     <span className='product-mounth-time'> jusqu'à 12 mois</span></p> */}
                </div>
              </div>
              
            </div>
          </>
        }


      </div>
      <Topannonces />
      <Footer />
    </>
  )
}

export default ShoppingCart