import React from 'react'

const Daridjarimaarpub = () => {
  return (
    <section className="draidjar-pub text-center">
      <div className="container pt-5 pb-5">
        <div className="row g-2 g-lg-12">
          <div className="col-12 col-lg-12">
            <a href='/dari3mar'>
              <img className="img-fluid" src="/img/dari3maridjarpub.png" />
            </a>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default Daridjarimaarpub