import { useEffect, useState } from 'react'

const Charges = ({ Step, setStep, StepTitel, setFormdata }) => {

    const [créditempruntimmobilier, setcréditempruntimmobilier] = useState('non')
    const [Mensualitéducréditimmobilier, setMensualitéducréditimmobilier] = useState('')
    const [Organismeprêteurimmobilier, setOrganismeprêteurimmobilier] = useState('')
    const [Datedefinducréditimmobilier, setDatedefinducréditimmobilier] = useState('')


    const [créditvoiture, setcréditvoiture] = useState('non')
    const [Mensualitéducréditvoiture, setMensualitéducréditvoiture] = useState('')
    const [Organismeprêteurdecréditvoiture, setOrganismeprêteurdecréditvoiture] = useState('')
    const [Datedefinducréditvoiture, setDatedefinducréditvoiture] = useState('')


    const [Autrecrédit, setAutrecrédit] = useState('non')
    const [Mensualitéduautrecrédit, setMensualitéduautrecrédit] = useState('')
    const [Organismeprêteurautrecrédit, setOrganismeprêteurautrecrédit] = useState('')
    const [Datedefinduautrecrédit, setDatedefinduautrecrédit] = useState('')

    const [Loyerpayer, setLoyerpayer] = useState('non')
    const [Loyer, setLoyer] = useState('')

    const [SéjourUSA, setSéjourUSA] = useState('non')
    const [GreenCard, setGreenCard] = useState('non')
    const [Compteépargne, setCompteépargne] = useState('non')
    const [CompteépargneText, setCompteépargneText] = useState('')
    const [immeublesoumeubles, setimmeublesoumeubles] = useState('')

    const [Mensualité, setMensualité] = useState('')

    const [FormValidate, setFormValidate] = useState(true)
    const [Showerr, setShowerr] = useState(false)
    // const [Datedefinducréditimmobilier, setDatedefinducréditimmobilier] = useState('')


    useEffect(() => {

        console.log(FormValidate);
        Validation();

    }, [créditempruntimmobilier, Mensualitéducréditimmobilier, Organismeprêteurimmobilier, Datedefinducréditimmobilier, créditvoiture, Loyer, Organismeprêteurdecréditvoiture, Datedefinducréditvoiture, Autrecrédit, Mensualitéduautrecrédit, Organismeprêteurautrecrédit, Datedefinduautrecrédit, Loyerpayer, GreenCard, Compteépargne, CompteépargneText, immeublesoumeubles, SéjourUSA])

    console.log(créditempruntimmobilier, Mensualitéducréditimmobilier, Organismeprêteurimmobilier, Datedefinducréditimmobilier, créditvoiture, Loyer, Organismeprêteurdecréditvoiture, Datedefinducréditvoiture, Autrecrédit, Mensualitéduautrecrédit, Organismeprêteurautrecrédit, Datedefinduautrecrédit, Loyerpayer, GreenCard, Compteépargne, immeublesoumeubles);



    function onlyLetters(str) {
        return /^[a-zA-Z]+$/.test(str);
    }
    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }
    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        // Test the string against the regular expression
        return regex.test(string)
    }

    function DateFormat(date) {

        var d = new Date(date);
        var datestring = d.getFullYear()  + "/" + d.getMonth().toString().padStart(2 , '0') + "/" + d.getDate().toString().padStart(2 , '0');
    
        return datestring;
        
      }

    function Validation() {

        setFormValidate(true)

        if ((créditempruntimmobilier !== 'non' && créditempruntimmobilier !== 'oui') || (créditvoiture !== 'non' && créditvoiture !== 'oui') || (Autrecrédit !== 'non' && Autrecrédit !== 'oui') || (Loyerpayer !== 'non' && Loyerpayer !== 'oui') || (SéjourUSA !== 'non' && SéjourUSA !== 'oui') || (GreenCard !== 'non' && GreenCard !== 'oui') || (Compteépargne !== 'non' && Compteépargne !== 'oui')) {
            setFormValidate(false)
        }

        if (créditempruntimmobilier == 'oui') {

            if (!onlyNumbers(Mensualitéducréditimmobilier)) {
                setFormValidate(false)
            }
            if (Organismeprêteurimmobilier == '') {
                setFormValidate(false)
            }
            if (Datedefinducréditimmobilier == '') {
                setFormValidate(false)
                
            }

        }

        if (créditvoiture == 'oui') {

            if (!onlyNumbers(Mensualitéducréditvoiture)) {
                setFormValidate(false)
            }
            if (Organismeprêteurdecréditvoiture == '') {
                setFormValidate(false)
            }
            if (Datedefinducréditvoiture == '') {
                setFormValidate(false)
                
            }

        }

        if (Autrecrédit == 'oui') {

            if (!onlyNumbers(Mensualitéduautrecrédit)) {
                setFormValidate(false)
            }
            if (Organismeprêteurautrecrédit == '') {
                setFormValidate(false)
            }
            if (Datedefinduautrecrédit == '') {
                setFormValidate(false)
            }

        }

        if (Loyerpayer == 'oui') {

            if (!onlyNumbers(Loyer)) {
                setFormValidate(false)
            }

        }

        if (Compteépargne == 'oui') {

            if (CompteépargneText == '') {
                setFormValidate(false)
            }

        }



        // if (Secteurdactivité !== "p" || Secteurdactivité !== "r" || Secteurdactivité !== "i" || Secteurdactivité !== "v") {
        //       setFormValidate(false)
        // }
        // if (Typedecontrat !== "CDD" || Typedecontrat !== "CDI" || Typedecontrat !== "INTERIM" || Typedecontrat !== "Autre") {
        //       setFormValidate(false)
        // }
        // if (!onlyLetters(Nomdelemployeur)) {
        //       setFormValidate(false)       
        // }
        // if (!onlyNumbers(Tel) || Tel.length > 11 ) {
        //       setFormValidate(false)    
        // }
        // if (!onlyNumbers(Salaire)) {
        //       setFormValidate(false)    
        // }
        // if (!onlyNumbers(CompteCCP)) {
        //       setFormValidate(false)
        // }
        // if (!onlyNumbers(CCPClé)) {
        //       setFormValidate(false)
        // }

    }

    function GoNext() {
        setShowerr(true)

        if (!FormValidate) {
            return
        }

        setFormdata((prev) => [
            {
                ...prev[0], ...{
                    "rimmobilier": créditempruntimmobilier,
                    "mimmobilier": Mensualitéducréditimmobilier,
                    "oimmobilier": Organismeprêteurimmobilier,
                    "dimmobilier": DateFormat(Datedefinducréditimmobilier),
                    "rvoiture": créditvoiture,
                    "mvoiture": Mensualitéducréditvoiture,
                    "ovoiture": Organismeprêteurdecréditvoiture,
                    "dvoiture": DateFormat(Datedefinducréditvoiture),
                    "rautrec": Autrecrédit,
                    "mautrec": Mensualitéduautrecrédit,
                    "oautrec": Organismeprêteurautrecrédit,
                    "dautrec": DateFormat(Datedefinduautrecrédit),
                    "rloyer": Loyerpayer,
                    "loyer": Loyer,
                    "mtLoyer": "",
                    "rsejourUsa": SéjourUSA,
                    "rgreenCard": GreenCard,
                    "rcomptepargne": Compteépargne,
                    "rgreenCard": GreenCard,
                    "mcompteepargne": CompteépargneText,
                    "biens": immeublesoumeubles.trim()
                }
            }
        ])

        setStep(5)
    }

    return (
        <div className={`form-info form-3 ${Step !== 4 ? 'd-none' : ''}`}>
            <div className='text-center info-step-titel mb-3'>{StepTitel[Step - 1]}</div>
            <form className="row g-3 m-auto justify-content-center">

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Avez-vous un crédit emprunt immobilier en cours ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio1" checked={créditempruntimmobilier == 'oui'} onChange={(e) => setcréditempruntimmobilier(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio2" checked={créditempruntimmobilier == 'non'} onChange={(e) => setcréditempruntimmobilier(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Non</label>
                    </div>
                </div>

                {
                    créditempruntimmobilier === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Mensualité du crédit immobilier</label>
                            <input type="text" className="form-control" onChange={(e) => setMensualitéducréditimmobilier(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéducréditimmobilier} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Mensualitéducréditimmobilier == "" ? 'Veuillez renseigner votre Mensualité du crédit immobilier' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Organisme prêteur immobilier</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurimmobilier(e.target.value)}>
                                <option disabled selected="selected">Choisissez</option>
                                <option value="AL-SALAM  ">Al salam Bank Algeria</option>
                                <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                <option value="BDL">BDL (Banque de Développement Local)</option>
                                <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                <option value="CITIBANK">CITIBANK</option>
                                <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                <option value="NATIXIS">Natixis ALGERIE</option>
                                <option value="SGA"> Société Générale Algérie</option>
                                <option value="BNP"> BNP Paribas Al-djazair</option>
                                <option value="TRUST-BANK"> TRUST BANK Algeria</option>
                                <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                <option value="AGB"> AGB (Gulf Bank Algérie)</option>
                                <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                <option value="FRANSABANK">Fransabank Al-Djazair</option>
                                <option value="HSBC "> HSBC Algeria</option>
                            </select>
                            <div className='alert-form'>{Showerr && (Organismeprêteurimmobilier == "" ? 'Veuillez renseigner votre Organisme prêteur immobilier' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label htmlFor="inputEmail4" className="form-label">Date de fin du crédit immobilier</label>
                            <input type="date" onChange={(e) => setDatedefinducréditimmobilier(e.target.value)} value={Datedefinducréditimmobilier} className="form-control" id="inputEmail4" />
                            <div className='alert-form'>{Showerr && (Datedefinducréditimmobilier == "" ? 'Veuillez renseigner votre Date de fin du crédit immobilier' : '')}</div>
                        </div>
                    </>
                }




                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Avez-vous un crédit voiture en cours ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio3" checked={créditvoiture == 'oui'} onChange={(e) => setcréditvoiture(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio4" checked={créditvoiture == 'non'} onChange={(e) => setcréditvoiture(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio4">Non</label>
                    </div>
                </div>

                {
                    créditvoiture === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Mensualité du crédit voiture</label>
                            <input type="text" className="form-control" onChange={(e) => setMensualitéducréditvoiture(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéducréditvoiture} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Mensualitéducréditvoiture == "" ? 'Veuillez renseigner votre Mensualité du crédit voiture' : '')}</div>

                        </div>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Organisme prêteur de crédit voiture</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurdecréditvoiture(e.target.value)}>
                                <option disabled selected="selected">Choisissez</option>
                                <option value="AL-SALAM  ">Al salam Bank Algeria</option>
                                <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                <option value="BDL">BDL (Banque de Développement Local)</option>
                                <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                <option value="CITIBANK">CITIBANK</option>
                                <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                <option value="NATIXIS">Natixis ALGERIE</option>
                                <option value="SGA"> Société Générale Algérie</option>
                                <option value="BNP"> BNP Paribas Al-djazair</option>
                                <option value="TRUST-BANK"> TRUST BANK Algeria</option>
                                <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                <option value="AGB"> AGB (Gulf Bank Algérie)</option>
                                <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                <option value="FRANSABANK">Fransabank Al-Djazair</option>
                                <option value="HSBC "> HSBC Algeria</option>
                            </select>
                            <div className='alert-form'>{Showerr && (Organismeprêteurdecréditvoiture == "" ? 'Veuillez renseigner votre Organisme prêteur de crédit voiture' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label htmlFor="inputEmail4" className="form-label">Date de fin du crédit voiture</label>
                            <input type="date" onChange={(e) => setDatedefinducréditvoiture(e.target.value)} value={Datedefinducréditvoiture} className="form-control" id="inputEmail4" />
                            <div className='alert-form'>{Showerr && (Datedefinducréditvoiture == "" ? 'Veuillez renseigner votre Organisme prêteur de Date de fin du crédit voiture' : '')}</div>
                        </div>
                    </>
                }

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Avez-vous un Autre crédit en cours ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio5" checked={Autrecrédit == 'oui'} onChange={(e) => setAutrecrédit(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio5">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions4" id="inlineRadio6" checked={Autrecrédit == 'non'} onChange={(e) => setAutrecrédit(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio6">Non</label>
                    </div>
                </div>


                {
                    Autrecrédit === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Mensualité du autre crédit</label>
                            <input type="text" className="form-control" onChange={(e) => setMensualitéduautrecrédit(e.target.value.replace(/[^0-9]+/g, ''))} value={Mensualitéduautrecrédit} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Mensualitéduautrecrédit == "" ? 'Veuillez renseigner votre Mensualité du autre crédit' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Organisme prêteur autre crédit</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setOrganismeprêteurautrecrédit(e.target.value)}>
                                <option disabled selected="selected">Choisissez</option>
                                <option value="AL-SALAM  ">Al salam Bank Algeria</option>
                                <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
                                <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
                                <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
                                <option value="BDL">BDL (Banque de Développement Local)</option>
                                <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
                                <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
                                <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
                                <option value="CITIBANK">CITIBANK</option>
                                <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
                                <option value="NATIXIS">Natixis ALGERIE</option>
                                <option value="SGA"> Société Générale Algérie</option>
                                <option value="BNP"> BNP Paribas Al-djazair</option>
                                <option value="TRUST-BANK"> TRUST BANK Algeria</option>
                                <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
                                <option value="AGB"> AGB (Gulf Bank Algérie)</option>
                                <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
                                <option value="FRANSABANK">Fransabank Al-Djazair</option>
                                <option value="HSBC "> HSBC Algeria</option>
                            </select>
                            <div className='alert-form'>{Showerr && (Organismeprêteurautrecrédit == "" ? 'Veuillez renseigner votre Organisme prêteur autre crédit' : '')}</div>
                        </div>
                        <div className="col-md-12 mb-5">
                            <label htmlFor="inputEmail4" className="form-label">Date de fin du autre crédit</label>
                            <input type="date" onChange={(e) => setDatedefinduautrecrédit(e.target.value)} value={Datedefinduautrecrédit} className="form-control" id="inputEmail4" />
                            <div className='alert-form'>{Showerr && (Datedefinduautrecrédit == "" ? 'Veuillez renseigner votre Date de fin du autre crédit' : '')}</div>
                        </div>
                    </>
                }



                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Avez-vous un Loyer à payer en cours ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio7" checked={Loyerpayer == 'oui'} onChange={(e) => setLoyerpayer(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio7">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions5" id="inlineRadio8" checked={Loyerpayer == 'non'} onChange={(e) => setLoyerpayer(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio8">Non</label>
                    </div>
                </div>

                {Loyerpayer === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Loyer</label>
                            <input type="text" className="form-control" onChange={(e) => setLoyer(e.target.value.replace(/[^0-9]+/g, ''))} value={Loyer} id="inputPassword4" />
                            <div className='alert-form'>{Showerr && (Loyer == "" ? 'Veuillez renseigner votre Loyer' : '')}</div>
                        </div>
                    </>}

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Séjour plus de 183 jours aux Etats Unis (USA) ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio9" checked={SéjourUSA == 'oui'} onChange={(e) => setSéjourUSA(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio9">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions6" id="inlineRadio10" checked={SéjourUSA == 'non'} onChange={(e) => setSéjourUSA(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio10">Non</label>
                    </div>
                </div>

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Possession d’une carte verte « Green Card » ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio11" checked={GreenCard == 'oui'} onChange={(e) => setGreenCard(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio11">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions7" id="inlineRadio12" checked={GreenCard == 'non'} onChange={(e) => setGreenCard(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio12">Non</label>
                    </div>
                </div>

                <div className="col-md-12 mb-2">
                    <div className='mb-2'>Avez-vous un Compte épargne ?</div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio13" checked={Compteépargne == 'oui'} onChange={(e) => setCompteépargne(e.target.value)} defaultChecked={true} defaultValue="oui" />
                        <label className="form-check-label" htmlFor="inlineRadio13">Oui</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions8" id="inlineRadio14" checked={Compteépargne == 'non'} onChange={(e) => setCompteépargne(e.target.value)} defaultValue="non" />
                        <label className="form-check-label" htmlFor="inlineRadio14">Non</label>
                    </div>
                </div>
                {Compteépargne === "oui" &&
                    <>
                        <div className="col-md-12 mb-1">
                            <label htmlFor="inputPassword4" className="form-label">Compte épargne</label>
                            <input type="text" className="form-control" onChange={(e) => setCompteépargneText(e.target.value.replace(/[^0-9]+/g, ''))} value={CompteépargneText} id="inputPassword4" />
                        </div>
                    </>}

                <div className="col-md-12 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">Biens immeubles ou meubles</label>
                    <input type="text" className="form-control" id="inputPassword4" onChange={(e) => setimmeublesoumeubles(e.target.value)} value={immeublesoumeubles} />
                    <span className='note-form'>Saisissez votre Biens immeubles ou meubles, hors prime, arrondi au dinar près sans virgule .</span>
                </div>



            </form>

            <div className='row mt-5'>
                <div className="col text-start">
                    <button type="submit" onClick={() => setStep(3)} className="next-info-btn">Retour</button>
                </div>
                <div className="col text-end">
                    <button type="submit" onClick={() => GoNext()} className="next-info-btn">Suivant</button>
                </div>
            </div>
        </div>
    )
}

export default Charges