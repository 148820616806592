import {useContext} from 'react'
import LangContext from '../../Context/lang';


const Description = () => {
  const { Lang, setLang, NavMenu, Global,daridjarPage } = useContext(LangContext);

  return (
    <div className='daridjar-desc'>
        <div className='container'>
            <div className='row align-items-center'> 
                <div className='col-lg-2 col-md-5'>
                    <div className='dar-idjar-folder'>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="118.5" height="118.537" viewBox="0 0 118.5 118.537">
  <defs>
    <linearGradient id="linear-gradient" x1="0.484" y1="-0.209" x2="0.074" y2="1.419" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#64d594"/>
      <stop offset="1" stop-color="#2dd2c7"/>
      <stop offset="1" stop-color="#37d9e1"/>
      <stop offset="1"/>
    </linearGradient>
  </defs>
  <g id="darijar" transform="translate(-68.87 -24.155)">
    <path id="Tracé_213" data-name="Tracé 213" d="M68.874,227.269a6.717,6.717,0,0,1,2.065-.768c5.514-.065,11.028-.043,16.539-.033,1.674,0,2.2.551,2.221,2.224.007.648,0,1.3,0,1.931,2.3-.206,4.5-.493,6.717-.583,3.956-.159,7.782.406,11.144,2.7.913.623,1.627,1.529,2.514,2.188a3.589,3.589,0,0,0,1.873.837c5.318.069,10.641.029,15.963.043a6.146,6.146,0,0,1,6.206,5.4c.485,3.6-1.373,6.786-4.511,7.634a7.725,7.725,0,0,1-1.942.2c-4.858.014-9.717.007-14.575.011-2,0-2.576.884-1.761,2.685a1.692,1.692,0,0,1-.663,2.471,1.741,1.741,0,0,1-2.522-.822,5.133,5.133,0,0,1-.1-5.391,4.717,4.717,0,0,1,4.485-2.511c4.934.043,9.869.014,14.807.014,2.167,0,3.329-1.091,3.3-3.09s-1.206-3.051-3.373-3.051c-5.166,0-10.333-.033-15.5.022a5.643,5.643,0,0,1-4.84-2.163c-1.8-2.239-4.387-3.058-7.112-3.427a27.845,27.845,0,0,0-9.42.547c-.627.13-.906.333-.9,1.036.025,8.445.018,16.887.022,25.332a4.494,4.494,0,0,0,.072.547c1.677-.083,3.319-.221,4.96-.221a9.059,9.059,0,0,1,2.815.373c5.956,1.975,11.9,3.967,17.821,6.054a8.948,8.948,0,0,0,8.528-1.109q17.629-11.793,35.367-23.426a3.706,3.706,0,0,0,1.946-3.967,2.5,2.5,0,0,0-4.13-1.757q-10.412,7.092-20.817,14.2a6.2,6.2,0,0,1-1.188.706,1.593,1.593,0,0,1-2.094-.717,1.691,1.691,0,0,1,.2-2.225,5.992,5.992,0,0,1,.927-.681q10.532-7.228,21.067-14.452c2.59-1.779,4.489-1.909,6.833-.348a6.521,6.521,0,0,1,3.007,6.673,6.8,6.8,0,0,1-3.1,4.931c-12.1,8.115-24.172,16.26-36.305,24.317a29.93,29.93,0,0,1-4.71,2.087h-3.935c-.837-.261-1.677-.511-2.511-.786-5.21-1.746-10.478-3.34-15.6-5.311-2.989-1.148-5.88-.623-8.869-.533a4,4,0,0,0-.083.5c-.1,2.217-.431,2.536-2.6,2.536-5.351,0-10.7.029-16.053-.036a7.23,7.23,0,0,1-2.177-.768Q68.875,247.28,68.874,227.269Zm17.158,3.054H72.388v34.1H86.032Z" transform="translate(0 -129.008)" fill="url(#linear-gradient)"/>
    <path id="Tracé_214" data-name="Tracé 214" d="M220.684,58.151v41.2c0,2.424-.366,2.782-2.84,2.782q-29.384,0-58.764,0c-.308,0-.616,0-.924-.007-1.5-.051-2.094-.641-2.1-2.138-.014-1.692,0-3.38,0-5.224h-1.308q-10.3,0-20.593,0c-3.286,0-4.819-1.529-4.819-4.8q0-20.531-.018-41.066A7.007,7.007,0,0,1,131,44.141c4.706-5.612,9.362-11.271,14.043-16.9,2.109-2.536,5.058-2.511,7.177.054q6.228,7.532,12.448,15.071c.221.264.449.522.739.859,2.348-1.982,4.666-3.938,6.989-5.9q7.244-6.119,14.488-12.242c1.46-1.232,2.069-1.235,3.511,0q17.374,14.836,34.751,29.657a1.692,1.692,0,0,1,.652,2.011,1.857,1.857,0,0,1-1.786,1.4C222.978,58.169,221.941,58.151,220.684,58.151Zm-3.467,40.533V58.223H167.9v1.768q0,15.265-.007,30.531c0,3.025-1.543,4.558-4.543,4.583-.924.007-1.851-.033-2.775.029-.254.018-.685.312-.7.5-.069,1.054-.033,2.119-.033,3.181h15.231V97.576q0-12.434,0-24.864c0-2.569.344-2.913,2.949-2.909q10.755.005,21.509,0c2.014,0,2.5.482,2.5,2.54q.005,12.434,0,24.864v1.475ZM148.711,28.733c-.355.388-.648.677-.909,1-4.634,5.568-9.282,11.126-13.865,16.738a3.931,3.931,0,0,0-.808,2.3c-.047,13.72-.025,27.444-.058,41.164,0,1.105.29,1.435,1.417,1.427q14.222-.065,28.444,0c1.119,0,1.424-.319,1.42-1.427q-.054-20.58-.007-41.164a3.337,3.337,0,0,0-.819-2.3Q156.591,38.17,149.7,29.838C149.417,29.49,149.1,29.167,148.711,28.733Zm39.845-.391c-6.894,5.891-13.695,11.7-20.477,17.521a1.232,1.232,0,0,0-.413.841c.047,2.188.152,4.377.232,6.568.018.453,0,.906,0,1.406h51.46C208.975,45.8,198.8,37.1,188.556,28.341Zm9.8,45.153H178.741V98.619H198.36Z" transform="translate(-38.543)" fill="url(#linear-gradient)"/>
    <path id="Tracé_215" data-name="Tracé 215" d="M165.749,145.891v2.518h-2.471v-2.525a45.334,45.334,0,0,1-5.032-1.572,43.844,43.844,0,0,1-4.5-2.627c1.08-1.293,1.985-2.377,2.974-3.558a13.173,13.173,0,0,0,6.677,3.366c0-1.819,0-3.565,0-5.315,0-2.141-.007-2.138-2-2.761-.438-.138-.877-.286-1.3-.46-3.855-1.551-5.261-3.739-4.913-7.63a7.48,7.48,0,0,1,6.134-6.521c.638-.141,1.286-.221,2.007-.341.051-.58.1-1.181.163-1.855h2.333c.054.648.105,1.25.156,1.884a15.571,15.571,0,0,1,7.815,2.924c-.844,1.206-1.652,2.359-2.384,3.406-1.811-.721-3.5-1.4-5.366-2.138,0,1.782-.014,3.261,0,4.739.033,2.467.043,2.467,2.33,3.2.145.047.29.109.435.159,4.406,1.543,5.949,3.768,5.532,7.981-.362,3.634-3.043,6.271-7.007,6.887C166.836,145.731,166.347,145.8,165.749,145.891Zm.2-4.5c2.3-.033,3.858-1.377,3.88-3.3.022-1.9-1.507-3.235-3.88-3.373Zm-2.609-18.618c-2.134.12-3.471,1.3-3.543,3.022-.072,1.8,1.177,2.993,3.543,3.326Z" transform="translate(-54.128 -58.959)" fill="url(#linear-gradient)"/>
    <path id="Tracé_216" data-name="Tracé 216" d="M177.263,74.649a7.3,7.3,0,0,1-7.369,7.3,7.15,7.15,0,0,1-7.3-7.192,7.347,7.347,0,0,1,7.373-7.318A7.18,7.18,0,0,1,177.263,74.649Zm-3.554.134a3.781,3.781,0,1,0-3.724,3.786A3.755,3.755,0,0,0,173.709,74.783Z" transform="translate(-59.766 -27.603)" fill="url(#linear-gradient)"/>
    <path id="Tracé_217" data-name="Tracé 217" d="M286.538,62.76a8.355,8.355,0,0,1-8.206,8.286,8.269,8.269,0,0,1-8.282-8.184,8.244,8.244,0,1,1,16.488-.1ZM278.3,58.123a4.649,4.649,0,1,0,.072,9.3,4.648,4.648,0,1,0-.072-9.3Z" transform="translate(-128.294 -19.351)" fill="url(#linear-gradient)"/>
  </g>
</svg>

                <a href='/daridjar/dossier'><p className='idjar-btn'> {(daridjarPage['dossier'][Lang])} </p></a>
                </div>

                </div>

                <div className='col-lg-5 col-md-5 mt-4 mt-lg-0'>
                    <div className='dar-idjar-titel'>
                    <p>{(daridjarPage['titre'][Lang])}</p>
                    </div>
                </div>

                <div className='col-lg-5 col-md-12 text-center text-lg-start'>
                    <p className='dar-idjar-text'> 
                    {(daridjarPage['definition'][Lang])}
                    </p>
                </div>

            </div>
        </div>

    </div>
  )
}

export default Description