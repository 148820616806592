import { useEffect, useState } from 'react'
import DartaksitCategories from './DartaksitCategories';
import axios from 'axios';


const CatSection = () => {

    const [DarTaksitCat, setDarTaksitCat] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`https://www.diardzair.com.dz/ws/dartaksit-categories`);
                console.log(response[0]);
                setDarTaksitCat(response[0]);
                console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    return (
        <div>

            <section className="pack-promo dar-taksit-cat-section mt-0 pb-5 text-center background-section"> 
            {DarTaksitCat.length > 0 && DarTaksitCat.map((item, i) => (

                <DartaksitCategories CatName={item} />
                
                ))
            }

            </section>
        </div>
    )
}

export default CatSection