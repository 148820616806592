import React, { createContext , useState ,useEffect } from 'react'

const AuthContext = createContext();

export function AuthProvider ({ children }) {
  var  authData;

  try {
     authData = JSON.parse(localStorage.getItem('Token'));
  } catch (err) {
    // 👇️ This runs
    authData = false
  }
  
  const getToken = localStorage.getItem('Token') !== '' || localStorage.getItem('Token') !== undefined || localStorage.getItem('Token') !== null ? authData : false;


  const [Auth, setAuth] = useState(getToken ? getToken : false);

  console.log(Auth);

  useEffect(() => {
    localStorage.setItem('Token', JSON.stringify(Auth));
  }, [Auth]);




  
  
  
 
  

  return <AuthContext.Provider value={{Auth,setAuth}}>{children}</AuthContext.Provider>

}


export default AuthContext