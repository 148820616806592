import {useState, useEffect, useContext} from 'react'
import { NavLink,useNavigate} from "react-router-dom";
import Menu from '../../components/Dashboard/Menu';
import AuthContext from '../../Context/auth';

const Dashboard = () => {

  const {Auth,setAuth} = useContext(AuthContext);
  let navigate = useNavigate();



  useEffect(() => {


    if (Auth) {
      console.log('hi!');
      
    }else{
      console.log('no!'); 
      return navigate("/");
    }

     
  }, [Auth])

  const [MenuShow, setMenuShow] = useState(true)

  const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)
  

  
  return (
    <div className='dashboard'>
      

      <div className=''>
         <div className=''>

          <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />
          
         </div>
         <div className='page-client pb-5 pt-lg-5' style={{marginLeft: windowSize.innerWidth > 992 ?  (MenuShow == true ? '328px' : '89px') : '0px'}}>
          <div className='menu-mobile-section'>
          <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
          </div>
          <div className='page-title'>Mon Compte <span>Mes Information</span></div>
          <div className='client-information mt-5'>
            <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-4 gy-5 gy-lg-0'>
                  <div className='box-info bg-g-first'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24.631" height="24.631" viewBox="0 0 24.631 24.631">
                    <path id="Tracé_6" data-name="Tracé 6" d="M23.028,27.063H3.6A2.6,2.6,0,0,1,1,24.46V11.6A2.6,2.6,0,0,1,3.6,9H23.028a2.6,2.6,0,0,1,2.6,2.6V24.46A2.6,2.6,0,0,1,23.028,27.063ZM3.6,10.642a.961.961,0,0,0-.961.961V24.46a.961.961,0,0,0,.961.961H23.028a.961.961,0,0,0,.961-.961V11.6a.961.961,0,0,0-.961-.961Z" transform="translate(-1 -2.432)" fill="#fff"/>
                    <rect id="Rectangle_4" data-name="Rectangle 4" width="16" height="2" rx="1" transform="translate(4 3)" fill="#fff"/>
                    <rect id="Rectangle_5" data-name="Rectangle 5" width="12" height="2" rx="1" transform="translate(6)" fill="#fff"/>
                    <path id="Tracé_7" data-name="Tracé 7" d="M16.039,15.708H14.4a2.775,2.775,0,0,1-2.767-2.767V9h7.184v3.933a2.775,2.775,0,0,1-2.775,2.775Zm-2.767-5.066v2.291A1.133,1.133,0,0,0,14.4,14.066h1.642a1.133,1.133,0,0,0,1.133-1.125v-2.3Z" transform="translate(-2.902 -2.432)" fill="#fff"/>
                  </svg>

                      <p>Mes Commandes</p> 
                      <p>80</p>
                  </div>
              </div>
              <div className='col-12 col-lg-4 gy-5 gy-lg-0'>
                  <div className='box-info bg-g-second'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                      <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
                      <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#fff"/>
                    </g>
                  </svg>

                     <p>Mes Achats</p>
                     <p>60</p>
                  </div>
              </div>
              <div className='col-12 col-lg-4 gy-5 gy-lg-0'>
                  <div className='box-info bg-g-third'>
                  <svg id="dashboard-svgrepo-com" xmlns="http://www.w3.org/2000/svg" width="30.776" height="25.646" viewBox="0 0 30.776 25.646">
                    <g id="Groupe_431" data-name="Groupe 431">
                      <g id="Groupe_430" data-name="Groupe 430">
                        <path id="Tracé_9269" data-name="Tracé 9269" d="M15.388,2.334h0A15.339,15.339,0,0,0,4.51,6.838l0,0,0,0A15.339,15.339,0,0,0,0,17.708s0,.009,0,.014,0,.009,0,.013a15.326,15.326,0,0,0,3.517,9.78,1.282,1.282,0,0,0,.989.466H26.269a1.282,1.282,0,0,0,.989-.466A15.387,15.387,0,0,0,15.388,2.334ZM25.647,25.415H5.128A12.742,12.742,0,0,1,2.629,19H6.412a1.282,1.282,0,1,0,0-2.565H2.629A12.763,12.763,0,0,1,5.459,9.606l.861.861A1.282,1.282,0,0,0,8.134,8.654l-.861-.861a12.763,12.763,0,0,1,6.833-2.831V8.745a1.282,1.282,0,0,0,2.565,0V4.962A12.763,12.763,0,0,1,23.5,7.793l-.861.861a1.282,1.282,0,1,0,1.813,1.813l.861-.861a12.763,12.763,0,0,1,2.831,6.833H24.364a1.282,1.282,0,0,0,0,2.565h3.784A12.743,12.743,0,0,1,25.647,25.415Z" transform="translate(0 -2.334)" fill="#fff"/>
                        <path id="Tracé_9270" data-name="Tracé 9270" d="M18.153,12.067,10.9,15.695l3.626,3.626Z" transform="translate(1.081 -1.369)" fill="#fff"/>
                        <path id="Tracé_9271" data-name="Tracé 9271" d="M18.309,19.833H10.615a1.282,1.282,0,1,0,0,2.565h7.694a1.282,1.282,0,0,0,0-2.565Z" transform="translate(0.925 -0.599)" fill="#fff"/>
                      </g>
                    </g>
                  </svg>


                     <p>Mes Demandes</p>
                     <p>80</p>
                  </div>
              </div>
            </div>
            </div>
          </div>
          <div className='page-content'>
          <form className="row g-3">
          <div className="col-md-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>

              <label htmlFor="inputEmail4" className="form-label">Nom</label>
              <input type="email" className="form-control" defaultValue={JSON.parse(localStorage.getItem('Token'))?.username} id="inputEmail4" />
            </div>
            <div className="col-md-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>
              <label htmlFor="inputEmail4" className="form-label">Mobile</label>
              <input type="email" className="form-control" defaultValue={''} id="inputEmail4" />
            </div>
            <div className="col-md-6"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>
              <label htmlFor="inputEmail4" className="form-label">Email</label>
              <input type="email" className="form-control" defaultValue={JSON.parse(localStorage.getItem('Token'))?.email} id="inputEmail4" />
            </div>
            <div className="col-md-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
              <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none"/>
              <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d"/>
            </g>
          </svg>
              <label htmlFor="inputPassword4" className="form-label">Password</label>
              <input type="password" className="form-control" defaultValue={'123456789'} id="inputPassword4" />
            </div>           
            <div className="col-12 mt-5">
              <button type="submit" className="btn btn-secondary">Modifier</button>
            </div>
          </form>
          </div>
         </div>
      </div>

    </div>
  )
}

export default Dashboard