import { useEffect, useState, useContext } from 'react'
import jwt_decode from 'jwt-decode';
import AuthContext from '../../Context/auth';
import { Link, useSearchParams, useNavigate } from "react-router-dom";

// import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';


// import FacebookLogin from 'react-facebook-login';




const SignWithGmailAndFb = () => {

    const responseFacebook = (response) => {
        console.log(response);
    }

    const componentClicked = () => {
        console.log('hhhh');
    }


    const navigate = useNavigate();


    const { Auth, setAuth } = useContext(AuthContext);

    useEffect(() => {

        function start() {
            gapi.client.init({
                clientId: '119803488198-71h8ev81q0olt1cacekd6d1s09v8m2ea.apps.googleusercontent.com',
                scope: ''
            })

        }

        gapi.load('client:auth2', start)



    }, [])



    useEffect(() => {


        /*global google*/
        const google = window.google;

        google?.accounts.id.initialize({
            client_id: '119803488198-71h8ev81q0olt1cacekd6d1s09v8m2ea.apps.googleusercontent.com',
            callback: handleCredentialResponse
        });

        google?.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            { theme: 'outline', size: 'large' }
        );



    }, [])


    function handleCredentialResponse(response) {
        console.log(response);
        console.log(jwt_decode(response.credential));

        const googledata = jwt_decode(response.credential)

        setAuth({ email: googledata.email, username: googledata.name, token: googledata.sub })
        // setloading(false)
        navigate('/profil')
        navigate(0)
    }


    const responseGoogle = (response) => {
        console.log(response);
    }

    return (
        <>
            <div className='container'>
                <div className='row justify-content-center pt-3'>
                    <div className='col-8'>

                        <div className='or-div mb-2'>
                            OU
                        </div>

                        <div className="col-12 text-center">
                            <button type="" className="login-btn-fb">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="40" height="40.001" viewBox="0 0 40 40.001">
                                    <defs>
                                        <linearGradient id="linear-gradient8" x1="0.15" y1="0.15" x2="0.915" y2="0.915" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#fff" />
                                            <stop offset="1" stop-color="#fff" />
                                        </linearGradient>
                                    </defs>
                                    <g id="icons8-facebook" transform="translate(-4 -4)">
                                        <path id="Soustraction_1" data-name="Soustraction 1" d="M-1740.1-2346a19.884,19.884,0,0,1-14.078-5.891A19.872,19.872,0,0,1-1760-2366a19.869,19.869,0,0,1,5.858-14.142A19.871,19.871,0,0,1-1740-2386a19.87,19.87,0,0,1,14.142,5.858A19.869,19.869,0,0,1-1720-2366a19.874,19.874,0,0,1-5.828,14.112,19.9,19.9,0,0,1-13.634,5.88c.654-.024,1.363-.088,2.169-.2v-14.5h5.176l.813-5.258h-5.989v-2.874a5.9,5.9,0,0,1,.612-2.88,2.326,2.326,0,0,1,2.144-1.241h3.283v-4.588l-.026,0a27.965,27.965,0,0,0-4.076-.245c-5.067,0-7.636,2.8-7.636,8.334v3.5h-4.948v5.258h4.947v14.452a20.243,20.243,0,0,0,2.9.245Z" transform="translate(1764 2390)" fill="url(#linear-gradient8)" />
                                    </g>
                                </svg>
                                Continuer avec Facebook</button>
                        </div>
                        <div className='mt-3' id='buttonDiv'></div>
                        {/* <FacebookLogin
                            appId="1088597931155576"
                            autoLoad={true}
                            fields="name,email,picture"
                            onClick={componentClicked}
                            callback={responseFacebook} 
                            icon="fa-facebook"
                            /> */}
                        {/* <GoogleLogin
                            clientId="119803488198-71h8ev81q0olt1cacekd6d1s09v8m2ea.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                        />
                        <GoogleLogout
                            clientId="119803488198-71h8ev81q0olt1cacekd6d1s09v8m2ea.apps.googleusercontent.com"
                            buttonText="Logout"
                            onLogoutSuccess={ ()=> console.log('logout')}
                        >
                        </GoogleLogout> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignWithGmailAndFb