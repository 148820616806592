import React from 'react'
import Box from '../components/DarI3mar/Box'
import Category from '../components/DarI3mar/Category'
import Description from '../components/DarI3mar/Description'
import Engagements from '../components/DarI3mar/Engagements'
import Header from '../components/DarI3mar/Header'
import Pub from '../components/DarI3mar/Pub'
import Footer from '../components/Global/Footer'

const Dari3mar = () => {
  return (
    <div className='dari3mar'>
    <Header />
    <Box />
    <Category />
    <Description />
    <Engagements />
    <Pub />
    <Footer />
    {/* <div>Dari3mar</div> */}
    </div>
  )
}

export default Dari3mar