
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


function Slide() {

    const [Slides, setSlides] = useState([]);
    const [imgLoaded, setimgLoaded] = useState(false)

    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`https://diardzair.com.dz/ws/homepage/slides?type=taksit`);
                // console.log(response[0]);
                setSlides(response[0]);
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);



    return (
        <section className="slide-categories-slider">
            {
                !imgLoaded && <Skeleton height={500} style={{ minHeight: '500px' }} borderRadius={20} className="slide-skeleton" />
            }


            <Swiper
                // install Swiper modules
                modules={[Navigation, Autoplay]}
                // loop={true}
                // spaceBetween={20}
                slidesPerView={1}
                speed={1200}

                navigation
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                
            >
                {/* <div id="diar-dzair-slide" className={imgLoaded ? "carousel slide" : 'd-none'} data-bs-ride="carousel"> */}
                {/* <div className="carousel-indicators">
                    <button type="button" data-bs-target="#diar-dzair-slide" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                    <button type="button" data-bs-target="#diar-dzair-slide" data-bs-slide-to={1} aria-label="Slide 2" />
                    <button type="button" data-bs-target="#diar-dzair-slide" data-bs-slide-to={2} aria-label="Slide 3" />
                </div> */}
                {/* <div className="carousel-inner"> */}

                {
                    Slides.length > 0 && Slides.map((item, i) => (
                        <SwiperSlide className={imgLoaded ? "" : 'd-none'}>

                            {/* <div className={`carousel-item ${i === 0 ? 'active' : ''} `}> */}
                                <img onLoad={() => setimgLoaded(true)} src={item['imageDesk']['webPath'] !== undefined ? `https://www.diardzair.com.dz/${windowSize.innerWidth > 880 ? item['imageDesk']['webPath'] : item['imageMob']['webPath']}` : "/img/slide2.jpg"} className="d-block" alt="img/slide2.jpg" />
                            {/* </div> */}
                        </SwiperSlide>
                    ))
                }
                {/* </div> */}
                {/* <button className="carousel-control-prev" type="button" data-bs-target="#diar-dzair-slide" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#diar-dzair-slide" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="visually-hidden">Next</span>
                </button> */}
                {/* </div> */}
            </Swiper>

        </section>

    )
}

export default Slide