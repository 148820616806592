import {useContext} from 'react'
import LangContext from '../../Context/lang';


const Box = () => {
  const { Lang, Global,dari3marsection } = useContext(LangContext);

  return (
    <div className='dar-i3mar-box'> 
        <div className='box-section'>
        <div className='box-item'>
        <p>{( dari3marsection['titre'][Lang] )}</p>
        <a href='/dari3mar/dossier'> <div className='box-btn'>{( Global['Plus'][Lang] )}</div></a>
        </div>
        <div className='box-item'>
        <p> {( dari3marsection['dossier'][Lang] )}</p>
        <a href='/dari3mar/dossier'><div className='box-btn'>{( Global['Plus'][Lang] )}</div></a>
        </div>
        <div className='box-item'>
        <p>{( dari3marsection['Catégories'][Lang] )}</p>
        <a href='#i3marcat'><div className='box-btn'>{( Global['Plus'][Lang] )}</div></a>
        </div>
        </div>
    </div>
  )
}

export default Box