import React from 'react'

const Qr = () => {
    return (
        <div>
            <div className="container mt-5 pt-5 mb-5">
                <div className='etap text-center'>
                    <div className="etap-title">Aide aux vendeurs</div>
                </div>
            </div>

            <div className='container mt-5 pt-5 mb-5 pt-5 pb-5'>



                <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                Quels sont les produits que je peux vendre sur Diar Dzair ?
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">
                                Vous pouvez proposez une large gamme de produits étalée sur de multiples et divers catégories : téléphones , tablettes , produits de maison et de bureau , informatique , appareil photo, électronique , cuisines montées , faïences salle de bain …..
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                Quels sont les produits que je ne peux pas vendre ?
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">
                                Vous ne pouvez pas vendre sur DIAR DZAIR des articles non-authentiques. De la contrefaçon ou des
                                articles illégaux. Tous les articles que vous vendrez doivent être totalement nouveaux et en parfait
                                état produits et emballage.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                Quel sont les condition d'inscription?
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                            <div className="accordion-body">
                                S’inscrire en tant que vendeur sur DIAR DZAIR et créer une boutique en linge de e-commerce. C’est
                                facile. Gratuit et cela ne vous prendra que 5 petites minutes ! Pour accéder au formulaire
                                d’inscription. Veuillez cliquer sur le lien. Puis remplissez les champs d’information demandes. Vous
                                serez par la suite contactes par un des agents de l’équipe acquisition. Qui se chargera de vous
                                expliquer plus en détail le fonctionnement de DIAR DZAIR et vous aidera à finaliser votre inscription.
                                il vous aidera notamment a mettre vos produits sur DIR DZAIR et vous communiquera les
                                informations principales concernant la formation. Ainsi il vous épaule dans la mise en place de votre
                                boutique en ligne sur DIAR DZAIR !
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                C'est quoi le dossier a fournir ?
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                            <div className="accordion-body">
                                <strong>Veuillez visiter ce lien {'->'}</strong> <a style={{color:'black'}} href='/dartaksit/dossier'>Dossier a fournir</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                Comment je m’inscris pour pouvoir bénéficier du service store?
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                            <div className="accordion-body">
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
        </div>
    )
}

export default Qr