import { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate , useSearchParams } from "react-router-dom";
import axios from "axios";
import CartContext from '../Context/cart';
import BankContext from '../Context/bank';
import LangContext from '../Context/lang';





const CartSimulation = () => {

  const { Lang, Global, dartaksitsim } = useContext(LangContext);


  const [searchParams, setSearchParams] = useSearchParams();
  const back = searchParams.get('back');
  const [UrlBack, setUrlBack] = useState('')

  useEffect(() => {

    if (back) {
      setUrlBack(back)
    }
  }, [])

  const { bankActive } = useContext(BankContext);
  



  const navigate = useNavigate();
  const isInitialMount = useRef(true);
  const { MaxAmount, setMaxAmount, MinAmount, setMinAmount, setTimeCart } = useContext(CartContext);

  const [DayList, setDayList] = useState(Array.from(Array(31), (_, i) => (i + 1).toString().padStart(2, "0")));
  const [Day, setDay] = useState('')
  const [MounthList, setMounthList] = useState(Array.from(Array(12), (_, i) => (i + 1).toString().padStart(2, "0")))
  const [Month, setMonth] = useState('')
  const [YearList, setYearList] = useState(Array.from(Array(52), (_, i) => (i + 1951).toString()))
  const [Year, setYear] = useState('')
  const [Salary, setSalary] = useState(25)
  const [Time, setTime] = useState('12')
  const [Max, setMax] = useState('')
  const [Min, setMin] = useState('')
  const [Step, setStep] = useState(1)
  const [Loading, setLoading] = useState(false)


  const [errors, seterrors] = useState({ day: '', salary: '', time: '' })
  const [Showerrors, setShowerrors] = useState(false)

  console.log(Day, Month, Year);
  console.log(Salary);
  console.log(Time);

  function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  console.log(errors);

  function Validate() {

    if (!DayList.includes(Day) || !MounthList.includes(Month) || !YearList.includes(Year)) {
      seterrors((prev) => ({ ...prev, day: dartaksitsim['dateerr'][Lang] }))
    } else {
      seterrors((prev) => ({ ...prev, day: '' }))
    }

    if (!Number.isInteger(Number(Salary)) || Number(Salary) < 25 || Number(Salary) > 200) {
      seterrors((prev) => ({ ...prev, salary: 'Veuillez renseigner votre Salaire' }))
    } else {
      seterrors((prev) => ({ ...prev, salary: '' }))
    }

    if (Time !== '12' && Time !== '24' && Time !== '36') {
      seterrors((prev) => ({ ...prev, time: 'Veuillez renseigner Durée de remboursement' }))
    } else {
      seterrors((prev) => ({ ...prev, time: '' }))
    }

  }

  useEffect(() => {

    Validate();

  }, [Day, Month, Year, Salary, Time,Lang,dartaksitsim])




  function getMaxamount(e) {


    e.preventDefault();

    setShowerrors(true)

    if (errors.day !== '' || errors.salary !== '' || errors.time !== '') {
      console.log('hhhhhhhhhhhhh');
      return;
    }


    const totalSalary = Salary + '000';

    setLoading(true)

    axios.post(`https://www.diardzair.com.dz/apicart/minmaxcart?r=${totalSalary}&d=${Time}`, { "title": "new_title", "body": "new_body", "userId": "userid" },{headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }})
      .then(function (response) {
        setMax(response.data['max'])
        setMin(response.data['min'])
        console.log(response.data);
        console.log(Max, Min);
        console.log(calculate_age(new Date(Year, Month, Day)));
        
        setMaxAmount(response.data['max']);
        setMinAmount(response.data['min']);
        setTimeCart(Time);
        localStorage.setItem('day' , Day)
        localStorage.setItem('Month' , Month)
        localStorage.setItem('Year' , Year)
        localStorage.setItem('Salary' , Salary)
        setLoading(false)
        setStep(2)
      })
      .catch(function (error) {
        // console.log(error);
        setLoading(false)
      });
    console.log('hi!');

  }

  return (
    // <div className='container'>
    <div>
      <div className='pre-simulation-popup'>
        <div className='cart-simulation-popup'>
          <div className={`cart-simulation-titel gradiant-${bankActive}`}>
            <a className='btn-close-simulation' onClick={() => UrlBack ? navigate('/product/'+UrlBack) :  navigate('/')}><div> X</div></a>
            <div className='bank-img-simulation me-3'>
              <img src={'/img/'+bankActive+'.png'} />
            
            </div>
            {(dartaksitsim['titre'][Lang])}</div>

          <div className={`${Step === 1 ? 'popup-container' : ''} ${Loading && 'd-none'}`}>

            {Step === 1 && <div className='cart-simulation-desc mb-3'> {(dartaksitsim['sous-titre2'][Lang])}</div>}
            {Step === 1 &&


              <div className=''>
                <form className="row g-3 justify-content-center">
                <label htmlFor="inputCity" className="form-label mb-3 label-days text-center">{(dartaksitsim['datenaissance'][Lang])}</label>
                  <div className='row justify-content-center'>
                    <div className="col-md-3 text-center">
                      <label htmlFor="inputState" className="form-label label-days">{(dartaksitsim['Jour'][Lang])}</label>
                      <select id="inputState" onChange={(e) => setDay(e.target.value)} className="form-select">
                        <option selected disabled>{(dartaksitsim['Jour'][Lang])}</option>
                        {
                          DayList.length > 0 && DayList.map((item, i) => (
                            <option selected={Day == item.toString().padStart(2, "0")} value={item.toString().padStart(2, "0")}>{item.toString().padStart(2, "0")}</option>
                          ))
                        }

                      </select>
                    </div>
                    <div className="col-md-3 text-center">
                      <label htmlFor="inputState" className="form-label label-days">{(dartaksitsim['Mois'][Lang])}</label>
                      <select id="inputState" onChange={(e) => setMonth(e.target.value)} className="form-select">
                        <option selected disabled>{(dartaksitsim['Mois'][Lang])}</option>
                        {
                          MounthList.length > 0 && MounthList.map((item, i) => (
                            <option selected={Month == item.toString().padStart(2, "0")} value={item.toString().padStart(2, "0")}>{item.toString().padStart(2, "0")}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className="col-md-4 text-center">
                      <label htmlFor="inputState" className="form-label label-days">{(dartaksitsim['Année'][Lang])}</label>
                      <select id="inputState" onChange={(e) => setYear(e.target.value)} className="form-select">
                        <option selected disabled>{(dartaksitsim['Année'][Lang])}</option>
                        {
                          YearList.length > 0 && YearList.map((item, i) => (
                            <option selected={Year == item} value={item}>{item}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className='err-cart-sum'>{Showerrors && errors.day !== '' ? errors.day : ''}</div>
                  <div className='row justify-content-center'>

                    <div className="col-md-10 mt-3 mb-5 text-center">

                      <label htmlFor="inputCity" className="form-label mb-3 label-days">{(dartaksitsim['Votresalaire'][Lang])}</label>
                      <input type="range" min={25} max={200} value={Salary} onChange={(e) => setSalary(e.target.value)} defaultValue={2500000} className={`slider  gradiant-${bankActive}`} id="myRange" />
                      {/* <input type="number" max={200000} min={25000}  className="form-control" value={Salary} id="inputCity" placeholder='DA' /> */}
                      <div className='rang-popup mt-2'><div className={`rang-popup-up  gradiant-${bankActive}`} onClick={() => setSalary(s => parseInt(s) > 25 ? parseInt(s) - 1 : parseInt(s))}>-</div>{Salary + ' 000 '}<span> {(Global['Da'][Lang])}</span><div className={`rang-popup-up  gradiant-${bankActive}`} onClick={() => setSalary(s => parseInt(s) < 200 ? parseInt(s) + 1 : parseInt(s))}>+</div></div>
                    </div>
                    <div className='err-cart-sum'>{Showerrors && errors.salary !== '' ? errors.salary : ''}</div>
                    {/* <div className="col-md-2">
        <label htmlFor="inputZip" className="form-label">Zip</label>
        <input type="text" className="form-control" id="inputZip" />
    </div> */}
                    <div className="col-md-12 text-center mt-3 mb-3 d-none">
                      <div className='mb-2 label-days'> {(dartaksitsim['Duréederemboursement'][Lang])} </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Time == '12'} onChange={(e) => setTime(e.target.value)} defaultChecked defaultValue="12" />
                        <label className="form-check-label" htmlFor="inlineRadio1">12 Mois</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Time == '24'} onChange={(e) => setTime(e.target.value)} defaultValue="24" />
                        <label className="form-check-label" htmlFor="inlineRadio2">24 Mois</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" checked={Time == '36'} onChange={(e) => setTime(e.target.value)} defaultValue="36" />
                        <label className="form-check-label" htmlFor="inlineRadio3">36 Mois</label>
                      </div>
                    </div>
                    <div className='mb-3 label-days text-center'> {(dartaksitsim['Duréederemboursement'][Lang])} </div>
                    <div className='remboursement-box' dir={Lang === 'ar' && 'rtl'}>
                      <div onClick={() => setTime('12')} className={`remb-box ${Time == '12' ? `active gradiant-${bankActive}` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                        <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                      </svg>
                        12 {(dartaksitsim['duréeMois'][Lang])}</div>
                      <div onClick={() => setTime('24')} className={`remb-box ${Time == '24' ? `active gradiant-${bankActive}` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                        <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                      </svg>24 {(dartaksitsim['duréeMois'][Lang])}</div>
                      <div onClick={() => setTime('36')} className={`remb-box ${Time == '36' ? `active gradiant-${bankActive}` : ''}`}><svg xmlns="http://www.w3.org/2000/svg" width="265.21" height="246.261" viewBox="0 0 265.21 246.261">
                        <path id="téléchargement" d="M484.93,276.55H451.778V262.343a4.734,4.734,0,0,0-9.469,0V276.55H404.422V262.343a4.734,4.734,0,1,0-9.469,0V276.55H357.066V262.343a4.734,4.734,0,0,0-9.469,0V276.55H309.711V262.343a4.734,4.734,0,0,0-9.469,0V276.55H267.09a23.448,23.448,0,0,0-23.68,23.68V480.19a23.448,23.448,0,0,0,23.68,23.68H484.94a23.448,23.448,0,0,0,23.68-23.68l-.008-179.96a23.45,23.45,0,0,0-23.68-23.676Zm-217.85,9.469h33.152v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,1,0,9.469,0V286.019h37.887v14.207a4.734,4.734,0,0,0,9.469,0V286.019H442.3v14.207a4.734,4.734,0,0,0,9.469,0V286.019H484.92a13.953,13.953,0,0,1,14.207,14.207v33.152H252.877V300.226a13.953,13.953,0,0,1,14.207-14.207ZM484.93,494.4H267.08a13.953,13.953,0,0,1-14.207-14.207V342.852h246.26v137.34A13.956,13.956,0,0,1,484.926,494.4Z" transform="translate(-243.41 -257.609)" />
                      </svg>36 {(dartaksitsim['duréeMois'][Lang])}</div>

                    </div>
                    <div className="col-12 mt-3 text-center mt-5 mb-3">
                      <button onClick={getMaxamount} className={`btn-cart-simulation gradiant-${bankActive}`}>{(dartaksitsim['Suivant'][Lang])}</button>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
          {Loading && <div className='cart-sum-loader'>
            <div class="spinner-grow text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>}
          {Step === 2 &&
            <div className='popup-container'>
              <div className='validation-section pb-5'>
                <div className='text-center mt-2 mb-2 titel'><p>{(dartaksitsim['sous-titre3'][Lang])}</p></div>
                <div className='text-center cart-simulation-desc mb-5'>{(dartaksitsim['sous-titre4'][Lang])}</div>
                <div className='max-amount'>{Max !== '' ? Max.toLocaleString() : ''} DA</div>

                <div className='user-info mt-5 mb-5'>
                  <p className='user-info-items'><b>{(dartaksitsim['VotreSalaire'][Lang])} :</b> {(Lang ==='fr') ? `${Salary} 000` : `000 ${Salary}` }  {(Global['Dajust'][Lang])}</p>
                  <p className='user-info-items'><b>{(dartaksitsim['Durée'][Lang])} : </b>{Time} {(dartaksitsim['duréeMois'][Lang])}</p>
                  <p className='user-info-items'><b>{(dartaksitsim['Votreàge'][Lang])} : </b>{calculate_age(new Date(Year, Month, Day))}</p>
                </div>

                <div className='cart-pre-sum'>
                  <div className='text-center'>
                    <button onClick={() => setStep(1)} className={`btn-cart-simulation gradiant-${bankActive}`}>{(dartaksitsim['Retour'][Lang])}</button>
                  </div>
                  <div className='text-center'>
                    <button onClick={() => UrlBack ? navigate('/product/'+UrlBack+'?add=yes') :  navigate('/cart')} className={`btn-cart-simulation gradiant-${bankActive}`}>{(dartaksitsim['Validation'][Lang])}</button>
                  </div>
                </div>


              </div>
            </div>
          }
        </div>
      </div>
    </div>
    // </div>
  )
}

export default CartSimulation