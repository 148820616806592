import { useEffect , useState ,useContext} from 'react'
import BankContext from '../../Context/bank'


const Popup = () => {

  // const [bankActive , setBankActive] = useState('bna');

  const { bankActive ,setBankActive} = useContext(BankContext)


  function SetBank (bank){
    setBankActive(bank);
    localStorage.setItem('bank', bank);
    document.querySelector('.PopupBank').style.display = "none";
  }


  return (
    <div className='PopupBank'>
       <div className='PopupContent'>
        <div className='popuptitel'>
        <h1>Bienvenue sur Diar Dzair</h1>
        <h3>Choisissez Votre Banque</h3>
        </div>
        <div className='Bank-section'>
            <div onClick={() => SetBank('bna')} className='bna-section'><img className='img-fluid' src="/img/bna.png" /><div className='desc'>BNA</div></div>
            {/* <div onClick={() => SetBank('agb')} className='bna-section'><img className='img-fluid' src="img/agb.png" /><div className='desc'>AGB</div></div> */}
            <div onClick={() => SetBank('alsalam')} className='bna-section'><img className='img-fluid' src="/img/alsalam.png" /><div className='desc'>Al Salam</div></div>
        </div>
       </div>
    </div>
  )
}

export default Popup