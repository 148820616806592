import { useState, useEffect } from 'react'

const Situation = ({ Step, setStep, StepTitel, setFormdata }) => {

  const [Secteurdactivité, setSecteurdactivité] = useState('')
  const [Profession, setProfession] = useState('')

  const [Typedecontrat, setTypedecontrat] = useState('')
  const [Périodedessai, setPériodedessai] = useState('oui')
  const [AnciennetéMois, setAnciennetéMois] = useState('')
  const [Anciennetéannée, setAnciennetéannée] = useState('')
  const [Nomdelemployeur, setNomdelemployeur] = useState('')
  const [Wilayadelemployeur, setWilayadelemployeur] = useState('')
  const [Adressedelemployeur, setAdressedelemployeur] = useState('')
  const [DepuisquelledateMois, setDepuisquelledateMois] = useState('')
  const [Depuisquelledateannée, setDepuisquelledateannée] = useState('')
  const [Tel, setTel] = useState('')

  const [Salaire, setSalaire] = useState('')
  const [Compte, setCompte] = useState('CCP')
  const [CompteCCP, setCompteCCP] = useState('')
  const [CCPClé, setCCPClé] = useState('')
  const [Banque, setBanque] = useState('')
  const [SalamBank, setSalamBank] = useState('non')
  const [sourcesderevenus, setsourcesderevenus] = useState('non')

  console.log(Secteurdactivité, Profession, Typedecontrat, Périodedessai, AnciennetéMois, Anciennetéannée, Nomdelemployeur, Wilayadelemployeur, DepuisquelledateMois, Depuisquelledateannée, Tel, Salaire, Compte, CompteCCP, CCPClé, Banque, SalamBank, sourcesderevenus);


  const [FormValidate, setFormValidate] = useState(true)
  const [Showerr, setShowerr] = useState(false)

  console.log(parseInt(localStorage.getItem('Year')) + 18);

  useEffect(() => {


    Validation();

  }, [Secteurdactivité, Profession, Typedecontrat, Périodedessai, AnciennetéMois, Anciennetéannée, Nomdelemployeur, Wilayadelemployeur, Adressedelemployeur, DepuisquelledateMois, Depuisquelledateannée, Tel, Salaire, Compte, CompteCCP, CCPClé, Banque, SalamBank, sourcesderevenus])



  function onlyLetters(str) {
    return /^[a-zA-Z]+$/.test(str);
  }
  function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
  }
  function onlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  function isValidEmail(string) {
    // The regular expression used by [type="email"]
    var regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    // Test the string against the regular expression
    return regex.test(string)
  }

  function Validation() {

    setFormValidate(true)

    if (Secteurdactivité !== "Public" && Secteurdactivité !== "Retraité" && Secteurdactivité !== "Indépendant" && Secteurdactivité !== "Privé") {
      setFormValidate(false)
      console.log(Secteurdactivité);
      console.log('err');
    }

    if (Secteurdactivité == "Public" || Secteurdactivité == "Privé") {


      if (Profession.trim() === '') {
        setFormValidate(false)
        console.log('err');
      }
      if (AnciennetéMois.trim() === '' || Anciennetéannée.trim() === '') {
        setFormValidate(false)

        
        console.log('err');
      }
      if (localStorage.getItem('Year')) {

        if (Anciennetéannée.trim()  < (parseInt(localStorage.getItem('Year')) + 18)) {

          setFormValidate(false)

        }

      }
      if (Typedecontrat !== "CDD" && Typedecontrat !== "CDI" && Typedecontrat !== "INTERIM" && Typedecontrat !== "Autre") {
        setFormValidate(false)
        console.log('err');
      }
      if (!onlyLetters(Nomdelemployeur)) {
        setFormValidate(false)
        console.log('err');
      }
      if (Wilayadelemployeur.trim() === '') {
        setFormValidate(false)
        console.log('err');
      }
      if (Adressedelemployeur.trim() === '') {
        setFormValidate(false)
        console.log('err');
      }
      if (!onlyNumbers(Tel) || Tel.length > 13 || Tel.length < 9) {
        setFormValidate(false)
        console.log('err');
      }

    }

    if (Secteurdactivité == "Indépendant") {


      if (Profession.trim() === '') {
        setFormValidate(false)
        console.log('err');
      }
      if (DepuisquelledateMois.trim() === '' || Depuisquelledateannée.trim() === '') {
        setFormValidate(false)
        console.log('err');
      }

      if (!onlyNumbers(Tel) || Tel.length > 13 || Tel.length < 9) {
        setFormValidate(false)
        console.log('err');
      }

    }

    if (!onlyNumbers(Salaire) || Salaire.length > 7 || Salaire.length < 5) {
      setFormValidate(false)
      console.log('err');
    }

    if (Compte == 'CCP') {

      if (!onlyNumbers(CompteCCP)) {
        setFormValidate(false)
        console.log('err');
      }
      if (!onlyNumbers(CCPClé) || CCPClé.length !== 2) {
        setFormValidate(false)
        console.log('err');
      }
    } else {

      if (Banque.trim() === '') {
        setFormValidate(false)
        console.log('err');
      }

    }

  }


  function GoNext() {


    setShowerr(true)

    if (!FormValidate) {
      return
    }

    setFormdata((prev) => [
      {
        ...prev[0], ...{
          "stactivite": Secteurdactivité,
          "profession": Profession.trim(),
          "typcontrat": Typedecontrat,
          "r_pessai": Périodedessai,
          "oldmois": AnciennetéMois,
          "oldannee": Anciennetéannée,
          "dmois": DepuisquelledateMois,
          "dannee": Depuisquelledateannée,
          "employeur": Nomdelemployeur,
          "wemployeur": Wilayadelemployeur,
          "adremployeur": Adressedelemployeur.trim(),
          "telprofessionnel": Tel,
          "salaire": Salaire,
          "rcompte": Compte,
          "cptccp": CompteCCP,
          "cleccp": CCPClé,
          "banque": Banque,
          "rCptalsalambank": SalamBank,
          "rautresRevenus": sourcesderevenus,
        }
      }
    ])

    setStep(4)
  }


  return (
    <div className={`form-info form-3 ${Step !== 3 ? 'd-none' : ''}`}>
      <div className='text-center info-step-titel mb-3'>{StepTitel[Step - 1]}</div>
      <form className="row g-3 m-auto justify-content-center">


        <div className="col-md-12 mb-3">
          <label htmlFor="inputEmail4" className="form-label">Secteur d'activité</label>
          <select class="form-select" aria-label="Default select example" onChange={(e) => setSecteurdactivité(e.target.value)}>
            <option value={0} disabled selected="selected">Choisissez</option>
            <option value="Public">Public </option>
            <option value="Privé">Privé</option>
            <option value="Indépendant">Indépendant </option>
            <option value="Retraité">Retraité </option>
          </select>
          <div className='alert-form'>{Showerr && (Secteurdactivité == "" ? "Veuillez renseigner votre Secteur d'activité" : '')}</div>


        </div>
        <div className={''}>

          {Secteurdactivité == 'Public' || Secteurdactivité == 'Privé' || Secteurdactivité == 'Indépendant' ?
            <div className="col-md-12 mb-3">
              <label htmlFor="inputEmail4" className="form-label">Profession</label>
              <input type="text" placeholder="Profession" className="form-control" id="inputEmail4" onChange={(e) => setProfession(e.target.value)} value={Profession} />
              <div className='alert-form'>{Showerr && (Profession.trim().length < 2 ? 'Veuillez renseigner votre Profession.' : '')}</div>
            </div> : ''}

          {Secteurdactivité == 'Public' || Secteurdactivité == 'Privé' ?
            <>
              <div className="col-md-12 mb-3">
                <label htmlFor="inputPassword4" className="form-label">Type de contrat</label>
                <select class="form-select" aria-label="Default select example" onChange={(e) => setTypedecontrat(e.target.value)}>
                  <option value="0" selected="selected">Choisissez</option>
                  <option value="CDI">CDI</option>
                  <option value="CDD">CDD </option>
                  <option value="INTERIM">Intérim</option>
                  <option value="Autre">Autre</option>
                </select>
                <div className='alert-form'>{Showerr && (Typedecontrat == "" ? 'Veuillez renseigner votre Type de contrat.' : '')}</div>
              </div>
              <div className="col-md-12 mb-2">
                <div className='mb-2'>Période d’essai terminée ?</div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions33" id="inlineRadio1" checked={Périodedessai == 'oui'} onChange={(e) => setPériodedessai(e.target.value)} defaultValue={'oui'} />
                  <label className="form-check-label" htmlFor="inlineRadio1">Oui</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions33" id="inlineRadio2" checked={Périodedessai == 'non'} onChange={(e) => setPériodedessai(e.target.value)} defaultValue={'non'} />
                  <label className="form-check-label" htmlFor="inlineRadio2">Non</label>
                </div>
              </div>
              <label htmlFor="inputPassword4" className="form-label">Ancienneté de votre emploi actuel depui?</label>
              <div className="col-md-12 mb-3 d-flex">
                <div>
                  <select class="form-select me-2" aria-label="Default select example" onChange={(e) => setAnciennetéMois(e.target.value)}>
                    <option disabled selected="selected">Mois</option>
                    <option value="01">janvier</option>
                    <option value="02">février </option>
                    <option value="03">mars</option>
                    <option value="04">avril</option>
                    <option value="05">mai</option>
                    <option value="06">juin </option>
                    <option value="07">juillet</option>
                    <option value="08">août</option>
                    <option value="09">septembre</option>
                    <option value="10">octobre</option>
                    <option value="11">novembre</option>
                    <option value="12">décembre</option>
                  </select>
                  <div className='alert-form'>{Showerr && (AnciennetéMois == "" ? 'Veuillez renseigner votre Mois' : '')}</div>
                </div>
                <div>
                  <select class="form-select" aria-label="Default select example" onChange={(e) => setAnciennetéannée(e.target.value)}>
                    <option disabled selected="selected">année</option>
                    <option value="2019">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009</option>
                    <option value="2008">2008</option>
                    <option value="2007">2007</option>
                    <option value="2006">2006</option>
                    <option value="2005">2005</option>
                    <option value="2004">2004</option>
                    <option value="2003">2003</option>
                    <option value="2002">2002</option>
                    <option value="2001">2001</option>
                    <option value="2000">2000</option>
                    <option value="1999">1999</option>
                    <option value="1998">1998</option>
                    <option value="1997">1997</option>
                    <option value="1996">1996</option>
                    <option value="1995">1995</option>
                    <option value="1994">1994</option>
                    <option value="1993">1993</option>
                    <option value="1992">1992</option>
                    <option value="1991">1991</option>
                    <option value="1990">1990</option>
                    <option value="1989">1989</option>
                    <option value="1988">1988</option>
                    <option value="1987">1987</option>
                    <option value="1986">1986</option>
                    <option value="1985">1985</option>
                    <option value="1984">1984</option>
                    <option value="1983">1983</option>
                    <option value="1982">1982</option>
                    <option value="1981">1981</option>
                    <option value="1980">1980</option>
                    <option value="1979">1979</option>
                    <option value="1978">1978</option>
                  </select>
                  <div className='alert-form'>{Showerr && (Anciennetéannée.trim() == "" ? 'Veuillez renseigner votre année' : '')}</div>
                  <div className='alert-form'>{Showerr && localStorage.getItem('Year') && (Anciennetéannée.trim() < (parseInt(localStorage.getItem('Year')) + 18) ? 'Vous devez entrer une date valide' : '')}</div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="inputEmail4" className="form-label">Nom de l'employeur</label>
                <input type="text" placeholder="Nom de l'employeur" className="form-control" id="inputEmail4" onChange={(e) => setNomdelemployeur(e.target.value.replace(/[^a-zA-Z]+/g, ''))} value={Nomdelemployeur} />
                <div className='alert-form'>{Showerr && (Nomdelemployeur.trim().length < 2 ? "Veuillez renseigner votre Nom de l'employeur" : '')}</div>
                <div className='alert-form'>{Showerr && (!onlyLetters(Nomdelemployeur) ? "Veuillez saisir votre Nom de l'employeur sans chiffres ni caractères spéciaux" : '')}</div>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="inputPassword4" className="form-label">Wilaya de l'employeur</label>
                <select class="form-select" aria-label="Default select example" onChange={(e) => setWilayadelemployeur(e.target.value)}>
                  <option disabled selected="selected">wilaya</option>
                  <option value="ADRAR">(01) ADRAR</option>
                  <option value="CHLEF">(02) CHLEF</option>
                  <option value="LAGHOUAT">(03) LAGHOUAT</option>
                  <option value="OUM EL BOUAGHI">(04) OUM-EL-BOUAGHI</option>
                  <option value="BATNA">(05) BATNA</option>
                  <option value="BEJAIA">(06) BEJAIA</option>
                  <option value="BISKRA">(07) BISKRA</option>
                  <option value="BECHAR">(08) BECHAR</option>
                  <option value="BLIDA">(09) BLIDA</option>
                  <option value="BOUIRA">(10) BOUIRA</option>
                  <option value="TAMANRASSET">(11) TAMANRASSET</option>
                  <option value="TEBESSA">(12) TEBESSA</option>
                  <option value="TLEMCEN">(13) TLEMCEN</option>
                  <option value="TIARET">(14) TIARET</option>
                  <option value="TIZI OUZOU">(15) TIZI-OUZOU</option>
                  <option value="ALGER">(16) ALGER</option>
                  <option value="DJELFA">(17) DJELFA</option>
                  <option value="JIJEL">(18) JIJEL</option>
                  <option value="SETIF">(19) SETIF</option>
                  <option value="SAIDA">(20) SAIDA</option>
                  <option value="SKIKDA">(21) SKIKDA</option>
                  <option value="SIDI BEL ABBES">(22) SIDI-BEL-ABBES</option>
                  <option value="ANNABA">(23) ANNABA</option>
                  <option value="GUELMA">(24) GUELMA</option>
                  <option value="CONSTANTINE">(25) CONSTANTINE</option>
                  <option value="MEDEA">(26) MEDEA</option>
                  <option value="MOSTAGANEM">(27) MOSTAGANEM</option>
                  <option value="M SILA">(28) M-SILA</option>
                  <option value="MASCARA">(29) MASCARA</option>
                  <option value="OUARGLA">(30) OUARGLA</option>
                  <option value="ORAN">(31) ORAN</option>
                  <option value="EL BAYADH">(32) EL-BAYADH</option>
                  <option value="ILLIZI">(33) ILLIZI</option>
                  <option value="BORDJ BOU ARRERIDJ">(34) BORDJ-BOU-ARRERIDJ</option>
                  <option value="BOUMERDES">(35) BOUMERDES</option>
                  <option value="EL TARF">(36) EL-TARF</option>
                  <option value="TINDOUF">(37) TINDOUF</option>
                  <option value="TISSEMSILT">(38) TISSEMSILT</option>
                  <option value="EL OUED">(39) EL-OUED</option>
                  <option value="KHENCHELA">(40) KHENCHELA</option>
                  <option value="SOUK AHRAS">(41) SOUK-AHRAS</option>
                  <option value="TIPAZA">(42) TIPAZA</option>
                  <option value="MILA">(43) MILA</option>
                  <option value="AIN DEFLA">(44) AIN-DEFLA</option>
                  <option value="NAAMA">(45) NAAMA</option>
                  <option value="AIN TEMOUCHENT">(46) AIN-TEMOUCHENT</option>
                  <option value="GHARDAIA">(47) GHARDAIA</option>
                  <option value="RELIZANE">(48) RELIZANE</option>
                  <option value="Timimoun">(49) Timimoun</option>
                  <option value="Bordj Badji Mokhtar">(50) Bordj Badji Mokhtar</option>
                  <option value="Ouled Djellal">(51) Ouled Djellal</option>
                  <option value="Béni Abbès">(52) Béni Abbès</option>
                  <option value="In Salah">(53) In Salah</option>
                  <option value="In Guezzam">(54) In Guezzam</option>
                  <option value="Touggourt">(55) Touggourt</option>
                  <option value="Djanet">(56) Djanet</option>
                  <option value="El M'Ghair">(57) El M'Ghair</option>
                  <option value="El Meniaa">(58) El Meniaa</option>
                </select>
                <div className='alert-form'>{Showerr && (Wilayadelemployeur == "" ? "Veuillez renseigner votre Wilaya de l'employeur" : '')}</div>
              </div>

              <div className="col-md-12 mb-3">
                <label htmlFor="inputEmail4" className="form-label">Adresse de l'employeur</label>
                <input type="text" placeholder="Adresse de l'employeur" className="form-control" id="inputEmail4" onChange={(e) => setAdressedelemployeur(e.target.value)} value={Adressedelemployeur} />
                <div className='alert-form'>{Showerr && (Adressedelemployeur.trim() == "" ? "Veuillez renseigner votre Adresse de l'employeur" : '')}</div>
              </div></> : ''}
          {Secteurdactivité == 'Indépendant' ? <>
            <label htmlFor="inputPassword4" className="form-label">Depuis quelle date?</label>
            <div className="col-md-12 mb-3 d-flex">
              <select class="form-select me-2" aria-label="Default select example" onChange={(e) => setDepuisquelledateMois(e.target.value)}>
                <option disabled selected="selected">Mois</option>
                <option value="01">janvier</option>
                <option value="02">février </option>
                <option value="03">mars</option>
                <option value="04">avril</option>
                <option value="05">mai</option>
                <option value="06">juin </option>
                <option value="07">juillet</option>
                <option value="08">août</option>
                <option value="09">septembre</option>
                <option value="10">octobre</option>
                <option value="11">novembre</option>
                <option value="12">décembre</option>
              </select>

              <select class="form-select" aria-label="Default select example" onChange={(e) => setDepuisquelledateannée(e.target.value)}>
                <option disabled selected="selected">année</option>
                <option value="2019">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                <option value="2009">2009</option>
                <option value="2008">2008</option>
                <option value="2007">2007</option>
                <option value="2006">2006</option>
                <option value="2005">2005</option>
                <option value="2004">2004</option>
                <option value="2003">2003</option>
                <option value="2002">2002</option>
                <option value="2001">2001</option>
                <option value="2000">2000</option>
                <option value="1999">1999</option>
                <option value="1998">1998</option>
                <option value="1997">1997</option>
                <option value="1996">1996</option>
                <option value="1995">1995</option>
                <option value="1994">1994</option>
                <option value="1993">1993</option>
                <option value="1992">1992</option>
                <option value="1991">1991</option>
                <option value="1990">1990</option>
                <option value="1989">1989</option>
                <option value="1988">1988</option>
                <option value="1987">1987</option>
                <option value="1986">1986</option>
                <option value="1985">1985</option>
                <option value="1984">1984</option>
                <option value="1983">1983</option>
                <option value="1982">1982</option>
                <option value="1981">1981</option>
                <option value="1980">1980</option>
                <option value="1979">1979</option>
                <option value="1978">1978</option>
              </select>
            </div>
          </> : ''
          }
          {Secteurdactivité == 'Public' || Secteurdactivité == 'Privé' || Secteurdactivité == 'Indépendant' ?
            <div className="col-md-12 mb-3">
              <label htmlFor="inputEmail4" className="form-label">Téléphone professionnel</label>
              <input type="text" placeholder="Téléphone professionnel" className="form-control" id="inputEmail4" onChange={(e) => setTel(e.target.value.replace(/[^0-9]+/g, ''))} value={Tel} />
              <div className='alert-form'>{Showerr && (!onlyNumbers(Tel) ? ' Veuillez saisir votre nom sans chiffres ni caractères spéciaux.' : '')}</div>
              <div className='alert-form'>{Showerr && (Tel.length > 13 || Tel.length < 9 ? 'Votre numéro de téléphone ne doit pas être inférieur à 9 chiffres ni supérieur à 13 chiffres' : '')}</div>

            </div> : ''}
        </div>

        <div className="col-md-12 mb-3">
          <label htmlFor="inputEmail4" className="form-label">Salaire net mensuel</label>
          <input type="text" placeholder="Salaire net mensuel" className="form-control" onChange={(e) => setSalaire(e.target.value.replace(/[^0-9]+/g, ''))} value={Salaire} id="inputEmail4" />
          <div className='alert-form'>{Showerr && (!onlyNumbers(Salaire) || Salaire.length > 7 || Salaire.length < 5 ? ' Veuillez saisir votre Salaire sans chiffres ni caractères spéciaux. Max 7 chiffres , Min 5 chiffres' : '')}</div>
        </div>
        <div className="col-md-12 mb-2">
          <div className='mb-2'>Votre compte ?</div>
          {/* <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultChecked defaultValue="option1" />
                <label className="form-check-label" htmlFor="inlineRadio1">CCP</label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
                <label className="form-check-label" htmlFor="inlineRadio2">Banque</label>
            </div> */}

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" checked={Compte == 'CCP'} onChange={(e) => setCompte(e.target.value)} defaultChecked={true} defaultValue="CCP" />
            <label className="form-check-label" htmlFor="inlineRadio1">CCP</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={Compte == 'Banque'} onChange={(e) => setCompte(e.target.value)} defaultValue="Banque" />
            <label className="form-check-label" htmlFor="inlineRadio2">Banque</label>
          </div>

        </div>

        {Compte == 'CCP' ?
          <>
            <label htmlFor="inputPassword4" className="form-label">Compte CCP</label>
            <div className="col-md-12 d-flex">
              <div>
                <input type="text" placeholder="Compte" onChange={(e) => setCompteCCP(e.target.value.replace(/[^0-9]+/g, ''))} value={CompteCCP} className="form-control me-2" id="inputPassword4" />
                <div className='alert-form'>{Showerr && (CompteCCP == "" ? "Veuillez renseigner votre compte CCP" : '')}</div>
              </div>
              <div className='ms-3'>
                <input type="text" placeholder="Clé" onChange={(e) => setCCPClé(e.target.value.replace(/[^0-9]+/g, ''))} value={CCPClé} className="form-control me-2" id="inputPassword4" />
                <div className='alert-form'>{Showerr && (CCPClé == "" ? "Veuillez renseigner votre Clé CCP" : '')}</div>
                <div className='alert-form'>{Showerr && (CCPClé.length !== 2 ? "votre Clé CCP doit être composée de deux chiffres" : '')}</div>
              </div>
            </div>
          </> : ''}

        {Compte == 'Banque' ?
          <div className="col-md-12 mb-3">
            <label htmlFor="inputPassword4" className="form-label">Banque</label>
            <select class="form-select" aria-label="Default select example" onChange={(e) => setBanque(e.target.value)}>
              <option disabled selected="selected">Choisissez</option>
              <option value="AL-SALAM">Al salam Bank Algeria</option>
              <option value="BEA">BEA (Banque Extérieure d’Algérie)</option>
              <option value="BNA">BNA (Banque Nationale d’Algérie) </option>
              <option value="CPA">CPA (Crédit Populaire d’Algérie)</option>
              <option value="BDL">BDL (Banque de Développement Local)</option>
              <option value="CNEP">CNEP-Banque (Caisse Nationale d’Epargne et de Prévoyance- Banque-)</option>
              <option value="BADR"> BADR (Banque de l’Agriculture et du Développement Rural)</option>
              <option value="AL-BARAKA">AL BARAKA (Banque AL Baraka d’Algérie)</option>
              <option value="CITIBANK">CITIBANK</option>
              <option value="ABC">ABC (Arab Banking Corporation Algeria)</option>
              <option value="NATIXIS">Natixis ALGERIE</option>
              <option value="SGA"> Société Générale Algérie</option>
              <option value="BNP"> BNP Paribas Al-djazair</option>
              <option value="TRUST-BANK"> TRUST BANK Algeria</option>
              <option value="Housing-Bank">Housing Bank (The Housing Bank For Trade and Finance – Algeria)</option>
              <option value="AGB"> AGB (Gulf Bank Algérie)</option>
              <option value="FRANSABANK">Crédit Agricole Corporate Et Investissement Bank Algérie  (Ex CALYON)</option>
              <option value="FRANSABANK">Fransabank Al-Djazair</option>
              <option value="HSBC"> HSBC Algeria</option>
            </select>
            <div className='alert-form'>{Showerr && (Banque == "" ? "Veuillez renseigner votre Banque" : '')}</div>
          </div> : ''
        }



        <div className="col-md-12 mb-2">
          <div className='mb-2'>Avez-vous un Compte Al Salam Bank-Algeria ?</div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="inlineRadioOptions88" id="inlineRadio1" checked={SalamBank == 'oui'} onChange={(e) => setSalamBank(e.target.value)} defaultValue="oui" />
            <label className="form-check-label" htmlFor="inlineRadio1">Oui</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="inlineRadioOptions88" id="inlineRadio2" checked={SalamBank == 'non'} onChange={(e) => setSalamBank(e.target.value)} defaultValue="non" />
            <label className="form-check-label" htmlFor="inlineRadio2">Non</label>
          </div>
        </div>

        <div className="col-md-12 mb-2">
          <div className='mb-2'>Avez-vous d'autre sources de revenus ?</div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="inlineRadioOptions66" id="inlineRadio1" checked={sourcesderevenus == 'oui'} onChange={(e) => setsourcesderevenus(e.target.value)} defaultValue="oui" />
            <label className="form-check-label" htmlFor="inlineRadio1">Oui</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="inlineRadioOptions66" id="inlineRadio2" checked={sourcesderevenus == 'non'} onChange={(e) => setsourcesderevenus(e.target.value)} defaultValue="non" />
            <label className="form-check-label" htmlFor="inlineRadio2">Non</label>
          </div>
        </div>

        {/* <label htmlFor="inputPassword4" className="form-label">Numéro d'acte de naissance</label>
  <div className="col-md-12 mb-3 d-flex">
    <input type="password" className="form-control me-2" id="inputPassword4" />

    <select class="form-select" aria-label="Default select example">
    <option selected>Open this select menu</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    </select>
  </div> */}
        {/* <div className="col-md-12 mb-3">
  <label htmlFor="inputPassword4" className="form-label">Situation familiale</label>
    <select class="form-select" aria-label="Default select example">
    <option selected>Open this select menu</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    </select>
  </div> */}
        {/* <div className="col-md-12 mb-3">
  <label htmlFor="inputPassword4" className="form-label">Niveau D'instructions</label>
    <select class="form-select" aria-label="Default select example">
    <option selected>Open this select menu</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    </select>
  </div> */}
        {/* <div className="col-md-12 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Nombre d'enfants à charge</label>
    <input type="password" className="form-control" id="inputPassword4" />
  </div> */}
        {/* <div className="col-md-12 mb-3">
    <label htmlFor="inputPassword4" className="form-label">Nombre d'autres personnes à charge
</label>
    <input type="password" className="form-control" id="inputPassword4" />
  </div> */}



      </form>

      <div className='row mt-5'>
        <div className="col text-start">
          <button type="submit" onClick={() => setStep(2)} className="next-info-btn">Retour</button>
        </div>
        <div className="col text-end">
          <button type="submit" onClick={() => GoNext()} className="next-info-btn">Suivant</button>
        </div>
      </div>
    </div>
  )
}

export default Situation